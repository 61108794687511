import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        float: 'right',
        margin: theme.spacing(2, 0)
    },
    formControl: {
        margin: theme.spacing(1,0)
    }
}));

export default function ModalFilter({ openModalFilter, closeModalFilter, handleRequestSort, setOrder, columns }) {
    //STYLE
    const classes = useStyles();

    //STATE
    const [formState, setFormState] = useState({orderBy: '', order: '' });

    const handleChange = (name, value) => {
        setFormState(formState => ({
            ...formState,
            [name]: value
        }))
    }

    const orderClick = () => {
        handleRequestSort(formState.orderBy);
        closeModalFilter();
        setOrder(formState.order)
    }

    return (
        <Dialog onClose={closeModalFilter} open={openModalFilter} fullWidth>
            <DialogContent>
                <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Ordenar por</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formState.orderBy} 
                        name="orderBy"
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                    >
                         <MenuItem value=""></MenuItem>
                         {columns.map((column, index) => (
                              <MenuItem key={index} value={column.name}>{column.label}</MenuItem>
                         ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="orderLabel">Ordem</InputLabel>
                    <Select labelId="orderLabel" name="order" value={formState.order} onChange={(e) => handleChange(e.target.name, e.target.value)}>
                        <MenuItem value="">Padrão</MenuItem>
                        <MenuItem value="asc"> Crescente</MenuItem>
                        <MenuItem value="desc"> Decrescente</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" onClick={() => orderClick()} size="small" color="primary" className={classes.button}>
                    Ordenar
                </Button>
            </DialogContent>
        </Dialog>
    );
}
