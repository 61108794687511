import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { State } from '../../../../components';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(3)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    button: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderRadius: '99px',
        width: '15%'
    },
    boxState: {
        display: 'flex',
        alignItems: 'center'
    },
    stateElement: {
        marginRight: theme.spacing(1)
    },
    buttonCallback: {
        color: '#8A8A8D',
        textDecorationLine: "underline",
        textTransform: "capitalize"
    }
}));

const MuiDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <DialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h4">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});

const MuiDialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
}))(DialogContent);

const MuiDialogActions = withStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
}))(DialogActions);

export default function DeviceDetails({ deviceDetailsModal, setDeviceDetailsModal, history }) {
    //STYLE
    const classes = useStyles();
    var device = deviceDetailsModal.device;

    const handleClose = () => {
        setDeviceDetailsModal({ open: false, device: {} });
    };

    function formatLastHeartbeatDate() {
        // Suporta lastTimeHeartBeat nos formatos:
        // - 2023-02-08 17:29:36.045 +0000 UTC
        // - 2023-02-08T17:30:36.060223Z

        const dateText = device?.lastTimeHeartBeat;
        if (!dateText) {
            return "Data não informada";
        }

        try {
            const d = new Date(dateText);
            return d.toLocaleString();
        } catch (error) {
            return `Data inválida, ${dateText}`;
        }
    }

    const lastTime = formatLastHeartbeatDate();
 
    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                onClose={handleClose}
                open={deviceDetailsModal.open}
            >
                <MuiDialogTitle id="dialog-title-device-details" onClose={handleClose}>
                    {device.deviceName}
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    <Grid container className={classes.headerContainer} spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> Nome: </Typography> {device.deviceName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> Número Serial: </Typography> {device.deviceKey}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12} className={classes.boxState}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> Versão: </Typography> {device.lastDeviceInfo?.version}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> Número Pessoas: </Typography> {device.lastDeviceInfo?.personCount}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> Número Faces: </Typography> {device.lastDeviceInfo?.faceCount}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12} >
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> IP: </Typography> {device.lastDeviceInfo?.ip}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12} >
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> Última Conexão: </Typography> {lastTime}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12} className={classes.boxState}>
                            <Typography variant="subtitle1" color="textSecondary" component="span" className={classes.stateElement}> Status: </Typography>
                            <State selected={device.status} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.boxState}>
                            <Button variant='text' className={classes.buttonCallback} 
                                onClick={() => history.push({
                                    pathname: `/device/${device.deviceKey}/callback`,
                                    state: { deviceData: device }
                                })}
                            >
                                Visualizar Callback
                            </Button>
                        </Grid> 
                    </Grid>
                </MuiDialogContent>
                <MuiDialogActions>
                    <Button id="ok-close-device-details" variant="contained" color="primary" onClick={handleClose} className={classes.button}>
                        OK
                    </Button>
                </MuiDialogActions>
            </Dialog>
        </div>
    );
}