export default {
  text: {
    color: '#8A8A8D',
    textDecorationLine: 'underline',
    texttransform: 'lowercase',
    textUnderlineOffset: '1px',
    '&:hover': {
      color: '#BBD400',
      backgroundColor: 'transparent',
      textDecorationLine: 'underline',
    },
    '&:active': {
      color: '#428B00',
      backgroundColor: 'transparent',
      textDecorationLine: 'underline',
    },
    "&:disabled": {
      color: '#E0E0E0',
      backgroundColor: 'transparent',
      textDecorationLine: 'underline',
    }
  },
  textSizeLarge: {
    fontSize: '16px'
  },
  textSizeSmall: {
    fontSize: '14px'
  },
  contained: {
    backgroundColor: '#76AB00',
    color: '#FFFFFF',
    borderRadius: '99px',
    '&:hover': {
      backgroundColor: '#BBD400',
      boxShadow: 'none'
    },
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: '#BBD400',
      }
    },
    '&:active': {
      backgroundColor: '#428B00 !important',
    },
    "&:disabled": {
      backgroundColor: '#E0E0E0',
      color: '#BDBDBD'
    }
  },
  containedPrimary: {
    backgroundColor: '#76AB00',
    color: '#FFFFFF',
    borderRadius: '99px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#BBD400',
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: '#428B00 !important',
    },
    "&:disabled": {
      backgroundColor: '#E0E0E0',
      color: '#BDBDBD'
    }
  },
  containedSizeLarge: {
    padding: '8px 16px',
    height: '54px',
  },
  containedSizeSmall: {
    padding: '8px 16px',
    height: '40px',
  },
  outlined: {
    color: '#76AB00',
    borderRadius: '99px',
    border: '2px solid #76AB00',
    '&:hover': {
      color: '#BBD400',
      backgroundColor: 'transparent',
      border: '2px solid #BBD400',
    },
    '&:active': {
      color: '#428B00',
      backgroundColor: 'transparent',
      border: '2px solid #428B00',
    },
    "&:disabled": {
      color: '#E0E0E0',
      backgroundColor: 'transparent',
      border: '2px solid #E0E0E0',
    }
  },
  outlinedPrimary: {
    color: '#76AB00',
    borderRadius: '99px',
    border: '2px solid #76AB00',
    '&:hover': {
      color: '#BBD400',
      backgroundColor: 'transparent',
      border: '2px solid #BBD400',
    },
    '&:active': {
      color: '#428B00',
      backgroundColor: 'transparent',
      border: '2px solid #428B00',
    },
    "&:disabled": {
      color: '#E0E0E0',
      backgroundColor: 'transparent',
      border: '2px solid #E0E0E0',
    }
  },
  outlinedSizeLarge: {
    padding: '8px 16px',
    height: '54px',
  },
  outlinedSizeSmall: {
    height: '40px'
  }
}