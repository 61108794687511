import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Swal from 'sweetalert2/src/sweetalert2.js'
const options = ['Ativo', 'Inativo', 'Bloqueado'];
const useStyles = makeStyles(theme => ({
    buttonActive: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        width: '155px',
        cursor: 'context-menu',
        "&:hover": {
            backgroundColor: theme.palette.success.main
        }
    },
    buttonIconActive: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.success.light
        }
    },
    buttonInactive: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.success.contrastText,
        width: '155px',
        cursor: 'context-menu',
        "&:hover": {
            backgroundColor: theme.palette.warning.main
        }
    },
    buttonIconInactive: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.success.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.warning.light
        }
    },
    buttonBlock: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.success.contrastText,
        width: '155px',
        cursor: 'context-menu',
        "&:hover": {
            backgroundColor: theme.palette.error.main
        }
    },
    buttonIconBlock: {
        backgroundColor: theme.palette.error.main,
        display: 'none',
        color: theme.palette.success.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.error.light
        }
    },
    buttonGroup: {
        width: '150px'
    },
    paper: {
        // backgroundColor:'white',
        zIndex: '3'
    }
}));
const Status = props => {
    const { selected, key } = props
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(selected);

    const handleMenuItemClick = (event, index) => {
        const swalWithBootstrapButtons = Swal.mixin({
            buttonsStyling: true
        })

        swalWithBootstrapButtons.fire({
            title: '<h5>Você tem certeza que deseja alterar o status de <u>' + options[selectedIndex] + '</u> para <u>' + options[index] + '</u> ?</h5>',
            text: index === 2 ? 'Importante: Ao alterar o status para Bloqueado não será possível alterar novamente' : '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                setSelectedIndex(index);
                setOpen(false);
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };


    return (
        <Grid container direction="column" alignItems="left">
            <Grid item xs={12}>
                {selectedIndex === 2 ?
                    <Button size="small" key={key} variant="contained" className={classes.buttonBlock} >{options[selectedIndex]}</Button>
                    :
                    <>
                        <ButtonGroup size="small" ref={anchorRef} className={classes.buttonGroup} aria-label="split button">
                            <Button size="small" key={key} variant="contained" className={selectedIndex === 0 ? classes.buttonActive : classes.buttonInactive} >{options[selectedIndex]}</Button>
                            <Button
                                key={key}
                                className={selectedIndex === 0 ? classes.buttonIconActive : classes.buttonIconInactive}
                                size="small"
                                aria-controls={open ? 'split-button-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-label="select merge strategy"
                                aria-haspopup="menu"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleToggle()
                                }}
                            >
                                <ArrowDropDownIcon />
                            </Button>
                        </ButtonGroup>
                        <Popper open={open} anchorEl={anchorRef.current} role={undefined} className={classes.paper} transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                    className={classes.paper}
                                >
                                    <Paper className={classes.paper}>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList id="split-button-menu">
                                                {options.map((option, index) => (
                                                    <MenuItem
                                                        key={option}
                                                        selected={index === selectedIndex}
                                                        onClick={(event) => handleMenuItemClick(event, index)}
                                                    >
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </>
                }
            </Grid>
        </Grid>
    );
}

export default Status