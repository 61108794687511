import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Divider,
    Grid,
    IconButton,
    Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { State } from '../../components';
import { RealTimeResultCommandsContext } from '../../context/RealTimeContext';

const useStyles = makeStyles((theme) => ({
    headerContainer: {
        padding: theme.spacing(4),
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    header: {
        padding: theme.spacing(0),
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headerTable: {
        padding: theme.spacing(4),
        paddingTop: theme.spacing(0),
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
        }
    },
    title: {
        margin: theme.spacing(3)
    },
    container: {
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(0),
        }
    },
    boxState: {
        display: 'flex',
        alignItems: 'center'
    },
    stateElement: {
        marginRight: theme.spacing(1)
    },
    hide: {
        display: 'none',
    },
    show: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 1,
        transform: 'none',
        transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    gridSearch: {
        display: 'flex',
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(1),
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1),
        }
    },
    gridSearchIcon: {
        display: 'flex',
        marginRight: theme.spacing(4),
        marginBottom: theme.spacing(1),
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(0),
        }
    },
    textField: {
        width: '30vw',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));

export default function ManageDevice({ history }) {
    //STYLE
    const classes = useStyles();

    //VARIABLES
    const inputSearchRef = useRef();
    const deviceData = history.location.state.deviceData || { device_name: "", deviceKey: "", status: false };
    let listUsers = JSON.parse(sessionStorage.getItem('listUsers')) || [];

    //GLOBAL VARIABLES
    const { loadingCommand } = useContext(RealTimeResultCommandsContext);

    //STATES
    const [openModal, setOpenModal] = useState(false);
    const [listUsersState, setListUsersState] = useState(filterListUser(listUsers) || [])
    const [personSelected, setPersonSelected] = useState(filterListUser(listUsers) || []);
    const [openSearch, setOpenSearch] = useState(false);
    const [filter, setFilter] = useState("");
    const [dataFilter, setDataFilter] = useState(filterListUser(listUsers) || []);

    const [openModalFilter, setOpenModalFilter] = useState(false);

    function filterListUser(list) {
        var newListUsers = [];
        if (list !== null && list.length > 0) {
            newListUsers = list.filter(el => el.deviceKey === deviceData.deviceKey)
            return newListUsers.length > 0? newListUsers[0].users:[]
        }
        else {
            return newListUsers
        }
    }
    useEffect(() => {
        if (filter !== "") {
            var newArray = listUsersState.filter((value) => {
                value = value.name.toLowerCase();
                var compound = value.split(' ');
                var indexOfCompound = -1;
                if (compound.length > 1)
                    indexOfCompound = compound[1].indexOf(filter.toLowerCase())
                return value.indexOf(filter.toLowerCase()) === 0 || indexOfCompound === 0
            });

            setDataFilter(newArray);
        }
        else {
            setDataFilter(listUsersState)
        }
    }, [filter])

    // useEffect(() => {
    //     inputSearchRef.current.focus();
    // }, [openSearch])

    useEffect(() => {
        setTimeout(() => {
            if (loadingCommand['manageDevice'].open === false) {
                let listUsersDevices = JSON.parse(sessionStorage.getItem('listUsers')) || [];
                var newListUsers = [];
                if (listUsersDevices !== null && listUsersDevices.length > 0) {
                    newListUsers = listUsersDevices.filter(el => el.deviceKey === deviceData.deviceKey)
                    if(newListUsers.length > 0){
                        setDataFilter(newListUsers[0].users)
                        setListUsersState(newListUsers[0].users)
                    }
                }
                else {
                    setDataFilter(newListUsers)
                    setListUsersState(newListUsers)
                }
            }
        }, 2000)

    }, [loadingCommand['manageDevice'].open])

    // function requestUsers(deviceId){
    //     ListUser(deviceId)
    //         .then(response => {
    //             console.log(response)
    //             setLoadingCommand({...loadingCommand, 'manageDevice': {deviceId: deviceId, open: true }});
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         })
    //         .finally(() => {
    //         });
    // }

    return (
        <Container className={classes.container}>
            <IconButton onClick={() => { history.push('/devices'); }}>
                <ArrowBackIcon />
            </IconButton>
            <Grid container className={classes.headerContainer} spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                    <Grid container className={classes.header} spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> Nome: </Typography> {deviceData.deviceName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> Número Serial: </Typography> {deviceData.deviceKey}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} className={classes.boxState}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> Versão: </Typography> {deviceData.lastDeviceInfo?.version}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> Número Pessoas: </Typography> {deviceData.lastDeviceInfo?.personCount}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5} lg={5}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> Número Faces: </Typography> {deviceData.lastDeviceInfo?.faceCount}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} className={classes.boxState}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> IP: </Typography> {deviceData.lastDeviceInfo?.ip}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} className={classes.header}>
                    <Typography variant="subtitle1" color="textSecondary" component="span" className={classes.stateElement}> Status: </Typography>
                    <State selected={deviceData.status} />
                </Grid>
            </Grid>
            <Divider light />
            {/* <Grid container>
                <Grid item lg={3}>
                    <Typography variant="h4" color="textPrimary" component="h4" className={classes.title}>
                        Cadastrados:
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.headerTable} >
                <Grid item lg={7} className={classes.gridSearch}>
                    <Box className={
                        clsx({
                            [classes.hide]: !openSearch,
                            [classes.show]: openSearch
                        })
                    }>
                        <TextField
                            inputRef={inputSearchRef}
                            className={classes.textField}
                            placeholder="Pesquisar"
                            value={filter}
                            autoFocus={true}
                            onChange={e => setFilter(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <IconButton onClick={() => { setOpenSearch(false); setFilter("") }}>
                                        <CloseIcon />
                                    </IconButton>
                                )
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item lg={2} xs={3} className={classes.gridSearchIcon}>
                    <Hidden smUp>
                        <IconButton onClick={() => setOpenModalFilter(true)}>
                            <FilterListIcon />
                        </IconButton>
                    </Hidden>
                    <IconButton onClick={() => setOpenSearch(!openSearch)}>
                        <SearchIcon />
                    </IconButton>
                    <IconButton onClick={() => requestUsers(deviceData.deviceKey)}>
                        <RefreshIcon />
                    </IconButton>
                </Grid>
                <TablePerson data={dataFilter} setData={setDataFilter} requestUsers={requestUsers} setOpenModal={setOpenModal} personSelected={personSelected} setPersonSelected={setPersonSelected} deviceData={deviceData} openModalFilter={openModalFilter} setOpenModalFilter={setOpenModalFilter} loadingCommand={loadingCommand} setLoadingCommand={setLoadingCommand} />
            </Grid>
            <FormEdit openModal={openModal} setOpenModal={setOpenModal} personSelected={personSelected} setPersonSelected={setPersonSelected} setData={setDataFilter} data={dataFilter} deviceData={deviceData} loadingCommand={loadingCommand} setLoadingCommand={setLoadingCommand} />
            <WaitingCommand openMessage={loadingCommand['manageDevice'].open} loadingCommand={loadingCommand} setLoadingCommand={setLoadingCommand} page={'manageDevice'} /> */}
        </Container >
    );
}
