import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Container,
    Fab,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core';
import { InputListClients, FilterCallback } from '../../../../components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import clsx from 'clsx';
import { GetAllDevices } from '../../../../API/endpoints';
import Swal from 'sweetalert2/src/sweetalert2.js';
import AppContext, { isAdmin, saveUserInfo, isUserView } from '../../../../context/AppContext';
import { validate } from 'validate.js';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(0),
        paddingLeft: 0,
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(0),
            padding: theme.spacing(2),
        }
    },
    title: {
        margin: theme.spacing(3, 3, 2, 3),
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0),
        }
    },
    hide: {
        display: 'none',
    },
    show: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 1,
        transform: 'none',
        transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    gridSearch: {
        display: 'flex',
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(0),
        }
    },
    textField: {
        width: '30vw',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    groupActionsHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'nowrap',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2)
        },
    },
    buttonFilter: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttonTextCleanFilter: {
        color: '#8A8A8D',
        textDecorationLine: 'underline'
    }
}));

var initialValues = {
    initialDate: '',
    finalDate: '',
    user: "",
    devices: [],
    status: "",
}
function Header({ history, getCallback, data, setPage,setData, setLoading,seturlWs, dataFilter, setDataFilter, formState, setFormState, dataUpdate, clientSelected, setClientSelected, getWebSocket }) {
    //STYLE
    const classes = useStyles();

    //STATE
  
    const [openSearch, setOpenSearch] = useState(false);
    const [filter, setFilter] = useState("");
    const [openModalFilter, setOpenModalFilter] = useState(false);
   
    const [devices, setDevices] = useState([]);
    const [cleanFilter, setCleanFilter] = useState(false);
    const [changeFilterText, setChangeFilterText] = useState(false);
    const [formFilter, setFormFilter] = useState([]);

    //VARIABLES
    const inputSearchRef = useRef();

    useEffect(() => {
        getDevices();
    }, [])

    //FILTER
    useEffect(() => {
        setChangeFilterText(!changeFilterText);
    }, [filter])

    useEffect(() => {
        inputSearchRef.current.focus();
    }, [openSearch])

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            cancelButton: 'swal2-cancel'
        },
    })

    function getDevices() {
        setLoading(true);
        var hasError = true;
        GetAllDevices()
            .then(response => {
                if (response.status === 200) {
                    setDevices(response.data.devices);
                    hasError = false
                }

                if (response.status === 401) {
                    swalWithBootstrapButtons.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Sessão expirada ',
                    }).then((result) => {
                        history.push("/login")
                    })
                    hasError = false
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
                setCleanFilter(!cleanFilter)
                if (hasError) {
                    swalWithBootstrapButtons.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Falha ao listar dispositivos.',
                    })
                }
            });
    }

    const changeClientSelect = (value) => {
        setClientSelected(value);
        saveUserInfo(value);
        getDevices();
        getCallback(initialValues);
        setPage(0);
        getWebSocket().close();
        setFormState(initialValues)

        const url = new URL("/websocket/device-callbacks", process.env.REACT_APP_API_BASE_URL);
        url.protocol = url.protocol === "https:" ? "wss:":"ws:";
        const urlWs = url;
        seturlWs(urlWs);
        sessionStorage.setItem('callbacks', JSON.stringify([]));
        setData([]);
        setDataFilter([]);
    }

    const filterIsEmpty = () => {
        if(validate.isEmpty(formState.initialDate) && validate.isEmpty(formState.finalDate) && validate.isEmpty(formState.status) && validate.isEmpty(formState.user) && validate.isEmpty(formState.devices)){
            return true
        } 
        return false
    }

    const handleFilter = (values, isClean = false) => {
        if (JSON.stringify(values) !== JSON.stringify(initialValues) || !validate.isEmpty(filter)) {
            setFormFilter(values)
            var newDevices = values?.devices.filter((id1) => 0 === id1.deviceKey);
            var newValues = {
                ...values,
                devices: newDevices?.length > 0 ? "" : values?.devices,
            };

            var newArray = data;

            var arrayDevice = new Set();
            //COMENTADO PORQUE O FILTRO ESTÁ SENDO FEITO NO BACK
            // //FILTRO USUÁRIO
            // if (!validate.isEmpty(newValues.user)) {
            //     newArray = data.filter((value) => {
            //         var user = value?.deviceCallback?.personId?.toLowerCase();
            //         var compoundUser = user?.split(' ');
            //         if (compoundUser?.length > 1) {
            //             var arrayCompoundUser = compoundUser.map((name, index) => {
            //                 var newName = compoundUser.slice(index, compoundUser.length).join(' ');
            //                 return newName.indexOf(values.user?.toLowerCase());
            //             })
            //         }
            //         return user?.indexOf(values.user?.toLowerCase()) === 0 || arrayCompoundUser && arrayCompoundUser?.indexOf(0) !== -1
            //     });
            // }
            // //FILTRO DISPOSITIVO
            // if (!validate.isEmpty(newValues.devices)) {
            //     var temporaryArrayDevice = [];
            //     var result = values?.devices.map(deviceFilter => {
            //         temporaryArrayDevice = newArray.filter((value) => {
            //             var name = value?.deviceCallback?.deviceKey?.toLowerCase();
            //             var compound = name?.split(' ');
            //             if (compound?.length > 1) {
            //                 var arrayCompound = compound.map((name, index) => {
            //                     var newName = compound.slice(index, compound.length).join(' ');
            //                     return newName.indexOf(deviceFilter.deviceName?.toLowerCase())
            //                 })
            //             }
            //             return name?.indexOf(deviceFilter.deviceName?.toLowerCase()) === 0;
            //         })
            //         arrayDevice = [...arrayDevice, ...temporaryArrayDevice]
            //     });
            //     newArray = arrayDevice;
            // }
            // //FILTRO STATUS
            // if (!validate.isEmpty(newValues.status)) {
            //     newArray = newArray.filter((value) => {
            //         var status = value?.deviceCallbackMetadata?.callbackAsReceivedByTheClient;
            //         return status?.toString().toLowerCase() === values?.status?.toString().toLowerCase()
            //     });
              
            // }
            //FILTRO PESQUISA
            if (!validate.isEmpty(filter)) {
                newArray = newArray.filter((value) => {
                    var name = value?.deviceCallback?.personId?.toLowerCase();
                    var deviceId = value?.deviceCallback?.deviceKey?.toLowerCase();
                    var compound = name?.split(' ');
                    if (compound?.length > 1) {
                        var arrayCompound = compound.map((name, index) => {
                            var newName = compound.slice(index, compound.length).join(' ');
                            return newName.indexOf(filter.toLowerCase());
                        })
                    }
                    return name.toString()?.indexOf(filter?.toLowerCase()) === 0 || deviceId?.indexOf(filter?.toLowerCase()) === 0
                });
            }

            // //FILTRO DATA 
            // if (!validate.isEmpty(newValues.initialDate) || !validate.isEmpty(newValues.finalDate)) {
            //     newArray = newArray.filter((value) => {
            //         var date = new Date(value?.deviceCallback.time).setHours(0, 0, 0, 0);
            //         var initialDate = new Date(values.initialDate).setHours(0, 0, 0, 0);
            //         var finalDate = new Date(values.finalDate).setHours(0, 0, 0, 0);
            
            //         return date >= initialDate &&  finalDate >= date;
            //     });
            // }
            setDataFilter(newArray);
            setPage(0);


            if (isClean) {
                setDataFilter(data);
            }
        }
        else {
            setDataFilter(data);
        }
    }

    return (
        <Container className={classes.container}>
            <Grid container>
                <Grid item lg={12} xs={12}>
                    <Typography variant="h2" component="h2" className={classes.title}>
                        Callback
                    </Typography>
                </Grid>
                {isAdmin() || isUserView() ?
                    <Grid item lg={4} md={4} xs={12} className={classes.gridSearch}>
                        <InputListClients clientSelected={clientSelected} setClientSelected={setClientSelected} changeClientSelect={changeClientSelect} />
                    </Grid> : ""}
                <Grid item lg={isAdmin() || isUserView() ? 3 : 7} md={3} xs={8} className={classes.gridSearch}>
                    <Box className={
                        clsx({
                            [classes.hide]: !openSearch,
                            [classes.show]: openSearch
                        })
                    }>
                        <TextField
                            inputRef={inputSearchRef}
                            className={classes.textField}
                            placeholder="Pesquisar por usuário ID ou dispositivo"
                            value={filter}
                            autoFocus={true}
                            onChange={e => setFilter(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <IconButton onClick={() => { setOpenSearch(false); setFilter("") }}>
                                        <CloseIcon />
                                    </IconButton>
                                )
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={4} md={4} lg={4} >
                    <Grid container className={classes.groupActionsHeader}>
                        { !filterIsEmpty() && !openSearch && <Grid item lg={3} xs={6} >
                            <Button onClick={() => setCleanFilter(!cleanFilter)} className={classes.buttonTextCleanFilter}>
                                Limpar filtro
                            </Button>
                        </Grid>}
                        <Grid item lg={1} xs={4} >
                            <IconButton onClick={() => setOpenSearch(!openSearch)}>
                                <SearchIcon />
                            </IconButton>
                        </Grid>
                        <Grid item lg={1} xs={4} className={classes.buttonFilter}>
                            <Fab aria-label="filtro" color="primary" size="small" onClick={() => setOpenModalFilter(true)}>
                                <FilterListIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <FilterCallback history={history} data={data} formState={formState} setFormState={setFormState} getCallback={getCallback} dataUpdate={dataUpdate} changeFilterText={changeFilterText} openModalFilter={openModalFilter} setOpenModalFilter={setOpenModalFilter} devices={devices} handleFilter={handleFilter} cleanFilter={cleanFilter} />
        </Container>
    );
}

export default React.memo(Header);