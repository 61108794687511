import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Divider,
    Fab,
    Hidden,
    IconButton,
    Typography
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import RepeatIcon from '@material-ui/icons/Repeat';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    progress: {
        margin: 'auto',
        display: 'flex'
    },
    dialogTitle: {
        fontFamily: 'Raleway, Arial',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        textAlign: 'center',
        fontSize: '22px',
        flexDirection: 'column',
        color: '#8f9397',
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            textAlign: 'left',
        }
    },
    iconError: {
        fontSize: theme.spacing(6),
        color: '#f27474',
        display: 'flex',
        marginBottom: theme.spacing(1)
    },
    dialogText: {
        fontSize: '16px'
    },
    buttonAlert: {
        paddingTop: '16px',
        paddingBottom: '16px',
        borderRadius: '24px',
        minWidth: '5vw',
        width: 'auto',
        height: '48px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '15vw',
            width: '100%'
        }
    },
    content: {
        paddingTop: '0 !important'
    },
    save: {
        paddingTop: '16px',
        paddingBottom: '16px',
        width: 'auto',
        height: '48px',
        padding: ' 0 16px',
        minwidth: '80px',
        minHeight: 'auto',
        borderRadius: '24px',
        cursor: 'pointer',
        //textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginLeft: '0 !important',
            marginTop: theme.spacing(2)
        }
    },
    dialogActions: {
        padding: theme.spacing(2, 1),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    paper: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '12px'
        }
    },
    boxClose: {
        position: 'absolute',
        right: 0,
        top: 0
    }
}));

export default function AlertError({ openError, setOpenError, children, handleBackupCommandPerson, handleBackupCommandAllPerson }) {
    //STYLE
    const classes = useStyles();

    //STATES

    return (
        <Dialog
            fullWidth
            open={openError}
            onClose={() => setOpenError(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='md'
            classes={{ paper: classes.paper }}
        >
            <IconButton onClick={() => setOpenError(false)} className={classes.boxClose}>
                <CloseIcon />
            </IconButton>
            <DialogTitle className={classes.dialogTitle} disableTypography={true}>
                {/* <HighlightOffIcon className={classes.iconError} />
                Erro */}
                Os seguintes erros impediram o envio de alguns comandos:
            </DialogTitle>
            <PerfectScrollbar>
                <DialogContent className={classes.content}>
                    {/* <DialogContentText className={classes.dialogText}>
                        Os seguintes erros impediram o envio de alguns comandos:
                    </DialogContentText> */}
                    {children}
                </DialogContent>
            </PerfectScrollbar>
            <DialogActions className={classes.dialogActions} spacing={0}>
                {/* <Button onClick={() => window.print()} color="primary" autoFocus className={classes.save}>
                    salvar
                </Button> */}
                <Hidden smDown>
                    <Button variant="text" onClick={() => setOpenError(false)}  autoFocus classes={{ root: classes.buttonRoot }} className={classes.save}>
                        fechar
                    </Button>
                </Hidden>
                <Button variant="contained" color="primary" className={classes.buttonAlert} onClick={() => handleBackupCommandAllPerson()}>
                    <RepeatIcon className={classes.extendedIcon} />
                    Utilizar novamente todos os dados
                </Button>

            </DialogActions>
        </Dialog>
    );
}
