import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Grid, 
  IconButton,
  Typography,  
} from '@material-ui/core';
import { Status, State} from '../../components';
import clsx from 'clsx';
import TableDevice from '../User/TableDevice';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    '@media screen and (max-width: 700px)': {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  listText: {
    color: 'white',
    [theme.breakpoints.up('xs')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(0),
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const CardComponent = props => {
  const classes = useStyles();
  const { companyKey, checked, setChecked, data, table_columns, isCollapse, elements, setElements, index } = props;

  const handleChange = (event) => {
    if (checked && checked['id'] === elements[index].id)
      setChecked(null);
    else
      setChecked(elements[index]);
  };

  const [expanded, setExpanded] = useState(false);
  const [device, setDevice] = useState(null);

  const handleExpandClick = () => {
    if (!expanded)
      setDevice(elements[index].device)
    setExpanded(!expanded);
  };

  useEffect(() => {
    setDevice(elements[index].device)
  }, [elements]);
  
  return (
    <Grid flexDirection="row">
      <Grid item lg={12} sm={12}>
        <Card id="cardList" className={classes.root} fullWidth >
          <CardContent>
            <Grid container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item lg={1} sm={1} xs={12}>
                <Checkbox
                  className={classes.listText}
                  color="primary"
                  checked={checked && checked['id'] === elements[index].id ? true : false}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={event => handleChange(event)}
                />
              </Grid>

              {table_columns.map((column, index) => (
                column.name === 'status' ?
                  <Grid item key={companyKey} lg={isCollapse ? 2 : table_columns.length === 4 ? 2 : 3} sm={isCollapse ? 2 : table_columns.length === 4 ? 2 : 3} xs={8}>
                    <Status selected={data[index]}  />
                  </Grid> : 
                  column.name === 'state' ?
                    <Grid item lg={3} sm={3} xs={8} key={companyKey} >
                      <State selected={data[index]} />
                    </Grid> :
                    <Grid item lg={table_columns.length === 4 ? 3 : 4} sm={table_columns.length === 4 ? 3 : 4} xs={12}>
                      <Typography className={classes.listText}>
                        {data[index]}
                      </Typography >
                    </Grid>
              ))}
              {isCollapse ?
                <Grid item lg={1}>
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Grid> : ''}
            </Grid>
          </CardContent>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <TableDevice elements={elements} setElements={setElements} index={index} data={device} />
            </CardContent>
          </Collapse>
        </Card>
      </Grid>
    </Grid>
  );
}
export default CardComponent