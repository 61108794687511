import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Card, ClickAwayListener, Grid, List, ListItem, ListItemText, ListItemIcon, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import moment from 'moment';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BootstrapTooltip from '../BootstrapTooltip';
import validate from 'validate.js';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '0',
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        backgroundColor: 'transparent',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    cardBody: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        margin: theme.spacing(2, 0),
        boxShadow: ' 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius: '8px',
    },
    cardBodyEmpty: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        margin: theme.spacing(2, 0),
        textAlign: 'center',
        padding: theme.spacing(3)
    },
    rowCard: {
        padding: '5%',
        borderBottom: ' 1px solid rgb(117 125 129)',
        [theme.breakpoints.only('sm')]: {
            padding: '2%',
        }
    },
    deleteIcon: {
        color: '#db1414'
    },
    boxAvatar: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
    colorWarning: {
        color: "#ff9800"
    },
    textTooltip: {
        color: '#FFFFFF',
        lineHeight: '130%',
        marginBottom: theme.spacing(1)
    },
    textTooltipSecondary: {
        color: '#FFFFFF',
        lineHeight: '130%',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    boxTooltip: {
        padding: theme.spacing(1)
    },
    iconTopic: {
        fontSize: '8px'
    },
    listIcon: {
        justifyContent: 'center',
        minWidth: '22px',
        color: '#FFFFFF',
    },
    listItem: {
        paddingBottom: 0,
        color: '#FFFFFF',
        paddingLeft: 0,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 0
        }
    },
    listItemText: {
        color: '#FFFFFF',
    },
    list: {
        padding: 0,
        color: '#FFFFFF',
    },
}));

const TableMobile = ({ data, columns }) => {

    const classes = useStyles();

    const [open, setOpen] = React.useState({ open: false, index: 0 });
    const [openTooltipContingency, setOpenTooltipContingency] = React.useState({ open: false, index: 0 });

    var URLs = ["https://upnote-prod-api.azurewebsites.net/api/UpnoteBiomtech/AcessoEquipamento", "https://upnote-prod-api.azurewebsites.net/api/UpnoteBiomtech/AcessoEquipamento"]

    const handleTooltipClose = () => {
        setOpen({ open: false, index: 0 });
    };

    const handleTooltipOpen = (index) => {
        setOpen({ open: true, index: index });
    };

    const handleTooltipContingencyClose = () => {
        setOpenTooltipContingency({ open: false, index: 0 });
    };

    const handleTooltipContingencyOpen = (index) => {
        setOpenTooltipContingency({ open: true, index: index });
    };

    const bodyIncompleteSend = (url, title, subtitle, subtitle2, url2) => (
        <Box className={classes.boxTooltip}>
            <Typography variant="h5" className={classes.textTooltip}>{title}</Typography>
            <Typography variant="body2" className={classes.textTooltip}>{subtitle}</Typography>
            <List className={classes.list}>
                {url.length > 0 && url.map((parent, index) => (
                    <ListItem key={`${index}-${parent}`} className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FiberManualRecordIcon className={classes.iconTopic} />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography variant="body2" className={classes.listItemText}>{parent}</Typography>}
                        />
                    </ListItem>
                ))}
            </List>
            {
                !validate.isEmpty(subtitle2) &&
                <>
                    <Typography variant="body2" className={classes.textTooltipSecondary}>{subtitle2}</Typography>
                    <List className={classes.list}>
                        {url2 && url2.length > 0 && url2.map((parent, index) => (
                            <ListItem key={`${index}-${parent}`} className={classes.listItem}>
                                <ListItemIcon className={classes.listIcon}>
                                    <FiberManualRecordIcon className={classes.iconTopic} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body2" className={classes.listItemText}>{parent}</Typography>}
                                />
                            </ListItem>
                        ))}
                    </List>
                </>
            }
        </Box>
    )


    const ComponentStatus = (value, index, urlSend, urlNotSend) => (
        <ClickAwayListener onClickAway={() => { handleTooltipClose() }} mouseEvent={"onMouseUp"}>
            <div className={classes.itemText}>
                {
                    {
                        'send': <BootstrapTooltip
                            interactive
                            key={index}
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open.open && open.index === index}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={bodyIncompleteSend(urlSend, "Enviado com sucesso", "Reconhecimento enviado para as seguintes Urls:")}
                        >
                            <CheckCircleOutlineIcon color="primary" onClick={() => handleTooltipOpen(index)} onBlur={handleTooltipClose} />
                        </BootstrapTooltip>,
                        'incomplete': <BootstrapTooltip
                            interactive
                            key={index}
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open.open && open.index === index}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={bodyIncompleteSend(urlNotSend, "Envio incompleto", ["Reconhecimento ", <b>não enviado </b> ,"para as seguintes Urls:"], ["Reconhecimento ", <b>enviado</b>,  " para as seguintes Urls:"], urlSend)}
                        >
                            <ErrorOutlineIcon color="warning" className={classes.colorWarning} onClick={() => handleTooltipOpen(index)} onBlur={handleTooltipClose} />
                        </BootstrapTooltip>,
                        'notSend':
                            <BootstrapTooltip
                                interactive
                                key={index}
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={open.open && open.index === index}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={bodyIncompleteSend(urlNotSend, "Falha no envio", "Reconhecimento não enviado para as seguintes Urls:")}
                            >
                                <HighlightOffIcon color="error" onClick={() => handleTooltipOpen(index)} onBlur={handleTooltipClose} />
                            </BootstrapTooltip>,
                        'withoutUrl':
                             <Typography className={classes.itemText}>Sem Url cadastrada</Typography>
                    }[value]
                }
            </div>
        </ClickAwayListener>
    )

    const ComponentContingency = (value, index, urlRealTime, urlContingency) => (
        <ClickAwayListener onClickAway={handleTooltipContingencyClose} mouseEvent={"onMouseUp"}>
            <div>
            {{
                'realTime':
                    <BootstrapTooltip
                        interactive
                        key={index + "-" + value}
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onClose={handleTooltipContingencyClose}
                        open={openTooltipContingency.open && openTooltipContingency.index === index}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={bodyIncompleteSend( urlRealTime, "Tempo real", "Reconhecimento em tempo real para as seguintes Urls:")}
                    >
                        <Typography  onClick={() => handleTooltipContingencyOpen(index)} onBlur={handleTooltipContingencyClose}  className={classes.itemText}>Tempo real</Typography>
                    </BootstrapTooltip>,
                'incomplete': 
                    <BootstrapTooltip
                        interactive
                        key={index + "-" + value}
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onClose={handleTooltipContingencyClose}
                        open={openTooltipContingency.open && openTooltipContingency.index === index}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={bodyIncompleteSend(urlContingency, "Contigência parcial", ["Reconhecimento em ", <b>contigência</b>, " para as seguintes Urls:"], ["Reconhecimento em ", <b>tempo real</b>, " para as seguintes Urls:"], urlRealTime)}
                    >
                        <Typography  onClick={() => handleTooltipContingencyOpen(index)} onBlur={handleTooltipContingencyClose}  className={classes.itemText}>Contigência parcial</Typography>
                    </BootstrapTooltip>,
                'contingency':
                    <BootstrapTooltip
                        interactive
                        key={index + "-" + value}
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onClose={handleTooltipContingencyClose}
                        open={openTooltipContingency.open && openTooltipContingency.index === index}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={bodyIncompleteSend( urlContingency, "Contigência", "Reconhecimento em contigência para as seguintes Urls:")}
                    >
                        <Typography  onClick={() => handleTooltipContingencyOpen(index)} onBlur={handleTooltipContingencyClose}  className={classes.itemText}>Contigência</Typography>
                    </BootstrapTooltip>,
                'withoutUrl':
                    <Typography className={classes.itemText}>Sem Url cadastrada</Typography>
            }[value]}
       </div>
        </ClickAwayListener>
    )

    const dividerListUrlCallback = (url, indexData) => {
        var urlContingency = [];
        var iterateUrlContingency = Object.entries(url).filter(x => { 
            if(x[1] === true){ 
                urlContingency.push(x[0])
            } 
        });
        var urlRealTime = [];
        var iterateUrlRealTime = Object.entries(url).filter(x => { if(x[1] === false){ 
            urlRealTime.push(x[0])
            return x[0]} });
        var typeMessage = Object.entries(url).length === 0?'withoutUrl': urlContingency.length ===  Object.entries(url).length ? 'contingency' : urlContingency.length === 0 ? "realTime" : "incomplete";
        
        return ComponentContingency(typeMessage, indexData, urlRealTime, urlContingency);
    }

    const dividerListUrlCallbackStatus = (url, indexData) => {
        var urlSend = [];
        var iterateUrlSend = Object.entries(url).filter(x => { 
            if(x[1] === true){ 
                urlSend.push(x[0])
            } 
        });
        var urlNotSend = [];
        var iterateUrlNotSend = Object.entries(url).filter(x => { 
            if(x[1] === false){ 
                urlNotSend.push(x[0])
            } 
        });
        var typeMessage = Object.entries(url).length === 0?'withoutUrl':urlSend.length === Object.entries(url).length ? 'send' : urlSend.length === 0 ? "notSend" : "incomplete"

        return ComponentStatus(typeMessage, indexData, urlSend, urlNotSend);
    }

    return (
        <Card id="cardList" className={classes.root}>
            {data.length > 0 ?
                data.map((device, indexData) => (
                    <CardContent key={indexData} className={classes.cardBody}>
                        {columns.map((column, index) => (
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                className={classes.rowCard}
                                key={index}
                            >
                                <Grid item lg={4} sm={4} xs={5}>
                                    <Typography >
                                        {column.title}
                                    </Typography >
                                </Grid>
                                <Grid item lg={4} sm={4} xs={7}>
                                    {
                                        {
                                            'personId': <Box className={classes.boxAvatar}><Avatar alt="Foto usuário" src={device?.deviceCallback?.photoUrl} className={classes.avatar} /><Typography className={classes.itemText}> {device?.deviceCallback?.[column.name]}</Typography></Box>,
                                            'contingency': dividerListUrlCallback(device?.deviceCallbackMetadata?.webhookUrlContingencyStatus, indexData),
                                            'callbackAsReceivedByTheClient': dividerListUrlCallbackStatus(device?.deviceCallbackMetadata?.webhookUrlStatus, indexData),
                                        }[column.name] ||
                                        <Typography className={classes.itemText}>
                                            {column.name === "time" ? moment(new Date(device?.deviceCallback?.[column.name])).format('DD/MM/YYYY - HH:mm') : device?.deviceCallback?.[column.name]}
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                        ))}
                    </CardContent>
                )) :
                <CardContent className={classes.cardBodyEmpty}>
                    <Typography>
                        Lista vazia
                    </Typography >
                </CardContent>
            }
        </Card>
    );
}
export default TableMobile