import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Fab,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography
} from '@material-ui/core';
import validate from 'validate.js';
import CloseIcon from '@material-ui/icons/Close';
import { AddDevices, EditDevices } from '../../../../API/endpoints';
import Swal from 'sweetalert2/src/sweetalert2.js';

const schema = {
    device_name: {
        presence: { allowEmpty: false, message: 'Por favor, informe um nome para o dispositivo' },
    },
    device_id: {
        presence: { allowEmpty: false, message: 'Por favor, informe o número serial do dispositivo' },
    },
};


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(3)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    buttons: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderRadius: '99px'
    },
    buttonCancel: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    inputText: {
        marginBottom: theme.spacing(3),
    },
}));

const MuiDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <DialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h4">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});

const MuiDialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(5),
    },
}))(DialogContent);

const MuiDialogActions = withStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
}))(DialogActions);

export default function Form({ openModal, setOpenModal, setRequestData, requestData, formEdit, setFormEdit, modeForm, setModeForm }) {
    //STYLE
    const classes = useStyles();

    //STATE
    const [validator, setValidator] = useState([]);
    const [formState, setFormState] = useState( formEdit );

    //let isEdit = !(validate.isEmpty(formEdit.device_name) && validate.isEmpty(formEdit.device_id));
    let isEdit = modeForm === "edit";

    const handleClose = () => {
        setOpenModal(false);
        setValidator([])
        setFormEdit({ device_name: "", device_id: "" });
        setModeForm("add")
    };

    const handleChange = (name, value) => {
        setFormState(formState => ({
            ...formState,
            [name]: value
        }));
    };

    useEffect(() => {
        setFormState(formEdit);
    }, [formEdit])

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            cancelButton: 'swal2-cancel'
        },
    })

    const registerDevice = () => {
        var hasError = true;
        AddDevices(formState)
            .then(response => {
                if (response.status === 200) {
                    hasError = false;
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setRequestData(!requestData);
                if (hasError) {
                    swalWithBootstrapButtons.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Falha ao cadastrar dispositivo.',
                    })
                }
                else {
                    swalWithBootstrapButtons.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Dispositivo cadastrado com sucesso!',
                    })
                }
            });
        setFormState({ device_name: "", device_id: "" })
    } 

    const editDevice = () => {
        var hasError = true;
        EditDevices(formState.device_id, {device_name: formState.device_name})
            .then(response => {
                if (response.status === 204) {
                    hasError = false;
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setRequestData(!requestData);
                if (hasError) {
                    swalWithBootstrapButtons.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Falha ao editar dispositivo.',
                    })
                }
                else {
                    swalWithBootstrapButtons.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Dispositivo editado com sucesso!',
                    })
                }

                handleClose();
            });
        setFormState({ device_name: "", device_id: "" })
    }

    const handleSave = () => {
        let errors = validate(formState, schema, { fullMessages: false });
        if (!errors) {
            setOpenModal(false);
            if(isEdit){
                editDevice();                
            }
            else{
                registerDevice();
            }            
        }
        else {
            setValidator(errors)
        }
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                onClose={handleClose}
                aria-labelledby="Associar Dispositivo"
                open={openModal}
            >
                <MuiDialogTitle id="dialog-title" onClose={handleClose}>
                    { !isEdit ? "Associar Dispositivo" : "Editar dispositivo" }
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    <form noValidate autoComplete="off">
                        <TextField
                            label={'Nome'}
                            name={'device_name'}
                            className={classes.inputText}
                            onChange={event => handleChange(event.target.name, event.target.value)}
                            value={formState && (formState['device_name'] || '')}
                            error={Object.keys(validator).length > 0 && validator['device_name'] && validator['device_name'].length > 0}
                            helperText={validator['device_name']}
                            fullWidth
                        />
                        {!isEdit && <TextField
                            label={'Número Serial'}
                            name={'device_id'}
                            className={classes.inputText}
                            onChange={event => handleChange(event.target.name, event.target.value.toUpperCase())}
                            value={formState && (formState['device_id'] || '')}
                            error={Object.keys(validator).length > 0 && validator['device_id'].length > 0}
                            helperText={validator['device_id']}
                            fullWidth
                        />}
                    </form>
                </MuiDialogContent>
                <MuiDialogActions>
                    <Button variant="text" onClick={handleClose} className={classes.buttonCancel}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary" className={classes.buttons} onClick={() => handleSave()}>
                        Salvar
                    </Button>
                </MuiDialogActions>
            </Dialog>
        </div>
    );
}