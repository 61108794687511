import {api, apiLogin, apiAdm } from './baseUrl';

export const AddDevices = async (values) => 
    await api.post(`/device`, values);

export const EditDevices = async (deviceKey, values) => 
    await api.patch(`/device/${deviceKey}`, values);

export const RemoveDevice = async (values) => 
    await api.delete(`/device`, { data: values });

export const GetAllDevices = async () => 
    await api.get(`/devices`);

export const SetConfigDevices = async (values) => 
    await api.post(`/commands/set-config`, values);

export const AddUser = async (values) => 
    await api.post(`/users`, values);

export const ListUser = async (id) => 
    await api.get(`/users/${id}`);

export const EditUser = async (values) => 
    await api.put(`/user`, values);

export const RemoveUser = async (values) => 
    await api.delete(`/users`, { data: values });

export const SignIn = async (values) => 
    await apiLogin.post(`/auth`, values );

export const ListClients = async (values) => 
    await apiAdm.get(`/clients`, values );

export const AddClient = async (values) => 
    await apiAdm.post(`/client/register`, values);

export const EditClient = async (clientId, values) => 
    await apiAdm.patch(`/clients/${clientId}`, values);

export const RemoveClient = async (clientId) => 
    await api.delete(`/clients/${clientId}`);

export const ListCallback = async (params) => 
    await api.get(`/device-callbacks?${params}`);

