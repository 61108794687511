import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Fab,
    Grid,
    IconButton,
    InputAdornment,
    TablePagination,
    TextField,
    Typography,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { LoadingComponent, FilterCallback, TableDesktop, TableMobile, ComponentExport } from '../../components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import clsx from 'clsx';
import Swal from 'sweetalert2/src/sweetalert2.js';
import AppContext, { getToken } from '../../context/AppContext';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import validate from 'validate.js';
import useWebSocket from 'react-use-websocket';
import { getComparatorCallback, stableSort } from '../../common/ordination';
import { ListCallback } from '../../API/endpoints';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(0),
        paddingLeft: 0,
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(0),
            padding: theme.spacing(2),
        }
    },
    tableHeader: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
        backgroundColor: '#76AB00',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
        },
    },
    textHeader: {
        color: 'white',
        alignItems: 'center',
        cursor: 'pointer',
        textAlign: 'center',
    },
    view: {
        display: 'inline',
        marginLeft: theme.spacing(1),
        fontSize: 'medium'
    },
    cardHeader: {
        padding: theme.spacing(3, 6),
        backgroundColor: '#76AB00 !important',
        [theme.breakpoints.only('sm')]: {
            padding: theme.spacing(3),
        },
    },
    title: {
        margin: theme.spacing(3, 3, 2, 3),
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0),
        }
    },
    hide: {
        display: 'none',
    },
    show: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 1,
        transform: 'none',
        transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    gridSearch: {
        display: 'flex',
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(0),
        }
    },
    textField: {
        width: '30vw',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    containerPagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: '90px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
            paddingBottom: theme.spacing(4)
        }
    },
    paginationToolbar: {
        position: 'initial',
        right: 0,
        flexWrap: 'wrap',
        textAlign: 'center',
        justifyContent: 'center',
        padding: 0
    },
    groupActionsHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'nowrap',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2)
        },
    },
    buttonFilter: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttonDownload: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
    },
    buttonBack: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(3),
        },
    },
    titlePage: {
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(3),
        },
    },
    itemIcon: {
        textAlign: 'center'
    },
    buttonTextCleanFilter: {
        color: '#8A8A8D',
        textDecorationLine: 'underline'
    },
    gridClean: {
        [theme.breakpoints.down('sm')]: {
            textAlign: "right"
        },
    }
}));

var initialValues = {
    initialDate: '',
    finalDate: '',
    user: "",
    devices: [],
    status: "",
}

export default function CallbackDevice({ history }) {
    //STYLE
    const classes = useStyles();

    //GLOBAL VARIABLES
    const deviceData = history?.location?.state?.deviceData || { deviceName: "", deviceKey: "", status: false };

    //STATE
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [openSearch, setOpenSearch] = useState(false);
    const [filter, setFilter] = useState("");
    const [dataFilter, setDataFilter] = useState(data);
    const [openModalFilter, setOpenModalFilter] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openModalDownload, setOpenModalDownload] = useState(false);
    const [cleanFilter, setCleanFilter] = useState(false);
    const [formState, setFormState] = useState(initialValues);
    const [changeFilterText, setChangeFilterText] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);

    //VARIABLES
    const inputSearchRef = useRef();
    const isMobile = useMediaQuery(theme => theme.breakpoints.only('xs'));
    let filterData = stableSort(dataFilter, getComparatorCallback(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    let filterDataExport = stableSort(dataFilter, getComparatorCallback(order, orderBy));

    const columns = [
        {
            title: "Usuário ID",
            name: 'personId',
            lg: 3,
            sm: 3
        },
        {
            title: "Data",
            name: 'time',
            lg: 3,
            sm: 3
        },
        {
            title: "Padrão de envio",
            name: "contingency",
            lg: 3,
            sm: 3,
        },
        {
            title: "Transmissão",
            name: 'callbackAsReceivedByTheClient',
            lg: 3,
            sm: 3
        },
    ]

    const closeModalFilter = () => {
        setOpenModalFilter(false)
    }

    //WEBSOCKET
    const url = new URL("/websocket/device-callbacks", process.env.REACT_APP_API_BASE_URL);
    url.protocol = url.protocol === "https:" ? "wss:":"ws:";
    const [urlWs, seturlWs] = useState(url);
    var accessToken = getToken();
    urlWs.searchParams.append("authorization", accessToken);

    const [socketUrl, setSocketUrl] = useState(urlWs.toString())

    useEffect(() => {
        setSocketUrl(urlWs.toString());
    }, [urlWs])

    const { lastJsonMessage, sendMessage, getWebSocket } = useWebSocket(socketUrl, {
        onOpen: (event) => {
            console.log(`Connected WebSocket`);
        },
        onMessage: (response, b) => {
            var arrayData = JSON.parse(response.data);
            console.log(arrayData);
            var callbacksSession = data;
            var newArrayCallbacks = [];

            if (callbacksSession !== null) {
                if (Array.isArray(callbacksSession))
                    callbacksSession.unshift(arrayData.data);
                else {
                    callbacksSession = [callbacksSession];
                    callbacksSession.unshift(arrayData.data)
                }

                if (callbacksSession.length > 60) {
                    let lastItem = callbacksSession.pop();
                }

                newArrayCallbacks = callbacksSession;
            }
            else {
                newArrayCallbacks = [arrayData.data]
            }


            setData(newArrayCallbacks)

            if (JSON.stringify(formState) !== JSON.stringify(initialValues)) {
                setDataUpdate(!dataUpdate)
            }
            else
                setDataFilter(newArrayCallbacks)
        },
        onError: (event) => { console.error(event); },
        onClose: (event) => {
            console.log({ event })
            return false
        },
        shouldReconnect: (closeEvent) => {
            if (accessToken === "" || accessToken === null) {
                return false
            }
            return true
        },
    });

    const handleFilter = (values, isClean = false) => {
        if (JSON.stringify(values) !== JSON.stringify(initialValues) || !validate.isEmpty(filter)) {
            var newDevices = values?.devices.filter((id1) => 0 === id1.deviceKey);
            var newValues = {
                ...values,
                devices: newDevices?.length > 0 ? "" : values?.devices,
            };

            var newArray = data;

            //FILTRO USUÁRIO
            if (!validate.isEmpty(newValues.user)) {
                newArray = data.filter((value) => {
                    var user = value?.deviceCallback?.personId?.toLowerCase();
                    var compoundUser = user?.split(' ');
                    if (compoundUser?.length > 1) {
                        var arrayCompoundUser = compoundUser.map((name, index) => {
                            var newName = compoundUser.slice(index, compoundUser.length).join(' ');
                            return newName.indexOf(values.user?.toLowerCase());
                        })
                    }
                    return user?.indexOf(values.user?.toLowerCase()) === 0 || arrayCompoundUser && arrayCompoundUser?.indexOf(0) !== -1
                });
            }

            //FILTRO STATUS
            if (!validate.isEmpty(newValues.status)) {
                newArray = newArray.filter((value) => {
                    var status = value?.deviceCallbackMetadata?.callbackAsReceivedByTheClient;
                    return status.toString().toLowerCase() === values.status.toString().toLowerCase()
                });
            }
            //FILTRO PESQUISA
            if (!validate.isEmpty(filter)) {
                newArray = newArray.filter((value) => {
                    var name = value?.deviceCallback?.personId?.toLowerCase();
                    var deviceId = value?.deviceCallback?.deviceKey?.toLowerCase();
                    var compound = name?.split(' ');
                    if (compound?.length > 1) {
                        var arrayCompound = compound.map((name, index) => {
                            var newName = compound.slice(index, compound.length).join(' ');
                            return newName.indexOf(filter.toLowerCase());
                        })
                    }
                    return name.toString()?.indexOf(filter?.toLowerCase()) === 0 || deviceId?.indexOf(filter?.toLowerCase()) === 0 || arrayCompound && arrayCompound?.indexOf(0) !== -1
                });
            }

            //FILTRO DATA 
            if (!validate.isEmpty(newValues.initialDate) || !validate.isEmpty(newValues.finalDate)) {
                newArray = newArray.filter((value) => {
                    var date = new Date(value?.deviceCallback.time).setHours(0, 0, 0, 0);
                    var initialDate = new Date(values.initialDate).setHours(0, 0, 0, 0);
                    var finalDate = new Date(values.finalDate).setHours(0, 0, 0, 0);

                    return date >= initialDate && finalDate >= date;
                });
            }
            setDataFilter(newArray);
            setPage(0);


            if (isClean) {
                setDataFilter(data);
            }
        }
        else {
            setDataFilter(data);
        }
    }

    useEffect(() => {
        inputSearchRef.current.focus();
    }, [openSearch])

    //PAGINATION
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //FILTER
    useEffect(() => {
        setChangeFilterText(!changeFilterText);
    }, [filter])

    //ORDINATION
    const handleRequestSort = (property) => {
        const isEmpty = orderBy === property && order === 'desc';

        if (!isEmpty) {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }
        else {
            setOrder('');
            filterData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            filterDataExport = data;
        }
    };

    const filterIsEmpty = () => {
        if(validate.isEmpty(formState.initialDate) && validate.isEmpty(formState.finalDate) && validate.isEmpty(formState.status) && validate.isEmpty(formState.user)){
            return true
        } 
        return false
    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            cancelButton: 'swal2-cancel'
        },
    })

    function getCallback(filter=formState) {
        setLoading(true);
        var hasError = true;
 
        let formFilter = [
            {
                name: "startDate",
                value: validate.isEmpty(filter.initialDate)? "":moment(filter.initialDate).format("YYYY-MM-DD")
            },
            {
                name: 'endDate',
                value:  validate.isEmpty(filter.finalDate)? "":moment(filter.finalDate).format("YYYY-MM-DD") 
            },
            {
                name: "personId",
                value: filter.user
            },
            {
                name: 'deviceKey',
                value: deviceData.deviceKey
            },
            {
                name: 'status',
                value: filter.status ? "SENT" : validate.isEmpty(filter.status)? "":"NOT_SENT"
            },
        ]

        const params = new URLSearchParams()

        formFilter.forEach(param => {
            if (!validate.isEmpty(param.value)) {
                params.set(param.name, param.value)
            }
        })
        
        ListCallback(params)
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    setData(response.data.deviceCallbacks)
                    setDataFilter(response.data.deviceCallbacks);
                    hasError = false
                }
                if (response.status === 401) {
                    swalWithBootstrapButtons.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Sessão expirada ',
                    }).then((result) => {
                        history.push("/login")
                    })
                    hasError = false
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
                if (hasError) {
                    swalWithBootstrapButtons.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Falha ao listar callbacks.',
                    })
                }
            });

    }

    useEffect(() => {
        getCallback();
    }, [])

    return (
        <Container className={classes.container}>
            <Grid container>
                <IconButton onClick={() => history.goBack()} className={classes.buttonBack}>
                    <ArrowBackIcon />
                </IconButton>
                <Grid item lg={12} xs={12} className={classes.titlePage}>
                    <Typography variant="h2" component="h2" className={classes.title}>
                        Callback - {deviceData.deviceName}
                    </Typography>
                </Grid>
                <Grid item lg={7} md={3} xs={12} className={classes.gridSearch}>
                    <Box className={
                        clsx({
                            [classes.hide]: !openSearch,
                            [classes.show]: openSearch
                        })
                    }>
                        <TextField
                            inputRef={inputSearchRef}
                            className={classes.textField}
                            placeholder="Pesquisar"
                            value={filter}
                            autoFocus={true}
                            onChange={e => setFilter(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <IconButton onClick={() => { setOpenSearch(false); setFilter("") }}>
                                        <CloseIcon />
                                    </IconButton>
                                )
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4} >
                    <Grid container className={classes.groupActionsHeader}>
                        {!filterIsEmpty() && !openSearch && <Grid item lg={3} xs={6} className={classes.gridClean}>
                            <Button onClick={() => setCleanFilter(!cleanFilter)} className={classes.buttonTextCleanFilter}>
                                Limpar filtro
                            </Button>
                        </Grid>}
                        <Grid item lg={1} xs={1} >
                            <IconButton onClick={() => setOpenSearch(!openSearch)}>
                                <SearchIcon />
                            </IconButton>
                        </Grid>
                        <Grid item lg={1} xs={1} className={classes.buttonFilter}>
                            <Fab aria-label="filtro" color="primary" size="small" onClick={() => setOpenModalFilter(true)}>
                                <FilterListIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Card className={classes.tableHeader} >
                <CardContent className={classes.cardHeader}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        className={classes.marginDevice}
                    >
                        {columns.map((column, index) => (
                            <Grid key={column.index} item lg={column.lg} sm={column.sm} xs={10}
                                className={clsx({
                                    [classes.itemIcon]: column.name === "contingent",
                                })}>
                                <Typography component="h2" variant="h5" color="primary" className={classes.textHeader} onClick={e => handleRequestSort(column.name)}>
                                    {column.title}
                                    <ImportExportIcon className={order === '' || orderBy !== column.name ? classes.view : classes.hide} />
                                    <ArrowDownwardIcon className={order === 'desc' && orderBy === column.name ? classes.view : classes.hide} />
                                    <ArrowUpwardIcon className={order === 'asc' && orderBy === column.name ? classes.view : classes.hide} />
                                </Typography >
                            </Grid>)
                        )}
                    </Grid>
                </CardContent>
            </Card>
            {isMobile ?
                <TableMobile data={filterData} history={history} columns={columns} />
                :
                <TableDesktop data={filterData} history={history} columns={columns} />
            }
            <Container className={classes.containerPagination}>
                <TablePagination
                    component="div"
                    classes={{ toolbar: classes.paginationToolbar }}
                    count={dataFilter.length}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : ` até ${to}`
                        }`
                    }
                    labelRowsPerPage="Total por página:"
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 15, 30]}
                />
            </Container>
            { !validate.isEmpty(filterDataExport) && <Fab aria-label="filtro" color="primary" size="large" onClick={() => setOpenModalDownload(true)} className={classes.buttonDownload}>
                <GetAppIcon />
            </Fab>}
            <FilterCallback history={history} getCallback={getCallback} dataUpdate={dataUpdate} changeFilterText={changeFilterText} setOpenModalFilter={setOpenModalFilter} formState={formState} setFormState={setFormState} openModalFilter={openModalFilter} closeModalFilter={closeModalFilter} devices={deviceData} handleFilter={handleFilter} cleanFilter={cleanFilter} />
            <LoadingComponent open={loading} handleClose={() => setLoading(false)} />
            <ComponentExport formState={formState} callbackToExport={filterDataExport} closeModalDownload={() => setOpenModalDownload(false)} openModalDownload={openModalDownload} />
        </Container>
    );
}
