export default {
    ["BAD_IMAGE_QUALITY"]: {title: 'Reprovação na qualidade da foto', body:'Verifique se a qualidade da imagem é boa o suficiente para a leitura de dados'},
    ["LIVENESS_VERIFICATION_FAILED"]: {title: 'Reprovação de prova de vida - liveness', body: 'Não foi possível identificar se a foto pertence a uma pessoa real' },
    ["MATCHING_VERIFICATION_FAILED"]: { title: 'Falha na verificação', body: 'Não foi possível encontrar correspondente' },
    ["INVALID_OR_MISSING_PARAMETER"]: { title: 'Dados incompletos enviados', body: 'Verifique se preencheu todos os campos do formulário.' },
    ["UNSUPPORTED_MEDIA_TYPE"]: { title: 'Formato da imagem não reconhecido', body: 'Formato de imagem não suportado pelo sistema' },
    ["NOT_FOUND"]: { title: 'Objeto não encontrado', body: 'Não foi possível encontrar o elemento enviado' },
    ["ALREADY_EXISTS"]: { title: 'Elemento já cadastrado', body: 'Verifique se você já realizou este cadastro' },
    ["UNAUTHORIZED"]: { title: 'Ação não autorizada', body: 'Verifique se você possui permissão para realizar o comando enviado.' },
    ["UNAUTENTICATED"]: { title: 'Falha na autenticação', body: 'Verifique se você está logado no sistema e tente novamente.' },
    ["UNEXPECTED"]: { title: 'Erro inesperado', body: 'Ocorreu um erro inesperado, por favor entre em contato com o suporte ou tente novamente mais tarde.' },
}