import React from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import { AppProvider } from './context/AppContext';
import { BrowserRouter } from "react-router-dom";
import Routes from './Routes'
import {createBrowserHistory} from 'history'
import { RealTimeProvider } from "./context/RealTimeContext";
import { NotificationsProvider } from "./context/NotificationsContext";

const myHistory = createBrowserHistory()

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter history={myHistory}>
        <AppProvider>
          <NotificationsProvider>
            <RealTimeProvider>
              <Routes />
            </RealTimeProvider>
          </NotificationsProvider>
        </AppProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
