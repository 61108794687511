import React, { useContext } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import AppContext, { isAuthenticated, isAdmin, isUserView } from './context/AppContext';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
  Callback as CallbackView,
  CallbackDevice as CallbackDeviceView,
  Clients as ClientsView,
  Commands as CommandsView,
  Dashboard as DashboardView,
  Devices as DevicesView,
  Login as LoginView,
  NotFound as NotFoundView,
  ManageDevice as ManageDeviceView
} from './views';
import { Company, User, Report } from './heartbeatModules';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TocIcon from '@material-ui/icons/Toc';
import TabletAndroidIcon from '@material-ui/icons/TabletAndroid';
import PeopleIcon from '@material-ui/icons/People';

const routes = [
  //   {
  //     title: 'Painel',
  //     name: 'dashboard',
  //     component: DashboardView,
  //     layout: MainLayout,
  //     path: '/dashboard',
  //     icon: <DashboardIcon />,
  //     exact:true
  // },
  // Company,
  // User,
  // Report,
  {
    title: 'Dispositivos',
    name: 'Device',
    component: DevicesView,
    layout: MainLayout,
    path: '/devices',
    icon: <TabletAndroidIcon />,
    exact: true,
    isIcon: true,
    hasUsersRestriction: false,
  },
  // !Página `/commands` comentada pois está enviando comandos desatualizados para a API.
  // {
  //   title: 'Comandos',
  //   name: 'commands',
  //   component: CommandsView,
  //   layout: MainLayout,
  //   path: '/commands',
  //   icon: <TocIcon />,
  //   exact: true,
  //   isIcon: true,
  //   hasUsersRestriction: true,
  //   rolesWithAccess: ["admin", "client"]
  // },
  {
    title: 'Clientes',
    name: 'clients',
    component: ClientsView,
    layout: MainLayout,
    path: '/clients',
    icon: <PeopleIcon />,
    exact: true,
    isIcon: true,
    hasUsersRestriction: true,
    rolesWithAccess: ["admin"]
  },
  {
    title: 'Callback',
    name: 'callback',
    component: CallbackView,
    layout: MainLayout,
    path: '/callback',
    icon: '/icon-callback-black.png',
    exact: true,
    isIcon: false,
    hasUsersRestriction: false,
  },
];

const Routes = () => {

  const { setContextRoutes } = useContext(AppContext);

  setContextRoutes(routes);

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/login"
      />
      {routes.filter(route => !route.hasUsersRestriction).map(route =>
        <RouteWithLayout
          key={route.title}
          component={route.component}
          exact
          layout={route.layout}
          path={route.path}
          module_form={route.module_form}
          table={route.table}
          textButtonAdd={route.textButtonAdd}
          isPrivate={true}
        />

      )}
      {routes.filter(route => route.hasUsersRestriction && route.rolesWithAccess.includes("admin") && isAdmin()).map(route =>
        <RouteWithLayout
          key={route.title}
          component={route.component}
          exact
          layout={route.layout}
          path={route.path}
          module_form={route.module_form}
          table={route.table}
          textButtonAdd={route.textButtonAdd}
          isPrivate={true}
        />

      )}
      {routes.filter(route => route.hasUsersRestriction && route.rolesWithAccess.includes("client") && !isAdmin() && !isUserView()).map(route =>
        <RouteWithLayout
          key={route.title}
          component={route.component}
          exact
          layout={route.layout}
          path={route.path}
          module_form={route.module_form}
          table={route.table}
          textButtonAdd={route.textButtonAdd}
          isPrivate={true}
        />

      )}
      <RouteWithLayout
        key='Login'
        component={LoginView}
        exact
        layout={MinimalLayout}
        path='/login'
        isPrivate={false}
      />
      <RouteWithLayout
        key='ManageDevice'
        component={ManageDeviceView}
        exact
        layout={MainLayout}
        path='/devices/manageDevice/:id?'
        isPrivate={true}
      />
      <RouteWithLayout
        key='CallbackDevice'
        component={CallbackDeviceView}
        exact
        layout={MainLayout}
        path='/device/:id?/callback'
        isPrivate={true}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
        isPrivate={false}
      />
      <Redirect to="/not-found" />
      <Redirect
        exact
        from="/login"
        to="/devices"
      />
    </Switch>
  );
}

export default Routes;
