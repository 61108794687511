import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
    Typography
} from '@material-ui/core';
import validate from 'validate.js';
import CloseIcon from '@material-ui/icons/Close';
import { AddClient, ListClients, EditClient } from '../../../../API/endpoints';
import Swal from 'sweetalert2/src/sweetalert2.js';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';

validate.validators.custom = function (value) {
    if (value.match(/[^a-zA-Z\d]/g)) {

    }
    else
        return "Por favor, adicione pelo menos 1 caractere especial. ";
};

const schema = {
    name: {
        presence: { allowEmpty: false, message: 'Por favor, informe um nome para o cliente. ' },
    },
    email: {
        presence: { allowEmpty: false, message: 'Por favor, informe o e-mail do cliente. ' },
        email: {
            message: "Por favor, informe um e-mail válido. "
        }
    },
    password: {
        presence: { allowEmpty: false, message: 'Por favor, informe uma senha para o cliente. ' },
        length: {
            minimum: 6,
            message: 'A senha não pode ter menos que 6 caracteres. '
        },
        custom: "Por favor, adicione pelo menos 1 caractere especial. "
    },
    clientType: {
        presence: { allowEmpty: false, message: 'Por favor, informe o tipo de usuário. ' },
    }
};


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(3)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    buttons: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderRadius: '99px'
    },
    buttonCancel: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    inputText: {
        marginBottom: theme.spacing(3),
    },
    allClientsCheckbox: {
        marginBottom: theme.spacing(1),
        marginLeft: '4px'
    },
    itemAutocomplete: {
        marginLeft: theme.spacing(1),
    },
    sizeMobile: {
        [theme.breakpoints.down('sm')]: {
            minWidth: '90vw'
        }
    },
    iconDeleteInput: {
        fontSize: theme.spacing(2)
    },
    addUrl: {
        padding: '0 16px !important'
    }
}));

const MuiDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <DialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h4">{children}</Typography>
            {onClose ? (
                <IconButton id="close-dialog-client" aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});

const MuiDialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2, 4),
    },
}))(DialogContent);

const MuiDialogActions = withStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
}))(DialogActions);

var initialValues = { email: "", name: "", password: "", clientType: "", parents: [], webhookURLs: [] }

export default function Form({ openModal, setOpenModal, setRequestData, requestData, setFormEdit, formEdit, modeForm, setModeForm }) {
    //STYLE
    const classes = useStyles();

    //STATE
    const [validator, setValidator] = useState([]);
    const [formState, setFormState] = useState(formEdit);
    const [clients, setClients] = useState([]);

    //VARIABLES
    let isEdit = modeForm === "edit";

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            cancelButton: 'swal2-cancel'
        },
    })

    useEffect(() => {
        ListClients()
            .then(response => {
                setClients(getOnlyClient(response.data.clients));
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {

            });
    }, [])

    useEffect(() => {
        const filterParent = (email) => {
            var clientSelectFinal = [];
            email.forEach(element => {
                var clientSelect = clients.filter(client => client.email === element)
                clientSelectFinal.push(clientSelect[0])
            });
            return clientSelectFinal;
        }

        let newForm = formEdit;
        if (!validate.isEmpty(formEdit.parents)) {
            newForm = {
                ...formEdit,
                parents: filterParent(formEdit.parents)
            }
        }
        setFormState(newForm);
    }, [formEdit])

    const getOnlyClient = (array) => {
        var result = array.filter(el => el.name.indexOf("READ ONLY") === -1);
        return result
    }

    const hasObject = (array, object) => {
        const results = array.filter((id1) => object?.email === id1?.email);
        return results.length > 0
    }

    const changeListParents = value => {
        handleChange("parents", [...new Set(value)]);
    }

    const handleDeleteTag = index => {
        var arrayCopy = Array.from(formState.parents);
        arrayCopy.splice(index, 1);
        handleChange("parents", arrayCopy)
    }

    const handleClose = () => {
        setOpenModal(false);
        setValidator([]);
        setFormState(initialValues);
        setFormEdit(initialValues);
        setModeForm("add")
    };

    const handleChange = (name, value) => {
        setFormState(formState => ({
            ...formState,
            [name]: value
        }));
    };

    const handleChangeUrlCallback = (value, index) => {
        let arrayUrlCallback = Array.from(formState.webhookURLs);
        arrayUrlCallback.splice(index, 1, value);

        setFormState(formState => ({
            ...formState,
            webhookURLs: arrayUrlCallback
        }));
    };

    const handleEditClient = client => {
        var dataEdit = {
            name: validate.isEmpty(client.name) ? null : client.name,
            password: validate.isEmpty(client.password) ? null : client.password,
            parents: validate.isEmpty(client.parents) ? null : client.parents,
            webhookURLs: validate.isEmpty(client.webhookURLs) ? null : client.webhookURLs,
        }

        var hasError = true;
        EditClient(client.id, dataEdit)
            .then(response => {
                if (response.status === 204) {
                    swalWithBootstrapButtons.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Cliente editado com sucesso!',
                    })
                    hasError = false;
                }
                else if (response.data && response.data.cause[0].indexOf("WebhookUrl inválida,") !== -1) {
                    swalWithBootstrapButtons.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Falha ao editar cliente. Url de callback inválida.',
                    });
                    hasError = false;
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setRequestData(!requestData);
                handleClose();
                if (hasError) {
                    swalWithBootstrapButtons.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Falha ao editar cliente.',
                    })
                }
            });
    }

    const removeElementsEmpty = (array) => {
        var newArray = array.filter(element => !validate.isEmpty(element))

        return newArray;
    }

    const formatData = () => {
        var onlyEmailClient = formState?.parents?.map(parent => parent?.email);
        onlyEmailClient = removeElementsEmpty(onlyEmailClient)
        var urlCallbackWithoutEmpty = removeElementsEmpty(formState.webhookURLs);

        var newFormData = {
            ...formState,
            parents: onlyEmailClient,
            webhookURLs: urlCallbackWithoutEmpty
        }

        if (formState.clientType.indexOf("READ_ONLY") === -1) {
            newFormData = {
                ...newFormData,
                parents: null,
            }
        }

        if (formState.clientType !== "CLIENT") {
            newFormData = {
                ...newFormData,
                webhookURLs: null,
            }
        }

        if (isEdit) {
            handleEditClient(newFormData);
        }
        else {
            handleSave(newFormData)
        }
    }

    const handleSave = (data) => {
        let errors = validate(data, schema, { fullMessages: false });

        if (data.clientType === "READ_ONLY" && validate.isEmpty(data.parents)) {
            errors = { ...errors, parents: ["Por favor, informe um cliente para ser associado."] }
        }

        if (!errors) {
            setOpenModal(false);
            var hasError = true;

            AddClient(data)
                .then(response => {
                    if (response.status === 200) {
                        hasError = false;
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    setRequestData(!requestData);
                    if (hasError) {
                        swalWithBootstrapButtons.fire({
                            icon: 'error',
                            title: 'Erro',
                            text: 'Falha ao cadastrar cliente.',
                        })
                    }
                    else {
                        swalWithBootstrapButtons.fire({
                            icon: 'success',
                            title: 'Sucesso',
                            text: 'Cliente cadastrado com sucesso!',
                        })
                    }
                });

            setFormState(initialValues)
        }
        else {
            setValidator(errors)
        }
    };

    const renderGroup = (params) => [
        <FormControlLabel
            key="group-autocomplete-parents-client"
            control={
                <Checkbox
                    name="clientElementCheckAll"
                    color="primary"
                    checked={formState.parents.length === clients.length}
                />
            }
            label={params.group}
            className={clsx(classes.allClientsCheckbox, {

            })}
            onChange={(e, check) => { check ? handleChange("parents", clients) : handleChange("parents", []) }}
        />,
        params.children,
    ];

    const addInputUrl = () => {
        if (formState.webhookURLs.length <= 3) {
            if (formState.webhookURLs.length === 0) {
                setFormState(formState => ({
                    ...formState,
                    webhookURLs: ["", ""]
                }));
            }
            else
                setFormState(formState => ({
                    ...formState,
                    webhookURLs: [...formState.webhookURLs, ""]
                }));
        }
    }

    const handleDeleteInputUrl = index => {
        let arrayUrlCallback = Array.from(formState.webhookURLs);
        arrayUrlCallback.splice(index, 1);

        setFormState(formState => ({
            ...formState,
            webhookURLs: arrayUrlCallback
        }));
    }

    const TextFieldUrlCallback = (value, index) => (
        <TextField
            key={`url-${index}`}
            label={'Url de callback'}
            multiline
            name={'webhookURLs'}
            className={classes.inputText}
            onChange={event => handleChangeUrlCallback(event.target.value, index)}
            onFocus={e => { e.target.selectionStart = e.target.value.length }}
            value={formState && (value || '')}
            fullWidth
            autoFocus
            InputProps={{
                endAdornment: index !== 0 && <InputAdornment position="end"><IconButton onClick={() => handleDeleteInputUrl(index)}><CloseIcon className={classes.iconDeleteInput} /></IconButton></InputAdornment>,
            }}
        />
    )

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                onClose={handleClose}
                aria-labelledby="Formulário Cliente"
                open={openModal}
                classes={{ paper: classes.sizeMobile }}
            >
                <MuiDialogTitle id="dialog-title-client" onClose={handleClose}>
                    {!isEdit ? "Cadastrar Cliente" : "Editar cliente"}
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    <form noValidate autoComplete="off">
                        <TextField
                            id="input-name-client"
                            label={'Nome'}
                            name={'name'}
                            className={classes.inputText}
                            onChange={event => handleChange(event.target.name, event.target.value)}
                            value={formState && (formState['name'] || '')}
                            error={validator && Object.keys(validator).length > 0 && validator['name'] && validator['name'].length > 0}
                            helperText={validator['name']}
                            fullWidth
                        />
                        {!isEdit && <TextField
                            id="input-email-client"
                            label={'E-mail'}
                            name={'email'}
                            className={classes.inputText}
                            onChange={event => handleChange(event.target.name, event.target.value)}
                            value={formState && (formState['email'] || '')}
                            error={validator && Object.keys(validator).length > 0 && validator['email'] && validator['email'].length > 0}
                            helperText={validator['email']}
                            fullWidth
                        />}
                        <TextField
                            id="input-password-client"
                            label={isEdit ? 'Nova senha' : 'Senha'}
                            name={'password'}
                            className={classes.inputText}
                            onChange={event => handleChange(event.target.name, event.target.value)}
                            value={formState && (formState['password'] || '')}
                            error={validator && Object.keys(validator).length > 0 && validator['password'] && validator['password'].length > 0}
                            helperText={validator['password']}
                            fullWidth
                        />
                        {!isEdit && <FormControl fullWidth className={classes.inputText}>
                            <TextField
                                id="input-type-user-client"
                                label={' Tipo de usuário'}
                                value={formState.clientType}
                                onChange={e => handleChange("clientType", e.target.value)}
                                inputProps={{
                                    name: 'clientType',
                                    id: 'statusInputFilterCallback',
                                }}
                                SelectProps={{
                                    displayEmpty: true,
                                }}
                                select
                                error={validator && Object.keys(validator).length > 0 && validator['clientType'] && validator['clientType'].length > 0}
                                helperText={validator['clientType']}
                            >
                                <MenuItem value="CLIENT">Cliente</MenuItem>
                                <MenuItem value="READ_ONLY">Visualizador</MenuItem>
                            </TextField>
                        </FormControl>}
                        {formState.clientType.indexOf("READ_ONLY") !== -1 && <FormControl fullWidth className={classes.inputText}>
                            <Autocomplete
                                multiple
                                id="input-parents-client"
                                key="input-parents-client-key"
                                options={clients}
                                label="Associar cliente"
                                limitTags={2}
                                disableCloseOnSelect
                                value={formState.parents}
                                getOptionLabel={(option) => option?.name}
                                className={classes.autocompleteInput}
                                classes={{ inputRoot: classes.autocompleteInput }}
                                groupBy={(option) => "Todos os clientes"}
                                renderGroup={renderGroup}
                                onChange={(event, newValue) => { changeListParents(newValue) }}
                                renderTags={(tagValue, getTagProps) => {
                                    return formState.parents.map((option, index) => {
                                        if (option !== undefined)
                                            return (
                                                <Chip {...getTagProps({ index })} key={`${option?.name}-${index}`} label={option?.name} onDelete={() => handleDeleteTag(index)} />
                                            )
                                    });
                                }}
                                renderOption={(option) => (
                                    <FormControlLabel
                                        key={option?.email}
                                        control={
                                            <Checkbox
                                                name="parentElement"
                                                color="primary"
                                                checked={hasObject(formState.parents, option)}
                                                key={`checkbox-${option?.email}`}
                                            />
                                        }
                                        label={option?.name}
                                        className={clsx({
                                            [classes.itemAutocomplete]: option?.email !== 0,
                                        })}
                                    />
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Associar cliente" error={validator && Object.keys(validator).length > 0 && validator['parents'] && validator['parents'].length > 0} helperText={validator['parents']} />
                                )}
                            />
                        </FormControl>}
                        {formState.clientType === "CLIENT" && <>
                            {validate.isArray(formState.webhookURLs) && formState.webhookURLs.length > 0? formState.webhookURLs.map((url, index) => {
                                return TextFieldUrlCallback(url, index)
                            }) : TextFieldUrlCallback(formState.webhookURLs || "", 0)}
                            { formState.webhookURLs.length < 3 && <Fab
                                id="add-input-url"
                                variant="extended"
                                size="small"
                                color="primary"
                                aria-label="adicionar nova url de callback"
                                className={classes.addUrl}
                                onClick={() => addInputUrl()}
                            >
                                <AddIcon className={classes.extendedIcon} />
                                Url de callback
                            </Fab>}
                        </>}
                        {/* {formState.clientType === "CLIENT" && <TextField
                            label={'Url de callback'}
                            multiline
                            name={'urlCallback'}
                            className={classes.inputText}
                            onChange={event => handleChange("urlCallback", event.target.value)}
                            value={formState && (formState['urlCallback'] || '')}
                            fullWidth
                        />} */}
                    </form>
                </MuiDialogContent>
                <MuiDialogActions>
                    <Button id="cancel-dialog-client" variant="text" onClick={handleClose} className={classes.buttonCancel}>
                        Cancelar
                    </Button>
                    <Button id="save-form-client" variant="contained" color="primary" className={classes.buttons} onClick={() => formatData()}>
                        Salvar
                    </Button>
                </MuiDialogActions>
            </Dialog>
        </div>
    );
}