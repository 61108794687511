import React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Grid, List, ListItem, ListItemText, ListItemIcon, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import clsx from 'clsx';
import RestoreIcon from '@material-ui/icons/Restore';
import BootstrapTooltip from '../BootstrapTooltip';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import validate from 'validate.js';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    listText: {
        color: '#4A4A4A',
        [theme.breakpoints.up('xs')]: {
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(0),
        }
    },
    cardBody: {
        padding: theme.spacing(3, 6),
        '&:hover': {
            backgroundColor: '#E0E0E0'
        },
        [theme.breakpoints.only('sm')]: {
            padding: theme.spacing(3),
        },
    },
    cardBodyEmpty: {
        padding: theme.spacing(3, 6),
        textAlign: 'center',
        cursor: 'auto'
    },
    itemText: {
        color: "#4A4A4A",
        textAlign: 'center'
    },
    itemIcon: {
        textAlign: 'center'
    },
    boxAvatar: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center'
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
    colorWarning: {
        color: "#ff9800"
    },
    textTooltip: {
        color: '#FFFFFF',
        lineHeight: '130%',
        marginBottom: theme.spacing(1)
    },
    boxTooltip: {
        padding: theme.spacing(1)
    },
    iconTopic: {
        fontSize: '8px'
    },
    listIcon: {
        justifyContent: 'center',
        minWidth: '22px',
        color: '#FFFFFF',
    },
    listItem: {
        paddingBottom: 0,
        color: '#FFFFFF',
        paddingLeft: 0,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 0
        }
    },
    listItemText: {
        color: '#FFFFFF',
    },
    list: {
        padding: 0,
        color: '#FFFFFF',
    },
    textTooltipSecondary: {
        color: '#FFFFFF',
        lineHeight: '130%',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
}));

const TableDesktop = ({ data, columns }) => {

    const classes = useStyles();
    var URLs = ["https://upnote-prod-api.azurewebsites.net/api/UpnoteBiomtech/AcessoEquipamento", "https://upnote-prod-api.azurewebsites.net/api/UpnoteBiomtech/AcessoEquipamento"]

    const bodyIncompleteSend = (index, url, title, subtitle, subtitle2, url2) => (
        <Box key={index} className={classes.boxTooltip}>
            <Typography variant="h5" className={classes.textTooltip}>{title}</Typography>
            <Typography variant="body2" className={classes.textTooltip}>{subtitle}</Typography>
            <List className={classes.list}>
                {url.length > 0 && url.map((parent, index) => (
                    <ListItem key={`${index}-${parent}`} className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FiberManualRecordIcon className={classes.iconTopic} />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography variant="body2" className={classes.listItemText}>{parent}</Typography>}
                        />
                    </ListItem>
                ))}
            </List>
            {
                !validate.isEmpty(subtitle2) &&
                <>
                    <Typography variant="body2" className={classes.textTooltipSecondary}>{subtitle2}</Typography>
                    <List className={classes.list}>
                        {url2.length > 0 && url2.map((parent, index) => (
                            <ListItem key={`${index}-${parent}-incomplete`} className={classes.listItem}>
                                <ListItemIcon className={classes.listIcon}>
                                    <FiberManualRecordIcon className={classes.iconTopic} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body2" className={classes.listItemText}>{parent}</Typography>}
                                />
                            </ListItem>
                        ))}
                    </List>
                </>
            }
        </Box>
    )

    const ComponentStatus = (value, index, urlSend, urlNotSend) => (
        <Typography className={classes.itemText}>
            {
                {
                    'send': <BootstrapTooltip
                        interactive
                        title={bodyIncompleteSend(index, urlSend, "Enviado com sucesso", "Reconhecimento enviado para as seguintes Urls:")}
                    >
                        <CheckCircleOutlineIcon color="primary" />
                    </BootstrapTooltip>,
                    'incomplete': <BootstrapTooltip
                        interactive
                        title={bodyIncompleteSend(index, urlNotSend, "Envio incompleto", ["Reconhecimento ", <b>não enviado </b>, "para as seguintes Urls:"], ["Reconhecimento ", <b>enviado</b>, " para as seguintes Urls:"], urlSend)}
                    >
                        <ErrorOutlineIcon className={classes.colorWarning} />
                    </BootstrapTooltip>,
                    'notSend':
                        <BootstrapTooltip
                            interactive
                            title={bodyIncompleteSend(index, urlNotSend, "Falha no envio", "Reconhecimento não enviado para as seguintes Urls:", "", [])}
                        >
                            <HighlightOffIcon color="error" />
                        </BootstrapTooltip>,
                    'withoutUrl':
                        <Typography className={classes.itemText}>Sem Url cadastrada</Typography>
                }[value]
            }
        </Typography>
    )

    const ComponentContingency = (value, index, urlRealTime, urlContingency) => (
        <>
            {{
                'realTime':
                    <BootstrapTooltip
                        interactive
                        key={index + "-" + value}
                        title={bodyIncompleteSend(index, urlRealTime, "Tempo real", "Reconhecimento em tempo real para as seguintes Urls:", "", [])}
                    >
                        <Typography className={classes.itemText}>Tempo real</Typography>
                    </BootstrapTooltip>,
                'incomplete': <BootstrapTooltip
                    interactive
                    key={index + "-" + value}
                    title={bodyIncompleteSend(index, urlContingency, "Contigência parcial", ["Reconhecimento em ", <b>contigência</b>, " para as seguintes Urls:"], ["Reconhecimento em ", <b>tempo real</b>, " para as seguintes Urls:"], urlRealTime)}
                >
                    <Typography className={classes.itemText}>Contigência parcial</Typography>
                </BootstrapTooltip>,
                'contingency':
                    <BootstrapTooltip
                        interactive
                        key={index + "-" + value}
                        title={bodyIncompleteSend(index, urlContingency, "Contigência", "Reconhecimento em contigência para as seguintes Urls:", "", [])}
                    >
                        <Typography className={classes.itemText}>Contigência</Typography>
                    </BootstrapTooltip>,
                'withoutUrl':
                    <Typography className={classes.itemText}>Sem Url cadastrada</Typography>
            }[value]}
        </>
    )

    const dividerListUrlCallback = (url, indexData) => {
        var urlContingency = [];
        var iterateUrlContingency = Object.entries(url).filter(x => { 
            if(x[1] === true){ 
                urlContingency.push(x[0])
            } 
        });
        var urlRealTime = [];
        var iterateUrlRealTime = Object.entries(url).filter(x => { if(x[1] === false){ 
            urlRealTime.push(x[0])
            return x[0]} });
        var typeMessage = Object.entries(url).length === 0?'withoutUrl': urlContingency.length ===  Object.entries(url).length ? 'contingency' : urlContingency.length === 0 ? "realTime" : "incomplete";
        
        return ComponentContingency(typeMessage, indexData, urlRealTime, urlContingency);
    }

    const dividerListUrlCallbackStatus = (url, indexData) => {
        var urlSend = [];
        var iterateUrlSend = Object.entries(url).filter(x => { 
            if(x[1] === true){ 
                urlSend.push(x[0])
            } 
        });
        var urlNotSend = [];
        var iterateUrlNotSend = Object.entries(url).filter(x => { 
            if(x[1] === false){ 
                urlNotSend.push(x[0])
            } 
        });
        var typeMessage = Object.entries(url).length === 0?'withoutUrl':urlSend.length === Object.entries(url).length ? 'send' : urlSend.length === 0 ? "notSend" : "incomplete"

        return ComponentStatus(typeMessage, indexData, urlSend, urlNotSend);
    }

    return (
        <>
            {data.length > 0 ?
                data.map((device, indexData) => (
                    <Card id="cardList" key={indexData} className={classes.root}>
                        <CardContent className={classes.cardBody}>
                            <Grid container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                {
                                    columns.map((column, index) => (
                                        <Grid item lg={column.lg} sm={column.sm} key={index}
                                            className={clsx({
                                                [classes.itemIcon]: column.name === "contingent",
                                            })}
                                        >
                                            {
                                                {
                                                    'personId': <Box className={classes.boxAvatar}>
                                                        <Avatar alt="Foto usuário" src={device?.deviceCallback?.photoUrl} className={classes.avatar} />
                                                        <Typography className={classes.itemText}> {device?.deviceCallback?.[column.name]}</Typography>
                                                    </Box>,
                                                  //  'listUrlsCallback': dividerListUrlCallback(device?.deviceCallbackMetadata?.listUrlsCallback),
                                                    'contingency': dividerListUrlCallback(device?.deviceCallbackMetadata?.webhookUrlContingencyStatus, indexData),
                                                    'callbackAsReceivedByTheClient': dividerListUrlCallbackStatus(device?.deviceCallbackMetadata?.webhookUrlStatus, indexData),
                                                }[column.name] ||
                                                <Typography className={classes.itemText}>
                                                    {column.name === "time" ? moment(new Date(device?.deviceCallback?.[column.name])).format('DD/MM/YYYY - HH:mm') : device?.deviceCallback?.[column.name]}
                                                </Typography>
                                            }
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                )) :
                <Card id="cardList" className={classes.root}>
                    <CardContent className={classes.cardBodyEmpty}>
                        <Typography className={classes.listText} >
                            Lista Vazia
                        </Typography>
                    </CardContent>
                </Card>
            }
        </>
    );
}
export default TableDesktop