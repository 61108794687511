import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Card,
    CardContent,
    Container,
    Fab,
    Grid,
    Hidden,
    IconButton,
    InputAdornment,
    TablePagination,
    TextField,
    Typography,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import RefreshIcon from '@material-ui/icons/Refresh';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { TableDesktop, TableMobile, Form, DeviceDetails } from './components';
import { ModalFilter, LoadingComponent, InputListClients } from '../../components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import clsx from 'clsx';
import { GetAllDevices, ListUser, RemoveDevice } from '../../API/endpoints';
import Swal from 'sweetalert2'
import style from './AlertStyle.css';
import { getUserInfo, saveUserInfo, isUserView } from '../../context/AppContext';
import { RealTimeDeviceStatusContext } from '../../context/RealTimeContext';
import { stableSort } from '../../common/ordination';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(0),
        paddingLeft: 0,
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(0),
            padding: theme.spacing(2),
        }
    },
    tableHeader: {
        marginBottom: theme.spacing(1),
        backgroundColor: '#76AB00',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
        },
    },
    textHeader: {
        color: 'white',
        alignItems: 'center',
        cursor: 'pointer',
        textAlign: 'center'
    },
    textHeaderAction: {
        color: 'white',
        alignItems: 'center',
        cursor: 'default',
        textAlign: 'center'
    },
    view: {
        display: 'inline',
        marginLeft: theme.spacing(1),
        fontSize: 'medium'
    },
    cardHeader: {
        padding: theme.spacing(3, 6),
        backgroundColor: '#76AB00 !important',
        [theme.breakpoints.only('sm')]: {
            padding: theme.spacing(3),
        },
    },
    buttonAdd: {
        bottom: '2%',
        position: 'fixed',
        float: 'rigth',
        right: '2%',
        backgroundColor: '#76AB00',
        color: 'white',
        '&:hover': {
            backgroundColor: '#BBD400',
            color: '#FFFFFF'
        }
    },
    title: {
        margin: theme.spacing(3, 3, 2, 3),
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0),
        }
    },
    hide: {
        display: 'none',
    },
    show: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 1,
        transform: 'none',
        transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    gridSearch: {
        display: 'flex',
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(0),
        }
    },
    textField: {
        width: '30vw',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    containerPagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: '90px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
            paddingBottom: theme.spacing(4)
        }
    },
    paginationToolbar: {
        position: 'initial',
        right: 0,
        flexWrap: 'wrap',
        textAlign: 'center',
        justifyContent: 'center',
        padding: 0
    },
    groupActionsHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

export default function TablePerson({ history }) {
    //STYLE
    const classes = useStyles();

    //GLOBAL VARIABLES
    const { statusListDevice } = useContext(RealTimeDeviceStatusContext);

    //STATE
    const [listDevices, setListDevices] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [openSearch, setOpenSearch] = useState(false);
    const [filter, setFilter] = useState("");
    const [dataFilter, setDataFilter] = useState(listDevices);
    const [openModal, setOpenModal] = useState(false);
    const [requestData, setRequestData] = useState(false);
    const [openModalFilter, setOpenModalFilter] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clientSelected, setClientSelected] = useState(getUserInfo() || "");
    const [deviceDetailsModal, setDeviceDetailsModal] = useState({ open: false, device: {} });
    const [selectedDevice, setSelectedDevice] = useState({ device_name: "", device_id: "" });
    const [modeForm, setModeForm] = useState("add");

    //VARIABLES
    const inputSearchRef = useRef();
    const isMobile = useMediaQuery(theme => theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme => theme.breakpoints.only('sm'));
    let filterData = stableSort(dataFilter, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const columnsFilterMobile = [{ name: 'status', label: 'Status' }, { name: 'deviceName', label: 'Nome' }, { name: 'deviceKey', label: 'Número Serial' }]

    const openModalForm = () => {
        setOpenModal(true);
        setModeForm("add");
    };

    const closeModalFilter = () => {
        setOpenModalFilter(false)
    }

    function handleSearch(arrayData) {
        if (filter !== "") {
            var newArray = arrayData.filter((value) => {
                var name = value?.deviceName?.toLowerCase();
                var deviceId = value?.deviceKey?.toLowerCase();
                var compound = name?.split(' ');
                if (compound?.length > 1) {
                    var arrayCompound = compound.map((name, index) => {
                        var newName = compound.slice(index, compound.length).join(' ');
                        return newName.indexOf(filter.toLowerCase());
                    })
                }
                return name?.indexOf(filter?.toLowerCase()) === 0 || deviceId?.indexOf(filter?.toLowerCase()) === 0 || (arrayCompound && arrayCompound?.indexOf(0) !== -1)
            });
            setDataFilter(newArray);
            setPage(0)
        }
        else {
            setDataFilter(listDevices)
        }
    }

    //FILTER
    useEffect(() => {
        handleSearch(listDevices);
    }, [filter])

    useEffect(() => {
        inputSearchRef.current.focus();
    }, [openSearch])

    useEffect(() => {
        // Se é uma atualização vazia, nada a fazer
        if (!statusListDevice.length) {
            return;
        }

        filterByDeviceId(listDevices, statusListDevice)
    }, [statusListDevice])

    const filterByDeviceId = (objDevice, objStatus) => {
        let res = [];
        let indexData = [];

        res = objDevice.filter((el, index) => {
            return !objStatus.find(element => {
                if (element.deviceKey === el.deviceKey) {
                    indexData.push({ index: index, data: element })
                }
                return element.deviceKey === el.deviceKey;
            });
        });

        const itensCopy = Array.from(listDevices);
        indexData.map(element => {
            itensCopy.splice(element.index, 1, { ...element.data });
        })
        setListDevices(itensCopy);
        handleSearch(itensCopy);

        return res;
    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            cancelButton: 'swal2-cancel'
        },
    })

    function getDevices() {
        setLoading(true);
        var hasError = true;
        GetAllDevices()
            .then(response => {
                if (response.status === 200) {
                    setDataFilter(response.data.devices);
                    setListDevices(response.data.devices);
                    hasError = false
                }

                if (response.status === 401) {
                    swalWithBootstrapButtons.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Sessão expirada ',
                    }).then((result) => {
                        history.push("/login")
                    })
                    hasError = false
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
                if (hasError) {
                    swalWithBootstrapButtons.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Falha ao listar dispositivos.',
                    })
                }
            });
    }


    function deleteDevice(event, deviceId, deviceName) {
        event.stopPropagation();
        var hasError = true
        swalWithBootstrapButtons.fire({
            title: 'Alerta',
            html: `Tem certeza que deseja remover o dispositivo <strong> ${deviceName} - ${deviceId} </strong>? `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#76AB00',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                RemoveDevice({ device_id: deviceId })
                    .then(response => {
                        if (response.status === 200) {
                            hasError = false;
                            getDevices();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        if (hasError) {
                            swalWithBootstrapButtons.fire(
                                {
                                    title: 'Erro!',
                                    html: 'Falha ao remover dispositivo.',
                                    icon: 'error',
                                    timer: 2000,
                                }
                            )
                        }
                        else {
                            swalWithBootstrapButtons.fire(
                                {
                                    title: 'Deletado!',
                                    html: 'Dispositivo removido com sucesso.',
                                    icon: 'success',
                                    timer: 2000,
                                }
                            )
                        }
                    });
            }
        })
    }

    useEffect(() => {
        getDevices();
    }, [requestData])

    //PAGINATION
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //ORDINATION
    function descendingComparator(a, b, orderBy) {
        if (orderBy === 'personCount' || orderBy === 'faceCount') {
            if (b?.lastDeviceInfo[orderBy] < a?.lastDeviceInfo[orderBy]) {
                return -1;
            }
            if (b?.lastDeviceInfo[orderBy] > a?.lastDeviceInfo[orderBy]) {
                return 1;
            }
        }
        else {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        if (order === 'desc') {
            return (a, b) => descendingComparator(a, b, orderBy);
        }
        if (order === 'asc') {
            return (a, b) => -descendingComparator(a, b, orderBy);
        }
        else
            return (a, b) => a
    }

    const handleRequestSort = (property) => {
        const isEmpty = orderBy === property && order === 'desc';

        if (!isEmpty) {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }
        else {
            setOrder('');
            filterData = listDevices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        }
    };

    const changeClientSelect = (value) => {
        setClientSelected(value);
        saveUserInfo(value);
        getDevices();
        setPage(0);
    }

    const openEditDevice = (event, device) => {
        event.stopPropagation();
        setOpenModal(true);
        setSelectedDevice({ device_name: device.deviceName, device_id: device.deviceKey });
        setModeForm("edit");
    }

    var columns = [
        {
            title: "Status",
            name: "status",
            lg: 2,
            sm: 2,
            xs: 10
        },
        {
            title: "Nome",
            name: "deviceName",
            lg: !isUserView() ? 2 : 3,
            sm: !isUserView() ? 2 : 3,
            xs: 10
        },
        {
            title: isSm ? "Nº Serial" : "Número Serial",
            name: "deviceKey",
            lg: 3,
            sm: 3,
            xs: 10
        },
        {
            title: "Nº Pessoas",
            name: "personCount",
            lg: 2,
            sm: 2,
            xs: 10
        },
        {
            title: "Nº Faces",
            name: "faceCount",
            lg: 2,
            sm: 2,
            xs: 10
        },
    ]

    return (
        <Container className={classes.container}>
            <Grid container>
                <Grid item lg={12} xs={12}>
                    <Typography variant="h2" component="h2" className={classes.title}>
                        Dispositivos
                    </Typography>
                </Grid>
                <Grid item lg={4} md={4} xs={12} className={classes.gridSearch}>
                    <InputListClients clientSelected={clientSelected} changeClientSelect={changeClientSelect} />
                </Grid>
                <Grid item lg={3} md={3} xs={8} className={classes.gridSearch}>
                    <Box className={
                        clsx({
                            [classes.hide]: !openSearch,
                            [classes.show]: openSearch
                        })
                    }>
                        <TextField
                            inputRef={inputSearchRef}
                            className={classes.textField}
                            placeholder="Pesquisar"
                            value={filter}
                            autoFocus={true}
                            onChange={e => setFilter(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <IconButton onClick={() => { setOpenSearch(false); setFilter("") }}>
                                        <CloseIcon />
                                    </IconButton>
                                )
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={4} md={4} lg={4} >
                    <Grid container className={classes.groupActionsHeader}>
                        <Hidden smUp>
                            <Grid item lg={1} xs={4} className={classes.gridSearch} >
                                <IconButton onClick={() => setOpenModalFilter(true)}>
                                    <FilterListIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>
                        <Grid item lg={1} xs={4} className={classes.gridSearch} >
                            <IconButton onClick={() => setOpenSearch(!openSearch)}>
                                <SearchIcon />
                            </IconButton>
                        </Grid>
                        <Grid item lg={1} xs={4} className={classes.gridSearch} >
                            <IconButton onClick={() => getDevices()}>
                                <RefreshIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Card className={classes.tableHeader} >
                <CardContent className={classes.cardHeader}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        className={classes.marginDevice}
                    >
                        {columns.map((column, index) => (
                            <Grid key={index} item lg={column.lg} sm={column.sm} xs={column.xs}>
                                <Typography component="h2" variant="h5" color="primary" className={classes.textHeader} onClick={e => handleRequestSort(column.name)}>
                                    {column.title}
                                    <ImportExportIcon className={order === '' || orderBy !== column.name ? classes.view : classes.hide} />
                                    <ArrowDownwardIcon className={order === 'desc' && orderBy === column.name ? classes.view : classes.hide} />
                                    <ArrowUpwardIcon className={order === 'asc' && orderBy === column.name ? classes.view : classes.hide} />
                                </Typography >
                            </Grid>
                        ))}
                        {!isUserView() && <Grid key={5} item lg={1} sm={1} xs={10}>
                            <Typography component="h2" variant="h5" color="primary" className={classes.textHeaderAction} >
                                Ações
                            </Typography >
                        </Grid>}
                    </Grid>
                </CardContent>
            </Card>
            {isMobile ?
                <TableMobile devices={filterData} history={history} openEditDevice={openEditDevice} setDeviceDetailsModal={setDeviceDetailsModal} deleteDevice={deleteDevice} /*requestUsers={requestUsers}*/ />
                :
                <TableDesktop devices={filterData} history={history} openEditDevice={openEditDevice} setDeviceDetailsModal={setDeviceDetailsModal} deleteDevice={deleteDevice} /*requestUsers={requestUsers}*/ />
            }
            <Container className={classes.containerPagination}>
                <TablePagination
                    component="div"
                    classes={{ toolbar: classes.paginationToolbar }}
                    count={dataFilter.length}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : ` até ${to}`
                        }`
                    }
                    labelRowsPerPage="Total por página:"
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 15, 30]}
                />
            </Container>
            {!isUserView() && <Fab
                aria-label="adicionar dispositivo"
                className={classes.buttonAdd}
                onClick={() => openModalForm()}
                id="open-dialog-add-device"
            >
                <AddIcon />
            </Fab>}
            <Form data={listDevices} setData={setListDevices} formEdit={selectedDevice} modeForm={modeForm} setModeForm={setModeForm} setFormEdit={setSelectedDevice} openModal={openModal} setOpenModal={setOpenModal} setRequestData={setRequestData} requestData={requestData} />
            <ModalFilter openModalFilter={openModalFilter} closeModalFilter={closeModalFilter} handleRequestSort={handleRequestSort} setOrder={setOrder} columns={columnsFilterMobile} />
            <DeviceDetails deviceDetailsModal={deviceDetailsModal} setDeviceDetailsModal={setDeviceDetailsModal} history={history} />
            <LoadingComponent open={loading} handleClose={() => setLoading(false)} />
        </Container>
    );
}
