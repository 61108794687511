import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import validate from 'validate.js';
import { isCNPJ, formatToCNPJ } from 'brazilian-values';
import TextField from '@material-ui/core/TextField';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useHistory } from "react-router-dom";
import AppContext from '../../context/AppContext';
import Swal from 'sweetalert2/src/sweetalert2.js'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  buttons: {
    color: 'white',
    backgroundColor: 'transparent',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    border: 'solid 2px transparent',
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #b63131, #a74e4e)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    '&:hover': {
      boxShadow: '2px 1000px 1px #272727 inset',
      color: '#bd8f8f'
    }
  },
  buttonCancel: {
    color: '#FFFFFF',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxShadow: '0 0 4px 0 rgba(157, 96, 212, 0.5)',
    border: 'solid 2px transparent',
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #ffffff, #ffffff)',
    backgroundColor: 'white',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',

    '&:hover': {
      boxShadow: '2px 1000px 1px #272727 inset',
      color: 'white'
    }
  },
  inputText: {
    marginBottom: theme.spacing(3)
  }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalFormDefault = props => {
  let history = useHistory();
  const classes = useStyles();
  const { modalForm, setModalForm, companyNameCreate, setCompanyNameCreate } = useContext(AppContext);
  const { module_form, checked, setElements, elements, textButtonAdd } = props
  const { form_fields, title, validate_js_constraints } = module_form
  const [selectFieldRequest, setSelectFieldRequest] = useState({ has_requested: false, lists: {} });
  const [validator, setValidator] = useState({
    isValid: true,
    touched: {},
    errors: {},
    element_focus: null
  });
  let title_modal = "Cadastrar " + title
  if (modalForm.mode === 'edit')
    title_modal = "Editar " + title
  //START SELECT FIELD REQUEST
  const selectFieldListRequest = async () => {
    const list_select_field_request = { code: 3000, result: [{ id: 0, name: 'Wyogo' }, { id: 1, name: 'Xapoeor' }, { id: 0, name: 'Cufe' }, { id: 1, name: 'Kaohoe' }] }
    let list_select_fields = {};

    if (list_select_field_request.code === 3000) {
      if (module_form.select_fields.fields.length > 1) {
        module_form.select_fields.fields.forEach(field_name => {
          list_select_fields[field_name] = list_select_field_request.result[field_name];
        });
      }
      else
        list_select_fields[module_form.select_fields.fields[0]] = list_select_field_request.result;
    }
    setSelectFieldRequest({ has_requested: true, lists: list_select_fields });
  };

  if (module_form.select_fields && !selectFieldRequest.has_requested)
    selectFieldListRequest();

  const handleClose = () => {
    setModalForm({ open: false });
    setValidator({
      isValid: true,
      touched: {},
      errors: {},
      element_focus: null
    })
    setFormState([])
  };

  const [formState, setFormState] = React.useState(checked);

  useEffect(() => {
    setFormState(formState => ({
      ...checked
    }));
  }, [checked]);

  function handleClick(event, mode) {
    let errors = validate(formState, validate_js_constraints, { fullMessages: false });
    if (errors == null && validator.errors != null && validator.errors['companyKey'] != null)
      errors = validator.errors
    if (errors != null) {
      setValidator(validator => ({
        ...validator,
        isValid: false,
        errors: errors
      }));
    }
    else {
      handleSave(event, mode)
      if (validator.isValid) {
        setModalForm({ open: true, mode: 'add' });
        if (title === 'Usuário')
          setFormState([])
        if (title === 'Empresa') {
          setCompanyNameCreate(formState['name'])
          history.push("/user");
          setFormState([])
        }
      }
    }
  }

  const handleSave = async (event, mode) => {
    event.preventDefault();
    let errors = validate(formState, validate_js_constraints, { fullMessages: false });
    if (errors == null && validator.errors != null && validator.errors['companyKey'] != null)
      errors = validator.errors
    if (errors != null) {
      if (errors != null && validator.errors != null && validator.errors['companyKey'] != null)
        errors['companyKey'] = validator.errors['companyKey']
      setValidator(validator => ({
        ...validator,
        isValid: false,
        errors: errors
      }));
    }

    else {
      setValidator(validator => ({
        ...validator,
        isValid: true,
        errors: errors || {}
      }));
      //IT IS NECESSARY TO DO stringify AND parse IN ORER TO THE OBJECT new_element DO NOT REFERENCE OTHER OBJECT
      let new_element = JSON.parse(JSON.stringify(checked)) || JSON.parse(JSON.stringify(module_form.object));

      form_fields.map(field => {
        let value = formState[field.name];
        if (!value)
          value = field.default_value;
        if (field.add_to_object)
          new_element[field.name] = value;
        else if (field.add_to_field)
          new_element[field.add_to_field] = value;
      });

      let save_request_response;

      if (checked) {
        const itensCopy = Array.from(elements);
        itensCopy.splice(checked.id, 1, new_element);
        save_request_response = { code: 3000, result: itensCopy }
        handleClose();
        setElements(itensCopy);
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Edição realizada com sucesso!'
        })
      }
      else {
        if (title === 'Usuário' && new_element.company.length === 0)
          new_element['company'] = companyNameCreate
        const itensCopy = Array.from(elements);
        itensCopy.push(new_element)
        itensCopy.splice(itensCopy.length - 1, 1, { ...itensCopy[itensCopy.length - 1], id: (itensCopy[itensCopy.length - 2].id) + 1 })
        save_request_response = { code: 3000, result: itensCopy }
        handleClose()
        setElements(itensCopy);
        setCompanyNameCreate('')
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Cadastro realizado com sucesso!'
        })
        if (mode === 'save')
          setFormState([])
      }
    }
  };
  const handleChange = (name, value) => {
    if (name === 'companyKey') {
      if (!isCNPJ(value)) {
        setValidator(validator => ({
          ...validator,
          isValid: false,
          errors: { ...validator.errors, [name]: ['CNPJ inválido'] }
        }));
      }
      else if (validator.errors['companyKey'] != null) {
        const itensCopyValidator = Array.from(validator.errors);
        itensCopyValidator.map((item, index) => {
          if (item === 'companyKey')
            itensCopyValidator.splice(index, 1);
        })

        setValidator(validator => ({
          ...validator,
          isValid: true,
          errors: itensCopyValidator
        }));
      }
    }
    setFormState(formState => ({
      ...formState,
      [name]: value
    }));
  };
  const CreateSelectField = props => {
    const { formField } = props;

    let selected_option = formState[formField.name];
    
    if (!formState[formField.name]) {
      selected_option = module_form.object[formField.add_to_field] > 0 ? module_form.object[formField.add_to_field] : ''
    }

    return (
      <FormControl
        className={classes.field}
        fullWidth
        error={!validator.isValid}
      >
        <InputLabel id={'label_for_' + formField.name}>{formField.title}</InputLabel>
        <Select
          labelId={'label_for_' + formField.name}
          id={formField.name}
          name={formField.name}
          className={validator.isValid ? classes.inputText : ''}
          value={formState && (formState[formField.name] || companyNameCreate)}
          onChange={event => handleChange(formField.name, event.target.value)}
        >
          {selectFieldRequest.lists.hasOwnProperty(formField.name) && selectFieldRequest.lists[formField.name].map((option) => {
            return <MenuItem key={option.id} value={option.name}>{option.name}</MenuItem>
          })}
        </Select>
        <FormHelperText>{validator.isValid ? null : validator.errors[formField.name]}</FormHelperText>
      </FormControl>
    );
  };
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modalForm.open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title_modal}
        </DialogTitle>
        <DialogContent dividers>
          <form noValidate autoComplete="off">
            {form_fields.map((input, index) => {
              if (input.type === 'text') {
                return (
                  <TextField id="standard-basic"
                    key={index}
                    label={input.title}
                    name={input.name}
                    className={classes.inputText}
                    onChange={event => handleChange(input.name, event.target.value)}
                    value={input.name === "companyKey" ? formatToCNPJ(formState && (formState[input.name] || '')) : formState && (formState[input.name] || '')}
                    error={!validator.isValid && validator.errors[input.name]}
                    helperText={validator.isValid ? null : validator.errors[input.name]}
                    fullWidth
                  />
                )
              }
              else if (input.type === 'select')
                return (<CreateSelectField key={index} formField={input} />)
              else if (input.type === 'specialComponent')
                return (<input.component formState={formState} handleChange={handleChange} />)
            })}
          </form>
        </DialogContent>
        <DialogActions>
          <Fab variant="extended" onClick={handleClose} className={classes.buttonCancel}>
            Cancelar
          </Fab>
          <Fab variant="extended" className={classes.buttons} onClick={event => handleSave(event, 'save')}>
            Salvar
          </Fab>
          {modalForm.mode === 'add' ?
            <Fab
              variant="extended"
              className={classes.buttons}
              onClick={event => handleClick(event, 'save plus')}
            >
              {textButtonAdd}
            </Fab>
            : ''
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default ModalFormDefault