import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    Container,
    Fab,
    Grid,
    TablePagination,
    Typography,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { LoadingComponent, TableDesktop, TableMobile, ComponentExport } from '../../components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Swal from 'sweetalert2/src/sweetalert2.js';
import AppContext, { getToken, getUserInfo } from '../../context/AppContext';
import GetAppIcon from '@material-ui/icons/GetApp';
import useWebSocket from 'react-use-websocket';
import { Header } from './components';
import { getComparatorCallback, stableSort } from '../../common/ordination';
import { ListCallback } from '../../API/endpoints';
import { validate } from 'validate.js';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(0),
        paddingLeft: 0,
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(0),
            padding: theme.spacing(2),
        }
    },
    tableHeader: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
        backgroundColor: '#76AB00',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
        },
    },
    textHeader: {
        color: 'white',
        alignItems: 'center',
        cursor: 'pointer',
        textAlign: 'center'
    },
    view: {
        display: 'inline',
        marginLeft: theme.spacing(1),
        fontSize: 'medium'
    },
    cardHeader: {
        padding: theme.spacing(3, 6),
        backgroundColor: '#76AB00 !important',
        [theme.breakpoints.only('sm')]: {
            padding: theme.spacing(3),
        },
    },
    hide: {
        display: 'none',
    },
    containerPagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: '90px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
            paddingBottom: theme.spacing(4)
        }
    },
    paginationToolbar: {
        position: 'initial',
        right: 0,
        flexWrap: 'wrap',
        textAlign: 'center',
        justifyContent: 'center',
        padding: 0
    },
    buttonDownload: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
    },
    itemIcon: {
        textAlign: 'center'
    },
}));

var initialValues = {
    initialDate: '',
    finalDate: '',
    user: "",
    devices: [],
    status: "",
}

export default function Callback({ history }) {
    //STYLE
    const classes = useStyles();
    //STATE
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [dataFilter, setDataFilter] = useState(data);
    const [loading, setLoading] = useState(false);
    const [openModalDownload, setOpenModalDownload] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);
    const [formState, setFormState] = useState(initialValues);
    const [clientSelected, setClientSelected] = useState(getUserInfo() || "");

    //VARIABLES
    const isMobile = useMediaQuery(theme => theme.breakpoints.only('xs'));
    let filterData = stableSort(dataFilter, getComparatorCallback(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    let filterDataExport = stableSort(dataFilter, getComparatorCallback(order, orderBy));


    const columns = [
        {
            title: "Usuário ID",
            name: 'personId',
            lg: 2,
            sm: 2
        },
        {
            title: "Data",
            name: 'time',
            lg: 3,
            sm: 3
        },
        {
            title: "Dispositivo",
            name: 'deviceKey',
            lg: 2,
            sm: 2
        },

        {
            title: "Padrão de envio",
            name: "contingency",
            lg: 3,
            sm: 3,
        },
        {
            title: "Transmissão",
            name: 'callbackAsReceivedByTheClient',
            lg: 2,
            sm: 2
        },
    ]

    //WEBSOCKET
    //var url = "wss://hml-api-device-control.biomtech.com.br"
    const url = new URL("/websocket/device-callbacks", process.env.REACT_APP_API_BASE_URL);
    url.protocol = url.protocol === "https:" ? "wss:" : "ws:";
    const [urlWs, seturlWs] = useState(url);
    var accessToken = getToken();
    urlWs.searchParams.append("authorization", accessToken);
    urlWs.searchParams.append("clientId", clientSelected);

    const [socketUrl, setSocketUrl] = useState(urlWs.toString())

    useEffect(() => {
        setSocketUrl(urlWs.toString());
    }, [urlWs])

    const { lastJsonMessage, sendMessage, getWebSocket } = useWebSocket(socketUrl, {
        onOpen: (event) => {
            console.log(`Connected WebSocket`);
        },
        onMessage: (response, b) => {
            var arrayData = JSON.parse(response.data);
            console.log(arrayData);
            var callbacksSession = data;
            var newArrayCallbacks = [];

            if (callbacksSession !== null) {
                if (Array.isArray(callbacksSession))
                    callbacksSession.unshift(arrayData.data);
                else {
                    callbacksSession = [callbacksSession];
                    callbacksSession.unshift(arrayData.data)
                }

                if (callbacksSession.length > 60) {
                    let lastItem = callbacksSession.pop();
                }

                newArrayCallbacks = callbacksSession;
            }
            else {
                newArrayCallbacks = [arrayData.data]
            }


            setData(newArrayCallbacks)

            if (JSON.stringify(formState) !== JSON.stringify(initialValues)) {
                setDataUpdate(!dataUpdate)
            }
            else
                setDataFilter(newArrayCallbacks)
        },
        onError: (event) => { console.error(event); },
        onClose: (event) => {
            console.log({ event })
            return false
        },
        shouldReconnect: (closeEvent) => {
            if (accessToken === "" || accessToken === null) {
                return false
            }
            return true
        },
    });


    //PAGINATION
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //ORDINATION
    const handleRequestSort = (property) => {
        const isEmpty = orderBy === property && order === 'desc';

        if (!isEmpty) {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }
        else {
            setOrder('');
            filterData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            filterDataExport = data;
        }
    };

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            cancelButton: 'swal2-cancel'
        },
    })

    function getCallback(filter = formState) {
        setLoading(true);
        var hasError = true;

        let formFilter = [
            {
                name: "startDate",
                value: validate.isEmpty(filter.initialDate) ? "" : moment(filter.initialDate).format("YYYY-MM-DD")
            },
            {
                name: 'endDate',
                value: validate.isEmpty(filter.finalDate) ? "" : moment(filter.finalDate).format("YYYY-MM-DD")
            },
            {
                name: "personId",
                value: filter.user
            },
            {
                name: 'deviceKey',
                value: filter.devices
            },
            {
                name: 'status',
                value: filter.status === "true" ? "SENT" : validate.isEmpty(filter.status) ? "" : "NOT_SENT"
            },
        ]

        const params = new URLSearchParams()

        formFilter.forEach(param => {
            if (!validate.isEmpty(param.value)) {
                if (param.name === 'deviceKey') {
                    filter.devices.forEach(device => {
                        params.append("deviceKey", device.deviceKey)
                    })
                }
                else
                    params.set(param.name, param.value)
            }
        })

        ListCallback(params)
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    setData(response.data.deviceCallbacks)
                    setDataFilter(response.data.deviceCallbacks);
                    hasError = false
                }
                if (response.status === 401) {
                    swalWithBootstrapButtons.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Sessão expirada ',
                    }).then((result) => {
                        history.push("/login")
                    })
                    hasError = false
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
                if (hasError) {
                    swalWithBootstrapButtons.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Falha ao listar callbacks.',
                    })
                }
            });

    }

    useEffect(() => {
        getCallback();
    }, [])

    return (
        <Container className={classes.container}>
            <Header history={history} seturlWs={seturlWs} getWebSocket={getWebSocket} getCallback={getCallback} clientSelected={clientSelected} setClientSelected={setClientSelected} data={data} setData={setData} dataUpdate={dataUpdate} dataFilter={dataFilter} setDataFilter={setDataFilter} setPage={setPage} setLoading={setLoading} formState={formState} setFormState={setFormState} />
            <Card className={classes.tableHeader} >
                <CardContent className={classes.cardHeader}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        className={classes.marginDevice}
                    >
                        {columns.map((column, index) => (<Grid key={column.index} item lg={column.lg} sm={column.sm} xs={10} className={clsx({
                            [classes.itemIcon]: column.name === "contingent",
                        })}>
                            <Typography component="h2" variant="h5" color="primary" className={classes.textHeader} onClick={e => handleRequestSort(column.name)}>
                                {column.title}
                                <ImportExportIcon className={order === '' || orderBy !== column.name ? classes.view : classes.hide} />
                                <ArrowDownwardIcon className={order === 'desc' && orderBy === column.name ? classes.view : classes.hide} />
                                <ArrowUpwardIcon className={order === 'asc' && orderBy === column.name ? classes.view : classes.hide} />
                            </Typography >
                        </Grid>))}
                    </Grid>
                </CardContent>
            </Card>
            {isMobile ?
                <TableMobile data={filterData} history={history} columns={columns} />
                :
                <TableDesktop data={filterData} history={history} columns={columns} />
            }
            <Container className={classes.containerPagination}>
                <TablePagination
                    component="div"
                    classes={{ toolbar: classes.paginationToolbar }}
                    count={dataFilter.length}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : ` até ${to}`
                        }`
                    }
                    labelRowsPerPage="Total por página:"
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 15, 30]}
                />
            </Container>
            {!validate.isEmpty(filterDataExport) && <Fab aria-label="download" color="primary" size="large" onClick={() => setOpenModalDownload(true)} className={classes.buttonDownload}>
                <GetAppIcon />
            </Fab>}
            <LoadingComponent open={loading} handleClose={() => setLoading(false)} />
            <ComponentExport callbackToExport={filterDataExport} closeModalDownload={() => setOpenModalDownload(false)} openModalDownload={openModalDownload} formState={formState} />
        </Container>
    );
}
