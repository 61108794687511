import React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { BootstrapTooltip } from '../../../../components';
import { isUserView, isAdmin } from '../../../../context/AppContext';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        cursor: 'pointer',
    },
    listText: {
        color: '#4A4A4A',
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(0),
        }
    },
    cardBody: {
        padding: theme.spacing(3, 6),
        [theme.breakpoints.only('sm')]: {
            padding: theme.spacing(3),
        },
        '&:hover': {
            backgroundColor: '#E0E0E0'
        },
    },
    cardBodyEmpty: {
        padding: theme.spacing(3, 6),
        textAlign: 'center',
        cursor: 'auto'
    },
    deleteIcon: {
        color: '#db1414'
    },
    editIcon: {
        color: '#4a4a4a'
    },
    gridState: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    }
}));

const TableDesktop = ({ clients, openEditClient, handleDeleteClient, setClientDetailsModal }) => {

    const classes = useStyles();

    return (
        <>
            {clients.length > 0 ?
                clients.map((client, index) => (
                    <Card id="cardList" key={index} className={classes.root}
                        onClick={() => {
                            setClientDetailsModal({open: true, client: client })
                        }}
                    >
                        <CardContent className={classes.cardBody}>
                            <Grid container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item lg={5} sm={3} xs={12} >
                                    <Typography className={classes.listText} >
                                        {client.name}
                                    </Typography >
                                </Grid>
                                <Grid item lg={5} sm={3}>
                                    <Typography className={classes.listText} >
                                        {client.email}
                                    </Typography >
                                </Grid>
                                {isAdmin() && client.clientType !== "ADMIN" ? <Grid item sm={2} md={2} lg={2} className={classes.gridState}>
                                    <BootstrapTooltip title="Editar" >
                                        <IconButton onClick={e => openEditClient(client, e)}>
                                            <EditIcon className={classes.editIcon} />
                                        </IconButton>
                                    </BootstrapTooltip>
                                    <BootstrapTooltip title="Remover" >
                                        <IconButton onClick={e => handleDeleteClient(client, e)}>
                                            <DeleteForeverIcon className={classes.deleteIcon} />
                                        </IconButton>
                                    </BootstrapTooltip>
                                </Grid> :
                                    <Grid item sm={2} md={2} lg={2} className={classes.gridState}>
                                        <LockIcon />
                                    </Grid>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                )) :
                <Card id="cardList" className={classes.root}>
                    <CardContent className={classes.cardBodyEmpty}>
                        <Typography className={classes.listText} >
                            Lista Vazia
                        </Typography>
                    </CardContent>
                </Card>
            }
        </>
    );
}
export default TableDesktop