import axios from 'axios';
import { getToken, isAuthenticated, isAdmin, getUserInfo } from '../context/AppContext';

axios.defaults.validateStatus = function () {
  return true;
};

// busca nas variaveis de ambiente, se não encontrar usa arquivo .env
const BASE_URL  = process.env.REACT_APP_API_BASE_URL;

export const apiLogin = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

export const apiAdm = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

//BEFORE MAKES REQUESTS
apiAdm.interceptors.request.use(async config => {
  if(isAuthenticated()){
      config.headers.Authorization = 'Bearer ' + getToken();
  }

  return config;
});

api.interceptors.request.use(async config => {
  if(isAuthenticated()){
      config.headers.Authorization = 'Bearer ' + getToken();
      config.headers['Client-Email'] =  getUserInfo();
  }

  return config;
});
