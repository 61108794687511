import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Avatar,
    CircularProgress,
    Grid,
    TextField,
    Box,
    IconButton,
    Fab,
    Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import CloseIcon from '@material-ui/icons/Close';
import { BootstrapTooltip, WebcamDialog } from '../../../../components';
import validate from 'validate.js';
import clsx from 'clsx';

const schema = {
    id: {
        presence: { allowEmpty: false, message: 'Por favor, informe o id do usuário' },
    },
    name: {
        presence: { allowEmpty: false, message: 'Por favor, informe o nome do usuário' },
    },
    face: {
        presence: { allowEmpty: false, message: 'Por favor, adicione uma foto da face' },
    },
};

const useStyles = makeStyles((theme) => ({
    textField: {
        margin: '16px 8px 28px 10px',
        width: '95%',
        [theme.breakpoints.down('sm')]: {
            margin: '16px 8px 28px 16px',
            width: '90%',
        }
    },
    textFieldId: {
        margin: '16px 8px 28px 10px',
        width: '95%',
        [theme.breakpoints.down('sm')]: {
            margin: '16px 8px 28px 16px',
            width: '90%',
        }
    },
    boxForm: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        marginBottom: '24px',
        borderRadius: '12px',
        padding: theme.spacing(1)
    },
    boxPrimary: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '-20px'
    },
    buttonPlusUser: {
        float: 'right',
        marginRight: theme.spacing(2),
        // '&:hover': {
        //     backgroundColor: '#eda1a0'
        // }
    },
    form: {
        maxHeight: '60vh',
        margin: '16px 32px 16px 16px',
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2),
        }
    },
    footerBox: {
        height: '5vh',
        backgroundColor: 'transparent'
    },
    boxContent: {
        backgroundColor: '#e1dfdf',
        margin: '8px',
        minHeight: '70vh',
        paddingBottom: theme.spacing(3),
        borderRadius: '15px',
        [theme.breakpoints.down('sm')]: {
            minHeight: '50vh',
        }
    },
    boxContentLoading: {
        opacity: 0.3
    },
    buttonHeight: {
        height: 'min-content',
    },
    avatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        margin: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        }
    },
    avatarError: {
        border: '2px solid #f7413d'
    },
    boxAlignRight: {
        textAlign: 'right',
        marginLeft: 'auto'
    },
    boxClose: {
        textAlign: 'right',
        padding: 0,
        marginBottom: '-10px',
        marginRight: '-22px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '-10px',
        }
    },
    textError: {
        margin: 0,
        fontSize: '11px',
        marginTop: '3px',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '13px',
        letterSpacing: '0.33px',
        color: '#f7413d'
    },
    progress: {
        margin: 'auto',
        display: 'flex'
    },
}));


export default function InsertPerson({ setFormInsertPerson, formInsertPerson, refInputFile, loadingCommand, setLoadingCommand, errors, setErrors }) {
    //STYLE
    const classes = useStyles();

    //VARIABLES   

    //STATES
    const [modalCam, setModalCam] = useState({ open: false, select: 0 });

    function addBoxPerson() {
        if (formInsertPerson.length > 0) {
            let errors = validate(formInsertPerson[formInsertPerson.length - 1], schema, { fullMessages: false });
            if (!errors) {
                setErrors([]);
                setFormInsertPerson([...formInsertPerson, { id: "", name: "", face: "" }]);
            }
            else {
                errors['index'] = formInsertPerson.length - 1;
                setErrors(errors);
            }
        }
        else {
            setErrors([]);
            setFormInsertPerson([{ id: "", name: "", face: "" }]);
        }
    }

    function deletePerson(index) {
        const itensCopy = Array.from(formInsertPerson);
        itensCopy.splice(index, 1);
        setFormInsertPerson(itensCopy);
    }

    function handleChange(index, name, value) {
        const itensCopy = Array.from(formInsertPerson);
        itensCopy.splice(index, 1, { ...itensCopy[index], [name]: value });
        setFormInsertPerson(itensCopy);
    }

    const handleFileUpload = (file, index) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        const itensCopy = Array.from(formInsertPerson);

        reader.onload = function () {
            var url = reader.result;
            itensCopy.splice(index, 1, { ...itensCopy[index], face: url });
            setFormInsertPerson(itensCopy);
        }
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const savePhoto = (photo) => {
        const itensCopy = Array.from(formInsertPerson);
        itensCopy.splice(modalCam.select, 1, { ...itensCopy[modalCam.select], face: photo });
        setFormInsertPerson(itensCopy);
        setModalCam({ ...modalCam, open: false });
    }

    return (
        <Box className={
            clsx(classes.boxContent, {
                [classes.boxContentLoading]: loadingCommand,
            })
        }
        >
            <PerfectScrollbar>
                <form noValidate autoComplete="off" className={classes.form}>
                    {formInsertPerson.map((person, index) => {
                        return (
                            <Box key={index}>
                                <Box className={classes.boxClose}>
                                    <BootstrapTooltip title="Remover" >
                                        <IconButton onClick={() => deletePerson(index)}>
                                            <CloseIcon color="white"/>
                                        </IconButton>
                                    </BootstrapTooltip>
                                </Box>
                                <Box className={classes.boxForm} key={index}>
                                    <Grid container className={classes.boxPrimary} >
                                        <Grid item xs={1} sm={2} lg={1}>
                                            <Avatar alt="Face" src={person.face}
                                                className={clsx(classes.avatar, {
                                                    [classes.avatarError]: index === errors.index && errors['face'],
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField id="standard-basic" label="Id" className={classes.textFieldId} value={person.id} onChange={e => handleChange(index, 'id', e.target.value)}
                                                error={errors['id']?.length > 0 && index === errors['index']}
                                                helperText={index === errors['index'] && errors['id']}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={7}>
                                            <TextField id="standard-basic" label="Nome" className={classes.textField} value={person.name} onChange={e => handleChange(index, 'name', e.target.value)}
                                                error={errors['name']?.length > 0 && index === errors.index}
                                                helperText={index === errors.index && errors['name']}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box className={classes.boxAlignRight}>
                                        <Typography variant="subtitle2" component="span" className={classes.textError}>
                                            {index === errors.index && errors['face']}
                                        </Typography>
                                        <BootstrapTooltip title="Tirar foto">
                                            <IconButton onClick={() => setModalCam({ open: true, select: index })} className={classes.buttonHeight}>
                                                <AddAPhotoIcon />
                                            </IconButton>
                                        </BootstrapTooltip>
                                        <>
                                            <input
                                                ref={refInputFile}
                                                onChange={e => handleFileUpload(e.target.files[0], index)}
                                                type="file"
                                                style={{ display: "none" }}
                                                multiple={false}
                                                accept="image/jpeg, image/jpg, image/png"
                                            />
                                            <BootstrapTooltip title="Anexar foto">
                                                <IconButton onClick={() => refInputFile.current.click()} className={classes.buttonHeight}>
                                                    <SaveAltIcon />
                                                </IconButton>
                                            </BootstrapTooltip>
                                        </>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })}
                </form>
            </PerfectScrollbar>
            <Box className={classes.footerBox}>
                <BootstrapTooltip title="Adicionar pessoa">
                    <Fab size="medium" color="primary" aria-label="add" className={classes.buttonPlusUser} onClick={() => addBoxPerson()}>
                        <AddIcon />
                    </Fab>
                </BootstrapTooltip>
            </Box>
            <WebcamDialog modalCam={modalCam} setOpenModalCam={setModalCam} savePhoto={savePhoto} />
            {loadingCommand && <CircularProgress className={classes.progress} />}
        </Box>
    );
}
