import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '90vh'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  },
  subtitle: {
    margin: theme.spacing(2,0),
    fontSize: '16px'
  }
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4} >
        <Grid item lg={6} xs={12} >
          <div className={classes.content}>
            <Typography variant="h1">
              404: A página que você está procurando não está aqui
            </Typography>
            <Typography variant="subtitle2" className={classes.subtitle}>
              Você tentou alguma rota sombria ou veio aqui por engano. Seja qual for, tente usar a navegação
            </Typography>
            <img
              alt="Imagem de erro 404 "
              className={classes.image}
              src="/page_not_found.svg"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
