import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HttpsIcon from '@material-ui/icons/Https';
import Swal from 'sweetalert2/src/sweetalert2.js'
import validate from 'validate.js';
import { useHistory } from "react-router-dom";
import AppContext, { saveToken, saveLogin } from '../../context/AppContext';
import { SignIn } from '../../API/endpoints';

const useStyles = makeStyles((theme) => ({
  root1: {
    height: '100vh',
    width: '100vw'
  },
  root: {
    minHeight: theme.spacing(50),
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    minWidth: '45ch',
    padding: '10%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
      margin: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
      margin: 'auto',
      background: 'transparent',
    },
  },
  buttons: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: '99px',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  image: {
    height: 'auto',
    textAlign: 'center',
    padding: theme.spacing(0),
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(75),
      height: theme.spacing(55),
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(75),
      width: theme.spacing(105),
      padding: theme.spacing(10),
    },

  },
  imageGrid: {
    textAlign: 'center',
    backgroundColor: '#272727',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('sm')]: {
      position: 'absolute'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  margin: {
    margin: theme.spacing(1,0),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  icon: {
    fontSize: '30px'
  },
  avatarIcon: {
    marginBottom: theme.spacing(3),
  },
  iconAccountCircle: {
    padding: '12px'
  },
  login_footer_html_class: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: '2%',
    color: theme.palette.white
  },
  footer_link: {
    color: theme.palette.white,
    fontFamily: '"Titillium Web", "sans-serif"'
  },
  magin_bottom_1perc: {
    marginBottom: '1%',
    textAlign: 'center'
  },
  logo_biomtech: {
    zIndex: '2',
    width: '80%',
    [theme.breakpoints.only('sm')]: {
      width: '30%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    }
  },
  gridLogin:{
    zIndex: 5
  },
  gridItem:{
    width: '100%'
  },
  deviceControlTitle:{
    position: 'absolute',
    top: '5%'
  }
}));

const Login = props => {
  //STYLE
  const classes = useStyles();

  //VARIABLES
  let history = useHistory();

  const { setUserToken } = useContext(AppContext)

  //STATES
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value?.trim() });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      cancelButton: 'swal2-cancel'
    },
    // buttonsStyling: false
  })
  const handleLoginClick = event => {
    event.preventDefault();
    if (validate.isEmpty(values.email) || validate.isEmpty(values.password))
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: validate.isEmpty(values.email) && validate.isEmpty(values.password) ? 'Campo e-mail e campo senha não preenchido.' : validate.isEmpty(values.email) ? 'Campo e-mail não preenchido.' : 'Campo senha não preenchido.'
      })
    else {
      var hasError = true;
      var errorText = 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.';

      SignIn({ username: values.email, password: values.password })
        .then(async response => {
          if (response.status === 200) {
            var save = await saveToken(response.data.Token);
            saveLogin(values.email)
            setUserToken(response.data.Token)
            history.push("/devices");
            hasError = false
          }
          else {
            if (response.status === 404)
              errorText = "E-mail não cadastrado, por favor entre em contato com o suporte."
            else if (response.data.cause[0] === "client not authorized password does not match") {
              errorText = "Senha inválida."
            }
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          if (hasError) {
            swalWithBootstrapButtons.fire({
              icon: 'error',
              title: 'Erro',
              text: errorText,
              timer: 3000,
            })
          }
        });
    }
  }

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root1}
    >
      <Grid item lg={7} md={6} sm={false} xs={false} className={classes.imageGrid}>
        <img src='/devices.png' alt="dispositivos biomtech" className={classes.image} />
        <footer className={classes.login_footer_html_class}>
          <Grid container
            className={classes.magin_bottom_1perc}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid item lg={12}>
              <a href="http://www.biomtech.com.br" target="_blank">
                <img className={classes.logo_biomtech}
                  alt="Logo"
                  src="/logo-biomtech.png"
                />
              </a>
            </Grid>
          </Grid>
        </footer>
      </Grid>
      <Grid item lg={5} md={6} sm={12} xs={12} className={classes.gridLogin}>
        <Card id="loginCard" className={classes.root}>
          <form autoComplete="off" onSubmit={event => handleLoginClick(event)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item lg={10} xs={10} className={classes.deviceControlTitle} >
               <Typography variant="h1" >
                 Device Control
               </Typography>
              </Grid>
              <Grid item lg={10} xs={10} className={classes.avatarIcon}>
                <Avatar className={classes.avatar}>
                  <HttpsIcon className={classes.icon} fontSize="large" />
                </Avatar>
              </Grid>
            
              <Grid item lg={10} xs={10} className={classes.gridItem}>
                <FormControl className={clsx(classes.margin, classes.textField)} fullWidth>
                  <InputLabel htmlFor="login-user">E-mail</InputLabel>
                  <Input
                    id="login-user"
                    type='text'
                    value={values.email}
                    onChange={handleChange('email')}
                    endAdornment={
                      <InputAdornment position="end" className={classes.iconAccountCircle}>
                        <AccountCircle />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={10} xs={10} className={classes.gridItem}>
                <FormControl className={clsx(classes.margin, classes.textField)} fullWidth>
                  <InputLabel htmlFor="standard-adornment-password">Senha</InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={10} xs={10} className={classes.gridItem}>
                <Button variant="contained" color="primary" type="submit" fullWidth className={classes.buttons} onClick={event => handleLoginClick(event)}>
                  Entrar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
        <Hidden mdUp>
        <footer className={classes.login_footer_html_class}>
          <Grid container
            className={classes.magin_bottom_1perc}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid item lg={12}>
              <a href="http://www.biomtech.com.br" target="_blank">
                <img className={classes.logo_biomtech}
                  alt="Logo"
                  src="/logo-biomtech_cinza.png"
                />
              </a>
            </Grid>
          </Grid>
        </footer>
        </Hidden>
      </Grid>
    </Grid>
  );
}

export default Login