import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const grayColor = [
    "#999",
    "#777",
    "#3C4858",
    "#AAAAAA",
    "#D2D2D2",
    "#DDD",
    "#b4b4b4",
    "#555555",
    "#333",
    "#a9afbb",
    "#eee",
    "#e7e7e7"
];

export default {
    black,
    white,
    grayColor,
    primary: {
        contrastText: white,
        dark: '#BBD400',
        main: '#76AB00',
        light: '#f6875e'
    },
    secondary: {
        contrastText: white,
        dark: colors.blue[900],
        main: '#ef5350',
        light: colors.blue['A400']
    },
    success: {
        contrastText: white,
        dark: colors.green[900],
        main: '#4caf50',
        light: '#81c784'
    },
    info: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue[600],
        light: colors.blue[400]
    },
    warning: {
        contrastText: white,
        dark: colors.orange[900],
        main: '#ff9800',
        light: colors.orange[400]
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400]
    },
    biomtech_colors: {
        contrastText: black,
        dark: '#e87021',
        main: '#f0721e',
        light: '#fa7720'
    },
    text: {
        primary: '#4A4A4A',
        secondary: '#8A8A8D',
        link: colors.blue[600]
    },
    background: {
        default: '#303030',
        paper: '#FFFFFF',
        semi_transparent_dark: 'rgba(0,0,0,0.2)',
        semi_transparent_light: 'rgba(255,255,255,0.3)'
    },
    icon: colors.blueGrey[600],
    divider: 'rgba(255, 255, 255)',
    action: {
        disabledBackground: '#bbb',
        disabledColor: '#333',
    }
};
