import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, IconButton, Grid, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { BootstrapTooltip } from '../../../../components';
import { isUserView, isAdmin } from '../../../../context/AppContext';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '0',
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        backgroundColor: 'transparent',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    cardBody: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        margin: theme.spacing(2, 0),
        boxShadow:' 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius: '8px',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            overflow: 'auto'
        }
    },
    cardBodyEmpty:{
        width: '100%',
        backgroundColor: '#FFFFFF',
        margin: theme.spacing(2, 0),
        textAlign: 'center',
        padding: theme.spacing(3)
    },
    rowCard: {
        padding: '5%', 
        display: 'grid',
        borderBottom: ' 1px solid rgb(117 125 129)',
        [theme.breakpoints.only('sm')]: {
            padding: '2%', 
        }
    },
    deleteIcon:{
        color: '#db1414'
    },
    editIcon:{
        color: '#4a4a4a'
    },
    iconButton: {
        padding: '8px'
    }
}));

const TableMobile = ({ clients, openEditClient, handleDeleteClient, setClientDetailsModal }) => {

    const classes = useStyles();

    return (
        <Card id="cardList" className={classes.root}>
            {clients.length > 0 ?
                clients.map((client, index) => (
                    <CardContent key={index} className={classes.cardBody}
                        onClick={() => {
                            setClientDetailsModal({open: true, client: client })
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            className={classes.rowCard}
                        >
                            <Grid item lg={4} sm={4} xs={12}>
                                <Typography >
                                    Nome
                                </Typography >
                            </Grid>
                            <Grid item lg={4} sm={4} xs={12}>
                                <Typography variant='h5'>
                                    {client.name}
                                </Typography >
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            className={classes.rowCard}
                        >
                            <Grid item lg={4} sm={4} xs={12}>
                                <Typography >
                                    E-mail
                                </Typography >
                            </Grid>
                            <Grid item lg={4} sm={4} xs={12}>
                                <Typography variant='h5'>
                                    {client.email}
                                </Typography >
                            </Grid>
                        </Grid>
                        {!isUserView() && <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            className={classes.rowCard}
                        >
                            <Grid item lg={4} sm={4} xs={12}>
                                <Typography>
                                    Ação
                                </Typography >
                            </Grid>
                         
                            {isAdmin() && client.clientType !== "ADMIN" ? <Grid item  lg={4} sm={4} xs={12}>
                                    <BootstrapTooltip title="Editar" >
                                        <IconButton onClick={e => openEditClient(client, e)}  className={classes.iconButton}>
                                            <EditIcon className={classes.editIcon} />
                                        </IconButton>
                                    </BootstrapTooltip>
                                    <BootstrapTooltip title="Remover" >
                                        <IconButton onClick={e => handleDeleteClient(client, e)}  className={classes.iconButton}>
                                            <DeleteForeverIcon className={classes.deleteIcon} />
                                        </IconButton>
                                    </BootstrapTooltip>
                                </Grid> :
                                    <Grid item  lg={4} sm={4} xs={12}>
                                        <LockIcon />
                                    </Grid>
                                }
                        </Grid>}
                    </CardContent>
                )) :
                <CardContent className={classes.cardBodyEmpty}>
                    <Typography>
                        Lista vazia
                    </Typography >
                </CardContent>
            }
        </Card>
    );
}
export default TableMobile