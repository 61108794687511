import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    TextField,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    textField: {
        margin: '16px 8px 28px 10px',
        width: '95%',
        [theme.breakpoints.down('sm')]: {
            margin: '16px 8px 28px 16px',
            width: '90%',
        }
    },
}));


export default function ComponentInput({ name, label, type, handleChange, validateElement, errors, formState }) {
    //STYLE
    const classes = useStyles();

    return (
        <TextField
                name={name}
                label={label}
                className={classes.textField}
                type={type}
                variant="standard"
                value={formState[name]}
                onChange={e => handleChange(name, e.target.value)}
                onBlur={e => validateElement(name)}
                error={errors[name]?.length > 0}
                helperText={errors[name]}
            />
    );
}
