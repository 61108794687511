//REACT LIBRARIES
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import GridOnIcon from '@material-ui/icons/GridOn';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import moment from 'moment';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake';
import { CSVLink } from 'react-csv';
import CloseIcon from '@material-ui/icons/Close';
import { validate } from 'validate.js';

const useStyles = makeStyles(theme => ({
    root: theme => ({
        textAlign: 'left',
        padding: theme.spacing(0),
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0',
        }
    }),
    box: theme => ({
        width: 'inherit',
        padding: theme.spacing(0),
        marginTop: '1em',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 3),
        }
    }),
    listItemText: {
        color: '#4A4A4A',
        fontFamily: 'Raleway',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '150%',
        fontFeatureSettings: '"pnum" on, "lnum" on',
    },
    listItem: theme => ({
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: '4%',
        }
    }),
    icons: {
        fontSize: '24px'
    },
    gridButton: {
        paddingTop: theme => theme.spacing(3)
    },
    containerHeader: {
        display: ' inline-flex',
        justifyContent: 'space-between'
    },
    title: {
        fontWeight: 700,
        margin: theme.spacing(2),
    },
    buttonCancel: {
        color: '#8A8A8D',
        textDecorationLine: 'underline',
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0),
        }
    },
}));

const ComponentExport = ({ callbackToExport, statusMessages, close, closeModalDownload, openModalDownload, formState }) => {
    //GLOBAL CONST AND CONFIGURATION

    //LOCAL CONST AND VARIABLES

    //STYLE
    const theme = useTheme();
    const classes = useStyles(theme);

    const [title, setTitle]  = React.useState("Callbacks Recentes");
    const [subtitle, setSubtitle]  = React.useState("");
    const [headerDevice, setHeaderDevice] = React.useState("");
    const [headerStatus, setHeaderStatus] = React.useState("");

    const dividerListUrlCallback = (url) => {
        var urlContingency = [];
        var iterateUrlContingency = Object.entries(url).filter(x => { 
            if(x[1] === true){ 
                urlContingency.push(x[0])
            } 
        });
        var urlRealTime = [];
        var iterateUrlRealTime = Object.entries(url).filter(x => { if(x[1] === false){ 
            urlRealTime.push(x[0])
            return x[0]} });
        var typeMessage = Object.entries(url).length === 0?'Sem Url cadastrada': urlContingency.length ===  Object.entries(url).length ? 'Contigência' : urlContingency.length === 0 ? "Tempo real" : "Contigência parcial";
        
        return typeMessage
    }

    const dividerListUrlCallbackStatus = (url) => {
        var urlSend = [];
        var iterateUrlSend = Object.entries(url).filter(x => { 
            if(x[1] === true){ 
                urlSend.push(x[0])
            } 
        });
        var urlNotSend = [];
        var iterateUrlNotSend = Object.entries(url).filter(x => { 
            if(x[1] === false){ 
                urlNotSend.push(x[0])
            } 
        });
        var typeMessage = Object.entries(url).length === 0?'Sem Url cadastrada':urlSend.length === Object.entries(url).length ? 'Enviado' : urlSend.length === 0 ? "Não enviado" : "Envio incompleto"

        return typeMessage
    }

    useEffect(() => {
        let subtitleConcat = "";
        if(!validate.isEmpty(formState.initialDate)){
            if(new Date(formState.initialDate).setHours(0, 0, 0, 0) !== new Date(formState.finalDate).setHours(0, 0, 0, 0) )
                setTitle(`Callbacks - De ${moment(formState.initialDate).format("DD/MM/YYYY")} até ${moment(formState.finalDate).format("DD/MM/YYYY")}`);
            else
                setTitle(`Callbacks - ${moment(formState.initialDate).format("DD/MM/YYYY")}`)
        }


        if(!validate.isEmpty(formState.user)){
            subtitleConcat += `Usuário ID: ${formState.user}`;
            setSubtitle(subtitleConcat)
        }
        else
            setSubtitle("")



        if(!validate.isEmpty(formState.devices) && formState.devices.length < 5){
            let deviceConcat = `Dispositivos: `;
            formState.devices.forEach((device, index) => {
                if((index + 1) === formState.devices.length)
                    deviceConcat += `${device.deviceName}`
                else
                    deviceConcat += `${device.deviceName}; `
            })

            setHeaderDevice(deviceConcat)
        }
        else{
            setHeaderDevice("")
        }


        if(!validate.isEmpty(formState.status)){
            let statusConcat = `Transmissão: `;
            if(formState.status === "true")
                statusConcat += `Enviado`;
            else
                statusConcat += `Não enviado`;
            setHeaderStatus(statusConcat)
        }
        else
            setHeaderStatus("")

       
    }, [formState])


    //LOCAL CONST AND VARIABLES
    const headers = [
        { label: 'Nº', key: 'itemNumber' },
        { label: 'Usuário ID', key: 'user' },
        { label: 'Data', key: 'date' },
        { label: 'Dispositivo', key: 'device' },
        { label: 'Padrão de envio', key: 'contingency' },
        { label: 'Transmissão', key: 'status' },
    ];

    let countBodyCSV = 1;
    const bodyCSV = callbackToExport.map(callback => (
        {
            itemNumber: countBodyCSV++,
            user: callback?.deviceCallback?.personId,
            date: moment(new Date(callback?.deviceCallback?.time)).format('DD/MM/YYYY - HH:mm'),
            device: callback?.deviceCallback?.deviceKey,
            contingency: dividerListUrlCallback(callback.deviceCallbackMetadata.webhookUrlContingencyStatus),
            status: dividerListUrlCallbackStatus(callback.deviceCallbackMetadata.webhookUrlStatus),
        }
    )
    );
    pdfMake.tableLayouts = {
        exampleLayout: {
            hLineWidth: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                    return 0;
                }
                return i === node.table.headerRows ? 2 : 1;
            },
            vLineWidth: function (i) {
                return 0;
            },
            hLineColor: function (i) {
                return i === 1 ? 'black' : '#aaa';
            },
            paddingLeft: function (i) {
                return i === 0 ? 0 : 8;
            },
            paddingRight: function (i, node) {
                return i === node.table.widths.length - 1 ? 0 : 8;
            }
        }
    };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const handleExportToPdf = () => {
        closeModalDownload()
        if (callbackToExport.length > 0) {
            const headers = [
                'Nº',
                'Usuário ID',
                'Data',
                'Dispositivo',
                'Padrão de envio',
                'Transmissão'
            ];

            let countBody = 1;
            const body = callbackToExport.map(callback => [
                countBody++,
                callback.deviceCallback.personId,
                moment(new Date(callback.deviceCallback.time)).format('DD/MM/YYYY - HH:mm'),
                callback.deviceCallback.deviceKey,
                dividerListUrlCallback(callback.deviceCallbackMetadata.webhookUrlContingencyStatus),
                dividerListUrlCallbackStatus(callback.deviceCallbackMetadata.webhookUrlStatus),
            ]);

            const docDefinition = {
                pageOrientation: 'landscape',
                content: [
                    {
                        stack: [title],
                        style: 'header'
                    },
                    {
                        style: 'tableSubheader',
                        table: {
                            headerRows: 1,
                           widths: [`${subtitle.length > 0?'*':'auto'}`,`${headerDevice.length > 0?'*':'auto'}`,`${headerStatus.length > 0?'*':'auto'}` ] ,
                           body: [
                                [{text: `${subtitle}`, style: 'tableHeader'}, {text: `${headerDevice}` , style: 'tableHeader'}, {text: `${headerStatus}` , style: 'tableHeader'}] 
                            ]
                        },
                        layout: 'noBorders',
                    },
                    {
                        layout: 'lightHorizontalLines',
                        table: {
                            headerRows: 1,
                            widths: [15, 150, 120, 120, 150, 100],
                            body: [headers, ...body]
                        }
                    },
                   
                ],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        alignment: 'left',
                        margin: [0, 0, 0, 20]
                    },
                    tableSubheader: {
                        fontSize: 14,
                        margin: [0, 0, 0, 20]
                    }
                }
                
            };

            pdfMake.createPdf(docDefinition).download('Callback.pdf');
        }
    };


    return (
        <Dialog onClose={closeModalDownload} open={openModalDownload} fullWidth>
            <Grid container spacing={0}>
                <Grid item xs={12} className={classes.containerHeader} >
                    <Typography variant="body1" className={classes.title}>
                        Exportar Callbacks
                    </Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={() => closeModalDownload()}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <DialogContent>
                <Grid container component="main" direction="column" className={classes.root}>
                    <List component="nav" aria-label="lista de opções de download">
                        <ListItem button className={classes.listItem} onClick={() => handleExportToPdf()}>
                            <ListItemIcon>
                                <PictureAsPdfIcon className={classes.icons} />
                            </ListItemIcon>
                            <ListItemText
                                primary="PDF"
                                primaryTypographyProps={{ props: "body1", primary: '#4A4A4A' }}
                                style={{ color: '#4A4A4A' }}
                                className={classes.listItemText}
                                disableTypography={true}
                            />
                        </ListItem>
                        <CSVLink
                            separator=";"
                            target="_blank"
                            data={bodyCSV}
                            headers={headers}
                            filename="Callback.csv"
                        >
                            <ListItem button className={classes.listItem} onClick={() => closeModalDownload()}>
                                <ListItemIcon>
                                    <GridOnIcon className={classes.icons} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="CSV"
                                    primaryTypographyProps={{ props: "body1", primary: '#4A4A4A' }}
                                    style={{ color: '#4A4A4A' }}
                                    className={classes.listItemText}
                                    disableTypography={true}
                                />
                            </ListItem>
                        </CSVLink>
                    </List>
                    <Grid container component="main" direction="row" className={classes.gridButton}>
                        <Grid item xs={12} md={12} >
                            <Button
                                size="medium"
                                aria-label="cancelar"
                                fullWidth={true}
                                onClick={() => closeModalDownload()}
                                className={classes.buttonCancel}
                            >
                                cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog >
    );
};
export default ComponentExport;
