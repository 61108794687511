import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Tooltip,
} from '@material-ui/core';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: '#76AB00',
    },
    tooltip: {
        backgroundColor: '#76AB00',
    },
}));

export default function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}
