import React, { createContext, useContext, useMemo, useState } from 'react';
import { useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import validate from 'validate.js';
import AppContext, { isAdmin } from "./AppContext";
import { NotificationsApiContext } from './NotificationsContext';

const getResultCommands = () => {
    const statusCommands = JSON.parse(sessionStorage.getItem('statusCommands'));
    let arr = [];
    if (statusCommands !== null) {
        arr = Array.from(statusCommands);
        if (arr.length === 0)
            return [statusCommands];
        else
            return arr;
    }
    else {
        return [];
    }
};

export const RealTimeDeviceStatusContext = createContext();
export const RealTimeResultCommandsContext = createContext();

export const RealTimeProvider = ({ children }) => {
    
    const { userToken, userLoggedIn } = useContext(AppContext);
    const { incrementNotificationCounter } = useContext(NotificationsApiContext);

    const [statusListDevice, setStatusListDevice] = useState([]);
    const [loadingCommand, setLoadingCommand] = useState(
        {
            'command': {deviceId:null, open:false},
            'manageDevice': {deviceId:null, open:false},
            'devices': {deviceId:null, open:false}
        }
    )

    const saveResultCommands = (value) => {
        var statusCommandsLocal = JSON.parse(sessionStorage.getItem('statusCommands'));
       
        if (statusCommandsLocal !== null) {
            if (Array.isArray(statusCommandsLocal))
                statusCommandsLocal.unshift(value);
            else {
                statusCommandsLocal = [statusCommandsLocal];
                statusCommandsLocal.unshift(value)
            }
            sessionStorage.setItem('statusCommands', JSON.stringify(statusCommandsLocal));
        }
        else {
            sessionStorage.setItem('statusCommands', [JSON.stringify(value)]);
        }

        incrementNotificationCounter();
    }

    const saveListUsers = (users, deviceKey) => {
        var listUsersLocal = JSON.parse(sessionStorage.getItem('listUsers'));
        let newUser = { deviceKey: deviceKey, users: users };

        if (listUsersLocal !== null && listUsersLocal.length > 0) {
            if (Array.isArray(listUsersLocal)) {
                var newArrayUsers = Array.from(listUsersLocal);

                var hasId = false;
                listUsersLocal.map((el, index) => {
                    if (el.deviceKey === deviceKey) {
                        newArrayUsers.splice(index, 1, { ...newUser });
                        hasId = true
                    }
                })
                if (!hasId) {
                    newArrayUsers.push(newUser)
                }
            }
            sessionStorage.setItem('listUsers', JSON.stringify(newArrayUsers));
        }
        else {
            sessionStorage.setItem('listUsers', JSON.stringify([newUser]));
        }
    }

    function buildWebSocketUrl() {
        const url = new URL("/websocket/conn", process.env.REACT_APP_API_BASE_URL);
        url.protocol = url.protocol === "https:" ? "wss:" : "ws:";
        url.searchParams.append("authorization", userToken);
        return url.toString();
    }

    const wsUrlOrNull = userLoggedIn ? buildWebSocketUrl() : null;
    const { lastJsonMessage } = useWebSocket(wsUrlOrNull, {
        onOpen: () => {
            console.log(`Connected WebSocket`);
        },
        // onMessage: (response, b) => {},
        onError: (event) => { console.error(event); },
        onClose: (event) => {
            console.log({ event }) 
            return false
        },
        shouldReconnect: (closeEvent) => {
            console.debug("Should Reconnect Close Event: ", { closeEvent });
            return true;
        },
        // reconnectInterval: 6000
    });

    useEffect(() => {
        if (!lastJsonMessage) {
            return;
        }

        const arrayData = lastJsonMessage;        
        if (arrayData.type === 'devices') {
            setStatusListDevice(arrayData.data)
        }
        else if (arrayData.type === 'operations') {
            if (arrayData.data.type === 'enrollUser') {
                var listIds = [];
                if(arrayData.data.result.successfullEnrolls && arrayData.data.result.successfullEnrolls.length > 0){
                    listIds = arrayData.data.result.successfullEnrolls.filter(el => el.deviceKey === loadingCommand.command.deviceId);
                }
                else if(arrayData.data.result.failedEnrolls !== null){
                    listIds = arrayData.data.result.failedEnrolls.filter(el => el.deviceKey === loadingCommand.command.deviceId)
                }
                if(listIds.length > 0)
                    setLoadingCommand({ ...loadingCommand, 'command': {open: false, deviceId: null }});
            }
            else if(arrayData.data.type === 'deleteUser'){
                var listIds = [];
                if(arrayData.data.result.results.length > 0){
                    listIds = arrayData.data.result.results.filter(el => el.deviceKey === loadingCommand.manageDevice.deviceId)
                }
                if(listIds.length > 0)
                    setLoadingCommand({ ...loadingCommand, 'manageDevice': {open: false, deviceId: null }});
            }
            else if(arrayData.data.type === 'updateUser'){
                if(arrayData.data.result.deviceId === loadingCommand.manageDevice.deviceId){
                    setLoadingCommand({
                        ...loadingCommand,
                        'manageDevice': {open: false, deviceId: null }
                    });
                }
            }
            else {
                if(arrayData.data.result.deviceKey === loadingCommand.manageDevice.deviceId){
                    setLoadingCommand({
                        ...loadingCommand,
                        'manageDevice': {open: false, deviceId: null }
                    });
                }
            }
            if (arrayData.data.type === 'listUsers') {
                saveListUsers(arrayData.data.result.users, arrayData.data.result.deviceKey);
            }
            if(isAdmin()){
                if(!validate.isEmpty(arrayData.clientEmail)){
                    saveResultCommands({clientEmail: arrayData.clientEmail, result: arrayData.data.result, type: arrayData.data.type});
                }
            }
            else{
                saveResultCommands(arrayData.data);
            }
        }
    }, [lastJsonMessage]);

    const deviceStatusContextValue = useMemo(() => ({
        statusListDevice,
    }), [statusListDevice]);

    const resultCommandsContextValue = useMemo(() => ({
        getResultCommands,
        loadingCommand,
        setLoadingCommand,
    }), [getResultCommands, loadingCommand, setLoadingCommand]);

    return (
        <RealTimeDeviceStatusContext.Provider value={deviceStatusContextValue}>
            <RealTimeResultCommandsContext.Provider value={resultCommandsContextValue}>
                {children}
            </RealTimeResultCommandsContext.Provider>
        </RealTimeDeviceStatusContext.Provider>
    );
};
