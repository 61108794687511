import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isAuthenticated } from '../../context/AppContext';
import { Redirect } from 'react-router-dom';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, path, table, title, module_form, textButtonAdd, isPrivate, key } = props;
  
  if (isPrivate) {
    if (isAuthenticated())
      return (
        <Route
          path={path}
          render={match_props => (
            <Layout>
              <Component {...match_props} key={key} table={table} title={title} module_form={module_form} textButtonAdd={textButtonAdd} />
            </Layout>
          )}
        />
      );
    else
      return (<Redirect
        to="/login"
      />)
  }
  else {
    return (
      <Route
        path={path}
        render={match_props => (
          <Layout>
            <Component {...match_props} table={table} title={title} module_form={module_form} textButtonAdd={textButtonAdd} />
          </Layout>
        )}
      />
    );
  }
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
