import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardMedia,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  body: {
    backgroundColor: 'white'
  },
  root: {
    display: 'flex',
    backgroundColor: 'white'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(9),
  },
  cover: {
    width: 250,
    height: 80,
    margin: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  row: {
    color: '#272727',
    textAlign: 'center'
  },
  title: {
    color: '#272727',
    textAlign: 'center'
  }
}));
const ComponentToPrint = React.forwardRef((props, ref) => {

  //STYLE
  const classes = useStyles();

  return (
    <div ref={ref} className={classes.container}>
      <Card className={classes.root}>
        <CardMedia
          className={classes.cover}
          image="/logo_biomtech.png"
          title="Logo biomtech"
        />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5" className={classes.title}>
              RELATÓRIO
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {moment().format('DD/MM/yyyy h:mm')}
            </Typography>
          </CardContent>
          <div className={classes.controls}>
          </div>
        </div>
      </Card>
      <TableContainer ref={ref} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Número Serial</TableCell>
              <TableCell>Ação</TableCell>
              <TableCell>Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {props.data.map((row) => (
              <TableRow key={row.name} >
                <TableCell scope="row" className={classes.row}>{row.serialNumber}</TableCell>
                <TableCell className={classes.row}>{row.action}</TableCell>
                <TableCell className={classes.row}>{row.date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export default ComponentToPrint
