import React from 'react';
import { TableList } from '../../views';
import { TableDefault } from '../../components'
import InputDevice from '../../views/User/InputDevices'
import PersonIcon from '@material-ui/icons/Person';
import { Main as MainLayout } from '../../layouts';

const module_name = 'User';
const module_name_as_title = 'Usuário';
const module_name_route_address = 'user';
const module_api_route = '/user';
const textButtonAdd = 'Salvar e Criar Outro'
/*------------------------------*/
/*BEGIN LIST CONFIGURATION*/
const table_columns = [
    {
        name: "user",
        label: "Nome",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "company",
        label: "Empresa",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "status",
        label: "Status",
        options: {
            filter: false,
            sort: true,
        }
    }
];

//LIST CONFIG RESUME
const configTable = {
    print:false,
    download:false,
    viewColumns: false,
    filter:true,
    sort: true,
    title:false,
};
/*END LIST CONFIGURATION*/
/*------------------------------*/

/*------------------------------*/
/*BEGIN FORM CONFIGURATION*/
const module_form = {
    title: module_name_as_title,
    object: {
        id: 0,
        status:'0',
    },
    form_fields: [
        {
            name: 'company',
            title: 'Empresa *',
            type: 'select',
            isRequired: true,
            show_on_view: true,
            show_on_add: true,
            show_on_edit: true,
            default_value: '',
            add_to_object: false,
            add_to_field: 'company',
            grid_lg: 12,
            grid_xs:12
        },
        {
            name: 'name',
            title: 'Usuário *',
            type: 'text',
            isRequired: true,
            show_on_view: true,
            show_on_add: true,
            show_on_edit: true,
            default_value: '',
            add_to_object: true,
            grid_lg: 12,
            grid_xs:12
        },
        {
            name: 'device',
            title: 'Dispositivo',
            type: 'specialComponent',
            component: InputDevice,
            isRequired: true,
            show_on_view: true,
            show_on_add: true,
            show_on_edit: true,
            default_value: '',
            add_to_object: true,
            grid_lg: 12,
            grid_xs:12
        },
    ],
    validate_js_constraints: {
        name: {
            presence:{message: "Campo Obrigatório"}
        },
        company: {
            presence:{message: "Campo Obrigatório"}
        }
    },
    select_fields: {
        fields: ['company'],
        route: 'company'
    }
};
/*END FORM CONFIGURATION*/
/*------------------------------*/
const table = {
    type: 'table',
    configTable,
    table_columns,
    view: TableList,
    isCollapse: true,
    hasForm: true
};
export default {
    name: module_name,
    title: module_name_as_title,
    component: TableDefault,
    layout: MainLayout,
    path: '/' + module_name_route_address,
    module_api_route,
    icon: <PersonIcon/>,
    showOnSidebar: true,
    module_form,
    table, 
    textButtonAdd: textButtonAdd
};