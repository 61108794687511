import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import { ListClients } from '../../API/endpoints';
import { getUserInfo } from '../../context/AppContext';


const useStyles = makeStyles((theme) => ({
    formControlClient: {
        width: '90%',
        margin: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
}));

export default function InputListClients({ clientSelected, changeClientSelect }) {
    //STYLE
    const classes = useStyles();

    //GLOBAL VARIABLES

    //STATE
    const [clients, setClients] = useState([]);

    const hasEmail = (array, email) => {
        var result = array.filter(el => el.email === email);

        return result.length > 0
    }

    const getOnlyClient = (array) => {
        var result = array.filter(el => el.name.indexOf("READ ONLY") === -1);

        return result
    }

    function getClients() {
            ListClients()
                .then(response => {
                    setClients(getOnlyClient(response.data.clients));
                    var listClientsFilter = getOnlyClient(response.data.clients);
                    if (!hasEmail(listClientsFilter, getUserInfo()) && !hasEmail(listClientsFilter, clientSelected)) {
                        changeClientSelect(response.data.clients[0].email)
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {

                });
    }

    useEffect(() => {
        getClients();
    }, [])

    return (
        <>
            {clients.length > 1 && <FormControl className={classes.formControlClient}>
                <InputLabel htmlFor="age-native-simple">Clientes</InputLabel>
                <Select
                    inputProps={{
                        name: 'clientes',
                    }}
                    value={clientSelected}
                    onChange={(event) => changeClientSelect(event.target.value)}
                >
                    {
                        clients.map((client, index) => (
                            <MenuItem key={index} value={client.email}>{client.name}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>}
        </>
    );
}
