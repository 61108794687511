import React, { useState, useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardHeader,
    Checkbox,
    CircularProgress,
    Divider,
    InputBase,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    TablePagination,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

const useStyles = makeStyles((theme) => ({
    boxDevice: {
        // backgroundColor: '#272727',
        margin: '8px',
        padding: '16px',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        backgroundColor: theme.palette.background.paper,
        maxHeight: '70vh',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '55vh',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        margin: '8px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputSearch: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    listItemText: {
        color: '#8f9397'
    },
    subheader: {
        color: '#8f9397'
    },
    dividerChecked: {
        backgroundColor: '#6a6666'
    },
    listItemEmpty: {
        textAlign: 'center',
        fontSize: '16px',
        cursor: 'auto',
    },
    progress: {
        margin: 'auto',
        display: 'flex'
    },
    pagination: {
        margin: theme.spacing(1),
        marginBottom: 0,
    },
    paginationToolbar: {
        position: 'initial',
        right: 0,
        flexWrap: 'wrap',
        textAlign: 'center',
        justifyContent: 'center',
        padding: 0
    },
    paginationActions: {
        marginLeft: 0
    }
}));

function ListDevices({ history, devicesChecked, setDevicesChecked, itemsFilterDevices, setItemsFilterDevices, loadingDevices, devices, updatePage }) {
    //STYLE
    const classes = useStyles();

    //VARIABLES

    //STATES
    const [filter, setFilter] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);


    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }

    function union(a, b) {
        return [...a, ...not(b, a)];
    }
    const numberOfChecked = (items) => intersection(devicesChecked, items).length;

    //PAGINATION
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => { setPage(0);}, [updatePage])

    const handleToggle = (value) => () => {
        const currentIndex = devicesChecked.indexOf(value);
        const newChecked = [...devicesChecked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setDevicesChecked(newChecked);
    };

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setDevicesChecked(not(devicesChecked, items));
        } else {
            setDevicesChecked(union(devicesChecked, items));
        }
    };

    useEffect(() => {
        function handleSearch() {
            if (filter !== "") {
                var newArray = devices.filter((value) => {
                    var name = value.deviceName.toLowerCase();
                    var deviceId = value.deviceKey.toLowerCase();
                    var compound = name.split(' ');
                    var indexOfCompound = -1;
                    if (compound.length > 1)
                        indexOfCompound = compound[1].indexOf(filter.toLowerCase())
                    return name.indexOf(filter.toLowerCase()) === 0 || indexOfCompound === 0 || deviceId.indexOf(filter.toLowerCase()) === 0
                })
                setItemsFilterDevices(newArray)
                setPage(0)
            }
            else {
                setItemsFilterDevices(devices)
            }
        }

        handleSearch();
    }, [filter])

    let filterData = not(itemsFilterDevices, devicesChecked).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Card className={classes.boxDevice}>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(devices)}
                        checked={numberOfChecked(devices) === devices.length && devices.length !== 0}
                        indeterminate={numberOfChecked(devices) !== devices.length && numberOfChecked(devices) !== 0}
                        disabled={devices.length === 0}
                        inputProps={{ 'aria-label': 'todos os items selecionados' }}
                        color="primary"
                    />
                }
                title="Dispositivos"
                subheader={<Typography varianty="body2" className={classes.subheader}>{numberOfChecked(devices)}/{devices.length} selecionados</Typography>}
            />
            <Divider />
            <PerfectScrollbar>
                <List className={classes.list} dense component="div" role="list" >
                    {devicesChecked.map((value) => {
                        return (
                            <ListItem key={value.deviceKey} role="listitem" button onClick={handleToggle(value)}>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={devicesChecked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        color="primary"
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.listItemText}
                                            color="textPrimary"
                                        >
                                            {value.deviceName} {value.deviceName !== "" ? '-' : ''} {value.deviceKey}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                    {devicesChecked.length > 0 ? <Divider className={classes.dividerChecked} /> : ''}
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Pesquisar..."
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputSearch,
                            }}
                            inputProps={{ 'aria-label': 'pesquisar' }}
                            onChange={e => setFilter(e.target.value)}
                        />
                    </div>
                    {filterData.length > 0 ?
                        filterData.map((value) => (
                            <ListItem key={value.deviceKey} role="listitem" button onClick={handleToggle(value)}  >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={devicesChecked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        color="primary"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.listItemText}
                                            color="textPrimary"
                                        >
                                            {value.deviceName} {value.deviceName !== "" ? '-' : ''} {value.deviceKey}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        ))
                        :
                        <ListItem role="listitem" button className={classes.listItemEmpty} >
                            <ListItemText
                                primary={
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.listItemText}
                                        color="textPrimary"
                                    >
                                        Lista vazia
                                    </Typography>
                                }
                            />
                        </ListItem>
                    }
                </List>
            </PerfectScrollbar>
            {loadingDevices && <CircularProgress className={classes.progress} />}
            <TablePagination
                component="div"
                className={classes.pagination}
                classes={{ toolbar: classes.paginationToolbar, actions: classes.paginationActions }}
                colSpan={3}
                count={not(itemsFilterDevices, devicesChecked).length}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : ` até ${to}`
                    }`
                }
                labelRowsPerPage={""}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[15, 30, 45]}
            />
        </Card>
    );
}
export default memo(ListDevices);