import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    Grid,
    Hidden,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';
import {
    CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
    HighlightOff as HighlightOffIcon,
    Repeat as RepeatIcon,
    FiberManualRecord as FiberManualRecordIcon,
} from '@material-ui/icons';
import { InsertPerson, ListDevices, ConfigDevice } from './components';
import { GetAllDevices, AddUser, ListClients, SetConfigDevices } from '../../API/endpoints';
import clsx from 'clsx';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Swal from 'sweetalert2/src/sweetalert2.js';
import listCodeError from '../../common/listError';
import { AlertError, WaitingCommand, InputListClients } from '../../components';
import { getUserInfo, saveUserInfo, isAdmin } from '../../context/AppContext';
import { RealTimeResultCommandsContext } from '../../context/RealTimeContext';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1, 2),
        [theme.breakpoints.up('md')]: {
            marginLeft: 'auto',
            margin: theme.spacing(3),
        },
        [theme.breakpoints.only('md')]: {
            margin: theme.spacing(0),
            padding: theme.spacing(0),
        },
    },
    formControl: {
        margin: theme.spacing(1),
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    formControlClient: {
        margin: theme.spacing(1),
        width: '90%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    buttonSend: {
        float: 'right',
        margin: '8px',
    },
    buttonRepeat: {
        borderRadius: '99px'
    },
    boxHeaderCommand: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
    },
    buttonSuccess: {
        color: '#83ae24',
        display: 'flex',
        alignItems: 'center',
    },
    buttonSuccessIcon: {
        color: '#83ae24',
        marginLeft: theme.spacing(1)
    },
    hide: {
        display: 'none',
    },
    boxList: {
        borderRadius: '8px',
        borderLeft: '2px solid #f44336'
    },
    cardError: {
        backgroundColor: 'transparent',
        boxShadow: 'none !important',
        width: '100%',
    },
    content: {
        backgroundColor: 'transparent !important',
        boxShadow: 'none',
        paddingBottom: '16px !important',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 0,
    },
    listItem: {
        margin: theme.spacing(1)
    },
    titleCardError: {
        marginBottom: theme.spacing(1),
        color: '#f44336',
    },
    iconTopic: {
        fontSize: '12px',
        marginRight: theme.spacing(1)
    },
    boxCauses: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignContent: 'center',
        alignItems: 'center'
    },
    textCause: {
        marginTop: theme.spacing(1)
    },
    iconCardError: {
        color: '#f44336',
        fontSize: theme.spacing(4),
        marginRight: theme.spacing(2)
    },
    cardActions: {
        display: 'flex',
        padding: '8px',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',

    },
    iconRepeat: {
        marginRight: theme.spacing(1)
    },
    error: {
        marginTop: theme.spacing(3),
        fontFeatureSettings: '"pnum" on, "lnum" on',
    },
}));

var initialValuesConfigDevice = {
    "companyName": "My Test",
    "identityDistance": 1,
    "identifyScores": 85,
    "saveIdentityTime": 0,
    "ttsModType": 100,
    "ttsModContent": "Welcome {name}",
    "comModType": 3,
    "displayModType": 100,
    "displayModContent": "{name} Welcome You",
    "recStrangerTimesThreshold": 3,
    "recStrangerType": 2,
    "ttsModStrangerType": 100,
    "ttsModStrangerContent": "Hello Stranger",
    "wg": "#WG{idcardNum}#",
    "recRank": 2,
    "delayTimeForCloseDoor": 500,
}

export default function Commands({ history }) {
    //STYLE
    const classes = useStyles();

    //VARIABLES
    var commandInsertCache = JSON.parse(sessionStorage.getItem('newCommandAddPerson'));
    var commandInsertCacheConfigDevice = JSON.parse(sessionStorage.getItem('newCommandConfigDevice'));
    const { loadingCommand, setLoadingCommand } = useContext(RealTimeResultCommandsContext);

    //STATES
    const [select, setSelect] = useState('');
    const [devices, setDevices] = useState([]);
    const [itemsFilterDevices, setItemsFilterDevices] = useState(devices);
    const [clients, setClients] = useState([]);
    const [clientSelected, setClientSelected] = useState(getUserInfo() || "");
    const [isValid, setIsValid] = useState(false);
    const [requestData, setRequestData] = useState(false);
    const [loadingDevices, setloadingDevices] = useState(false);
    const [loadingCommandLocal, setLoadingCommandLocal] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [backupUserSelect, setBackupUserSelect] = useState([]);
    const [backupDeviceConfig, setBackupDeviceConfig] = useState([]);
    const [backupDeviceSelect, setBackupDeviceSelect] = useState([]);
    const [formInsertPerson, setFormInsertPerson] = useState(backupUserSelect.length > 0 ? backupUserSelect : [{ id: "", name: "", face: "" }]);
    const [devicesChecked, setDevicesChecked] = useState(backupDeviceSelect);
    const refInputFile = useRef(null);
    const [groupError, setGroupError] = useState([]);
    const [errorsInsertPerson, setErrorsInsertPerson] = useState([]);
    const [updatePage, setUpdatePage] = useState(false);
    const [errorConfigDevice, setErrorConfigDevice] = useState([]);
    const [formStateConfigDevice, setFormStateConfigDevice] = useState(initialValuesConfigDevice);

    const ComponentSelected = {
        insertPerson: <InsertPerson formInsertPerson={formInsertPerson} setFormInsertPerson={setFormInsertPerson} refInputFile={refInputFile} loadingCommand={loadingCommandLocal} setLoadingCommand={setLoadingCommandLocal} errors={errorsInsertPerson} setErrors={setErrorsInsertPerson} />,
        configDevice: <ConfigDevice loadingCommand={loadingCommandLocal} setLoadingCommand={setLoadingCommandLocal} errors={errorConfigDevice} setErrors={setErrorConfigDevice} formState={formStateConfigDevice} setFormState={setFormStateConfigDevice} />
    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            cancelButton: 'swal2-cancel'
        },
    })

    function getClients() {
        ListClients()
            .then(response => {
                console.log(response)
                setClients(response.data.clients)
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {

            });
    }

    useEffect(() => {
        setloadingDevices(true);
        var hasError = true;
        GetAllDevices()
            .then(response => {
                if (response.status === 200) {
                    setDevices(response.data.devices);
                    setItemsFilterDevices(response.data.devices);
                    hasError = false;
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setloadingDevices(false);
                if (hasError) {
                    swalWithBootstrapButtons.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Falha ao listar dispositivos.',
                    })
                }
            });
        getClients();
    }, [requestData])



    const handleChangeCommands = (event) => {
        setSelect(event.target.value);
    };

    const hasUserId = (userId) => {
        var selected = backupUserSelect.filter(user => user.id === userId);
        if (selected.length > 0) return true
        return false
    }

    const hasAndLastUserId = (userId) => {
        var selected = backupUserSelect;
        if (selected.length > 0 && selected[selected.length - 1].id === userId) return true
        return false
    }

    const handleBackupCommandPerson = (selected) => {
        var selected = commandInsertCache.users.filter(user => user.id === selected);
        var selectedDevices = itemsFilterDevices.filter(device => commandInsertCache.devices.indexOf(device.deviceKey) !== -1);

        setBackupDeviceSelect(selectedDevices)

        if (selected.length > 0) {
            if (backupUserSelect.length > 0) {
                const copyState = Array.from(backupUserSelect);
                copyState.push(selected[0]);
                setBackupUserSelect(copyState);
            }
            else {
                setBackupUserSelect(selected)
            }
        }
    }

    const handleBackupCommandAllPerson = () => {
        var usersId = groupUsersIdError(groupError);
        var selected = commandInsertCache.users.filter(user => Array.from(usersId.values()).indexOf(user.id) !== -1);
        var selectedDevices = itemsFilterDevices.filter(device => commandInsertCache.devices.indexOf(device.deviceKey) !== -1);

        setBackupDeviceSelect(selectedDevices);
        setBackupUserSelect(selected);
        setOpenError(false)
    }

    const handleBackupCommandAllConfigDevice = () => {
        var selected = commandInsertCacheConfigDevice.config
        var selectedDevices = itemsFilterDevices.filter(device => commandInsertCacheConfigDevice.devices.indexOf(device.deviceKey) !== -1);

        setBackupDeviceSelect(selectedDevices);
        setBackupDeviceConfig(selected);
        setOpenError(false)
    }

    useEffect(() => {
        if (backupUserSelect.length > 0) {
            setFormInsertPerson(backupUserSelect)
        }
    }, [backupUserSelect])

    useEffect(() => {
        if (backupDeviceSelect.length > 0) {
            setDevicesChecked(backupDeviceSelect)
        }
    }, [backupDeviceSelect])

    useEffect(() => {
        if (Object.keys(backupDeviceConfig).length > 0) {
            setFormStateConfigDevice(backupDeviceConfig)
        }
    }, [backupDeviceConfig])


    useEffect(() => {
        if (select === 'insertPerson') {
            var contEmpty = 0;
            formInsertPerson.map(person => {
                if (person.name === "" || person.face === "" || person.id === "") {
                    return contEmpty++
                }
                return contEmpty;
            })
            setIsValid(contEmpty === 0 && devicesChecked.length > 0)
        }
        else if (select === "configDevice") {
            setIsValid(errorConfigDevice.length === 0 && devicesChecked.length > 0)
        }

    }, [formInsertPerson, devicesChecked, select, errorConfigDevice])

    function handleSave() {
        if (select === 'insertPerson') {
            handleSaveInsertPerson();
        }
        else if (select === "configDevice") {
            handleSaveConfigDevice();
        }
    }

    const groupUsersIdError = (error) => {
        var usersId = new Set();
        error.forEach((err, index) => {
            usersId.add(err.cause[0]);
        });

        return usersId;
    }

    const Errors = () => (
        <List >
            {groupError.map((error, index) => {
                var message = [];
                var userId = "";

                Object.entries(error.cause).forEach(([key, value]) => {
                    if (key === '0')
                        userId += value
                    else
                        message.push(value)
                })
                return (
                    <Box key={index} className={classes.boxList}>
                        <ListItem className={classes.listItem}>
                            <Card className={classes.cardError}>
                                <CardContent className={classes.content}>
                                    <Hidden smDown>
                                        <HighlightOffIcon className={classes.iconCardError} />
                                    </Hidden>
                                    <Box>
                                        <Typography component="h4" variant="h4" className={classes.titleCardError}>
                                            {listCodeError[error.err].title || 'Erro inesperado'}
                                        </Typography>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            ID do usuário: <strong className={classes.contrast}>{userId}</strong>
                                        </Typography>
                                        <Typography variant="subtitle1" color="textSecondary" className={classes.textCause}>
                                            <strong className={classes.contrast}>Causas: </strong>
                                        </Typography>
                                        {message.map((cause, index) => (
                                            <Typography key={index} variant="subtitle1" color="textSecondary" className={classes.boxCauses}>
                                                <FiberManualRecordIcon className={classes.iconTopic} /> {cause}
                                            </Typography>
                                        ))}
                                    </Box>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                    <Button variant="outlined"
                                        color="primary"
                                        className={clsx(classes.buttonRepeat, {
                                            [classes.error]: hasAndLastUserId(userId),
                                            [classes.hide]: hasUserId(userId),
                                        })}
                                        onClick={() => handleBackupCommandPerson(userId)}>
                                        <RepeatIcon className={classes.iconRepeat} />
                                        Utilizar novamente os dados
                                    </Button>
                                    <Typography
                                        variant="h6"
                                        component="h6"
                                        className={clsx({
                                            [classes.hide]: !hasUserId(userId),
                                            [classes.buttonSuccess]: hasUserId(userId),
                                        })}
                                    >
                                        Dados copiados para o formulário
                                        <CheckCircleOutlineOutlinedIcon className={classes.buttonSuccessIcon} />
                                    </Typography>
                                </CardActions>
                            </Card>
                        </ListItem>
                    </Box>
                )
            })}
        </List>
    )


    const ErrorsConfigDevice = () => {
        var message = [];
        if(groupError.cause){
            Object.entries(groupError.cause).forEach(([key, value]) => {
                message.push(value)
            })

            return (
                <List >
                    <Box className={classes.boxList}>
                        <ListItem className={classes.listItem}>
                            <Card className={classes.cardError}>
                                <CardContent className={classes.content}>
                                    <Hidden smDown>
                                        <HighlightOffIcon className={classes.iconCardError} />
                                    </Hidden>
                                    <Box>
                                        <Typography component="h4" variant="h4" className={classes.titleCardError}>
                                            {listCodeError[groupError.err].title || 'Erro inesperado'}
                                        </Typography>
                                        <Typography variant="subtitle1" color="textSecondary" className={classes.textCause}>
                                            <strong className={classes.contrast}>Causas: </strong>
                                        </Typography>
                                        {message.map((cause, index) => (
                                            <Typography key={index} variant="subtitle1" color="textSecondary" className={classes.boxCauses}>
                                                <FiberManualRecordIcon className={classes.iconTopic} /> {cause}
                                            </Typography>
                                        ))}
                                    </Box>
                                </CardContent>
                            </Card>
                        </ListItem>
                    </Box>
                </List>
        )}
    }

    function handleSaveInsertPerson() {
        var listDevices = devicesChecked.map(device => { return device.deviceKey });

        //save data form for use again
        sessionStorage.setItem('newCommandAddPerson', JSON.stringify({ devices: listDevices, users: formInsertPerson }));

        //new array without header base64 image
        var newArrayUser = [];
        formInsertPerson.forEach(user => {
            user.face = /base64,(.+)/.exec(user.face)[1];
            newArrayUser.push(user);
        });

        var listError = [];
        setLoadingCommandLocal(true);

        AddUser({ devices: listDevices, users: newArrayUser })
            .then(response => {
                if (response.data.errors && response.data.errors.length > 0) {
                    setOpenError(true);
                    setGroupError(response.data.errors);
                    listError = response.data.errors;
                }
                else if (response.status === 200) {
                    swalWithBootstrapButtons.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Comando enviado com sucesso.',
                    })
                    setLoadingCommand({ ...loadingCommand, 'command': { open: true, deviceId: listDevices[0] } });
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                //clear form
                setDevicesChecked([]);
                setFormInsertPerson([{ id: "", name: "", face: "" }]);
                setBackupUserSelect([])
                if (refInputFile.current !== undefined && refInputFile.current !== null)
                    refInputFile.current.value = "";

                setLoadingCommandLocal(false);
            });
    }

    function handleSaveConfigDevice() {
        var listDevices = devicesChecked.map(device => { return device.deviceKey });

        //save data form for use again
        sessionStorage.setItem('newCommandConfigDevice', JSON.stringify({ devices: listDevices, config: formStateConfigDevice }));

        var listError = [];
        setLoadingCommandLocal(true);

        SetConfigDevices({ ...formStateConfigDevice, devices: listDevices })
            .then(response => {
                if (response.status === 201) {
                    swalWithBootstrapButtons.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Comando enviado com sucesso.',
                    })
                    // setLoadingCommand({ ...loadingCommand, 'command': { open: true, deviceId: listDevices[0] } });
                }
                else {
                    setGroupError(response.data);
                    listError = response.data;
                    setOpenError(true);
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoadingCommandLocal(false);
                setDevicesChecked([]);
                setFormStateConfigDevice(initialValuesConfigDevice);
            });
    }

    const changeClientSelect = (value) => {
        setClientSelected(value);
        saveUserInfo(value);
        setRequestData(!requestData);
        setUpdatePage(!updatePage)
    }

    return (
        <Grid container className={classes.container} spacing={0}>

            <Grid item xs={12} md={4} lg={4}>
                {clients.length > 1 ?
                    <InputListClients clientSelected={clientSelected} changeClientSelect={changeClientSelect} /> : ""
                }
                <ListDevices history={history} devices={devices} updatePage={updatePage} devicesChecked={devicesChecked} setDevicesChecked={setDevicesChecked} itemsFilterDevices={itemsFilterDevices} setItemsFilterDevices={setItemsFilterDevices} loadingDevices={loadingDevices} />
            </Grid>
            <Grid item xs={12} md={8} lg={8} xl={8}>
                <Box className={classes.boxHeaderCommand}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-native-simple">Comandos</InputLabel>
                        <Select
                            value={select}
                            onChange={handleChangeCommands}
                            inputProps={{
                                name: 'comandos',
                            }}
                        >
                            <MenuItem aria-label="None" value=""></MenuItem>
                            <MenuItem value={'insertPerson'}>Inserir Pessoa</MenuItem>
                            {isAdmin() ? <MenuItem value={'configDevice'}>Configurar Dispositivo</MenuItem> : ""}
                        </Select>
                    </FormControl>
                </Box>
                {ComponentSelected[select]}
                <Button variant="contained" color="primary"
                    className={clsx(classes.buttonSend, {
                        [classes.hide]: select === '',
                    })}
                    onClick={() => handleSave()}
                    disabled={!isValid}
                >
                    Enviar
                </Button>
            </Grid>
            <AlertError openError={openError} setOpenError={setOpenError} children={select === 'insertPerson' ? <Errors /> : <ErrorsConfigDevice />} handleBackupCommandPerson={handleBackupCommandPerson} handleBackupCommandAllPerson={select === 'insertPerson' ? handleBackupCommandAllPerson : handleBackupCommandAllConfigDevice} />
            <WaitingCommand openMessage={loadingCommand['command'].open} loadingCommand={loadingCommand} setLoadingCommand={setLoadingCommand} page={'command'} />
        </Grid>
    );
}
