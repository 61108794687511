import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";

const Topbar = props => {
    let history = useHistory();
    //CONTEXT
    // const { handleSignOut } = useContext(AppContext);

    const handleSignOut = () =>{
        history.push("/login");
    }

    //PROPS
    const { cssVariables, onSidebarOpen, ...rest } = props;

    //STYLE
    const useStyles = makeStyles(theme => ({
        root: {
            height: cssVariables.topbar_css.height,
            boxShadow: ' 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important',
            backgroundColor: '#76AB00'
        },
        flexGrow: {
          flexGrow: 1
        },
        topbar_css: {
          backgroundColor: '#eee',
          color: 'black'
        },
        signOutButton: {
          marginLeft: theme.spacing(1)
        },
        logo_img: {
          maxHeight: '30px',
          marginTop: theme.spacing(2)
        },
        logo:{
            color:'white',
            marginTop: theme.spacing(2)
        }
      }));
    const classes = useStyles();

    return (
        <AppBar
            {...rest}
            className={classes.root}
        >
            <Toolbar>
                <Grid container>
                    <Grid item className={classes.flexGrow} align="left">
                        <Hidden lgUp>
                            <IconButton
                                onClick={onSidebarOpen}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                    </Grid>
                    <Grid item className={classes.flexGrow} align="center">
                        <Typography variant="h2" className={classes.logo}>
                            Device Control
                        </Typography>
                    </Grid>
                    <Grid item className={classes.flexGrow} align="right">
                        <Hidden mdDown>
                            <IconButton
                                onClick={handleSignOut}
                                className={classes.signOutButton}
                                color="inherit"
                            >
                                <InputIcon />
                            </IconButton>
                        </Hidden>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
