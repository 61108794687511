import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Grid, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
const useStyles = makeStyles(theme => ({
  iconDeleteList: {
    color: '#b71c1c',
    backgroundClor: 'transparent',
    marginTop: theme.spacing(2),
  },
  icon: {
    margin: theme.spacing(1),
    float: 'left',
  },
  margin: {
    marginBottom: theme.spacing(3),
  },
  marginInput: {
    marginBottom: theme.spacing(3),
  },
}));

export default props => {
  const { formAction, handleChange, formState } = props;

  const classes = useStyles();
  const [serialNumber, setSerialNumber] = useState('');
  const [rows, setRows] = useState(formState && (formState['device'] || []));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (formState.length === 0)
      setRows([])
  }, [formState]);
  function createData(serialNumber) {
    return { serialNumber, status: 0 };
  }

  function add(e) {
    const itensCopy = Array.from(rows);
    const data = createData(serialNumber)
    itensCopy.push(data);
    setRows(itensCopy);
    setSerialNumber('');
    handleChange('device', itensCopy)
  }

  function update(input, value, index) {
    const itensCopy = Array.from(rows);
    itensCopy.splice(index, 1, { ...itensCopy[index], serialNumber: value });
    setRows(itensCopy);
    setOpen(false);
    handleChange('device', itensCopy)
  }

  function deleteContact(index) {
    const itensCopy = Array.from(rows);
    itensCopy.splice(index, 1);
    setRows(itensCopy);
    setOpen(false)
    handleChange('device', itensCopy)
  }

  return (
    <Grid container
      spacing={1}
      alignItems="center"
      justify="flex-start"
    >
      <Grid item xs={10} sm={10} lg={10}>
        <Typography variant="h5" component="h5">
          Dispositivos
        </Typography>
      </Grid>
      <Grid item xs={3} sm={4} lg={4}>
        <Fab
          variant="extended"
          size="medium"
          aria-label="add"
          color="primary"
          onClick={e => add(e)}
          className={classes.icon}
          disabled={formAction === 'view'}
        >
          <AddIcon />
          Adicionar
        </Fab>
      </Grid>
      {rows.map((row, index) => (
        <>
          <Grid item xs={10} sm={9} lg={9} className={classes.marginInput}>
            <TextField
              className={classes.input}
              label="Número Serial"
              fullWidth
              onChange={e => update('serialNumber', e.target.value, index)}
              defaultValue={row.serialNumber || ''}
              disabled={formAction === 'view'}
            />
          </Grid>
          <Grid item xs={2} sm={3} lg={3} className={classes.margin}>
            <IconButton
              aria-label="delete"
              className={classes.iconDeleteList}
              disabled={formAction === 'view'}
              onClick={() => deleteContact(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </>
      ))}
    </Grid>
  );
}
