export function getStatusCallback(url, type) {
    var urlStatusTrue = [];
    var iterateUrlTrue = Object.entries(url).filter(x => { 
        if(x[1] === true){ 
            urlStatusTrue.push(x[0])
        } 
    });
    if(type === "ContingencyStatus"){
        var typeMessageContingency = Object.entries(url).length === 0?'Sem Url cadastrada': urlStatusTrue.length ===  Object.entries(url).length ? 'Contigência' : urlStatusTrue.length === 0 ? "Tempo real" : "Contigência parcial";
        return typeMessageContingency
    }
    else{
        var typeMessageStatus = Object.entries(url).length === 0?'Sem Url cadastrada':urlStatusTrue.length === Object.entries(url).length ? 'Enviado' : urlStatusTrue.length === 0 ? "Não enviado" : "Envio incompleto"
        return typeMessageStatus;
    }
}

export function descendingComparatorCallback(a, b, orderBy) {
    if (orderBy === 'time') {
        if (new Date(b?.deviceCallback.time).getTime() < new Date(a?.deviceCallback.time).getTime()) {
            return -1;
        }
        if (new Date(b?.deviceCallback.time).getTime() > new Date(a?.deviceCallback.time).getTime()) {
            return 1;
        }
    }
    else if(orderBy === "personId" || orderBy === "deviceKey") {
        if (b?.deviceCallback[orderBy] < a?.deviceCallback[orderBy]) {
            return -1;
        }
        if (b?.deviceCallback[orderBy] > a?.deviceCallback[orderBy]) {
            return 1;
        }
    }
    else if(orderBy === "contingency") {
        var aElement = getStatusCallback(a?.deviceCallbackMetadata?.webhookUrlContingencyStatus, "ContingencyStatus");
        var bElement = getStatusCallback(b?.deviceCallbackMetadata?.webhookUrlContingencyStatus, "ContingencyStatus");
     
        if (bElement < aElement) {
            return -1;
        }
        if (bElement > aElement) {
            return 1;
        }
    }
    else if(orderBy === "callbackAsReceivedByTheClient" ) {
        var aElement = getStatusCallback(a?.deviceCallbackMetadata?.webhookUrlStatus, "Status");
        var bElement = getStatusCallback(b?.deviceCallbackMetadata?.webhookUrlStatus, "Status");
     
        if (bElement < aElement) {
            return -1;
        }
        if (bElement > aElement) {
            return 1;
        }
    }
    else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
    }
    return 0;
}

export function getComparatorCallback(order, orderBy) {
    if (order === 'desc') {
        return (a, b) => descendingComparatorCallback(a, b, orderBy);
    }
    if (order === 'asc') {
        return (a, b) => -descendingComparatorCallback(a, b, orderBy);
    }
    else
        return (a, b) => a
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    if (order === 'desc') {
        return (a, b) => descendingComparator(a, b, orderBy);
    }
    if (order === 'asc') {
        return (a, b) => -descendingComparator(a, b, orderBy);
    }
    else
        return (a, b) => a
}

