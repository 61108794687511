import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Box,
    Button,
    Drawer,
    Grid,
    IconButton,
    Card,
    CardContent,
    List,
    ListItem,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isAdmin } from '../../context/AppContext';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import validate from 'validate.js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { RealTimeResultCommandsContext } from '../../context/RealTimeContext';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        textAlign: 'left',
        height: 'auto',
        padding: theme.spacing(0),
        justifyContent: 'space-evenly',
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'space-between',
            // flexDirection: 'column'
        }
    },
    dialog: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    closeIcon: {
        float: 'right',
        backgroundColor: 'transparent',
        color: '#FFF',
        display: 'flex',
        [theme.breakpoints.only('xs')]:{
            color: '#0000008a',
            marginRight: theme.spacing(3)
        }
    },
    dialogPaper: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'hidden',
        width: 'auto',
        minWidth: '20%',
        [theme.breakpoints.only('xs')]: {
            minWidth: '80vw'
        },
        [theme.breakpoints.only('sm')]: {
            minWidth: '60vw'
        }
    },
    gridCloseIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start'
    },
    listMobile: {
        textAlign: 'left',
    },
    cardMobile: {
        textAlign: 'left',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
        // borderRadius: '8px',
        margin: theme.spacing(1, 0),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(2, 0),
        },
    },
    colorSuccess: {
        color: '#81BC00',
        fontWeight: 700
    },
    colorError: {
        color: '#F5222D',
        fontWeight: 700
    },
    borderSuccess: {
        borderLeft: '3px solid #81BC00'
    },
    borderError: {
        borderLeft: '2px solid #F5222D'
    },
    borderPending: {
        borderLeft: '2px solid #ffca28'
    },
    borderProcessing: {
        borderLeft: '2px solid #29b6f6'
    },
    iconStatusSuccess: {
        color: '#81BC00',
        fontSize: theme.spacing(4),
        marginRight: theme.spacing(2)
    },
    iconStatusError: {
        color: '#F5222D',
        fontSize: theme.spacing(4),
        marginRight: theme.spacing(2)
    },
    iconStatusPending: {
        color: '#ffca28',
        fontSize: theme.spacing(4),
        marginRight: theme.spacing(2)
    },
    iconStatusProcessing: {
        color: '#29b6f6',
        fontSize: theme.spacing(4),
        marginRight: theme.spacing(2)
    },
    textSubtitle: {
        marginTop: theme.spacing(1),
        color: '#8f9397',
        flexWrap: 'wrap',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(0),
            fontSize: '14px',
        },
    },
    gridItemScroll: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    gridItem: {
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    boxButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'space-between'
        },
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: '100vw',
        // flexWrap: 'wrap'
    },
    boxData: {
        display: 'flex',
        flexDirection: 'column'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    listItemEmpty: {
        justifyContent: 'center'
    },
    marginButton: {
        margin: theme.spacing(2)
    },
    code: {
        overflow: 'auto',
      
        [theme.breakpoints.up('sm')]: {
            maxWidth: '100%',
        },
    },
    gutters:{
        [theme.breakpoints.only('xs')]: {
            paddingLeft: theme.spacing(1)
        },
    },
    collapse:{
        maxWidth: '100vw',
        overflow: 'auto'
    }
}))

export default function StatusCommand({ dialogStatusCommand, setDialogStatusCommand }) {
    //GLOBAL VARIABLES
    const { getResultCommands } = useContext(RealTimeResultCommandsContext);

    //STYLE
    const theme = useTheme();
    const classes = useStyles(theme);

    //STATE
    const [expanded, setExpanded] = useState({ 0: false });
    const [clearStatus, setClearStatus] = useState(false);

    //LOCAL VARIABLES
    let resultCommands = getResultCommands();
    const isMobile = useMediaQuery(theme => theme.breakpoints.only('xs'));

    const handleExpandClick = (index) => {
        setExpanded({ [index]: !expanded[index] });
    };

    const clearCommands = () => {
        sessionStorage.removeItem('statusCommands');
        setClearStatus(true);
        resultCommands = getResultCommands();
    }

    const isSuccess = (type, data) => {
        if (type === 'enrollUser') {
            return validate.isEmpty(data.failedEnrolls)
        }
        else if (type === 'listUsers') {
            return data.failureReasons && validate.isEmpty(data.failureReasons[0])
        }
        else if (type === 'updateUser') {
            return data.failureReasons && validate.isEmpty(data.failureReasons[0]) && data.successFace && data.successName
        }
        else if (type === 'deleteUser') {
            return data.results && data.results[0] && data.results[0].failedDeletions.length < 2 && validate.isEmpty(data.results[0].failedDeletions[0])
        }

        return false
    }

    return (
        <Drawer
            open={dialogStatusCommand}
            anchor='left'
            className={classes.dialog}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <Grid container component="main" direction="row" className={classes.root}>
                <Grid item xs={12} sm={11} md={11} lg={10} className={classes.gridItem} >
                    <Box className={classes.boxButton}>
                        <Button
                            variant='contained'
                            color='primary'
                            endIcon={<DeleteOutlineIcon />}
                            onClick={() => clearCommands()}
                            className={classes.marginButton}
                        >
                            Limpar
                        </Button>
                        {
                            isMobile?<IconButton
                            aria-label="close"
                            className={classes.closeIcon}
                            onClick={() => {
                                setDialogStatusCommand(false);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>:""
                        }
                    </Box>
                    <PerfectScrollbar className={classes.gridItemScroll}>
                        <List component="div">
                            <ListItem className={clsx({
                                [classes.listItemEmpty]: resultCommands.length === 0
                            })}
                            classes={{gutters: classes.gutters}}
                            >
                                <Box className={classes.listMobile}>
                                    {resultCommands.length > 0 ?
                                        resultCommands.map((data, index) => (
                                            <Card
                                                className={clsx(classes.cardMobile, {
                                                    [classes.borderSuccess]: isSuccess(data.type, data.result),
                                                    [classes.borderError]: !isSuccess(data.type, data.result),
                                                    // [classes.borderProcessing]: data.status === 'send',
                                                    // [classes.borderPending]: data.status === 'waiting',
                                                })}
                                                key={index}
                                            >
                                                <CardContent className={classes.cardContent}>
                                                    {/* {
                                                    {
                                                        'send': <TimelapseIcon className={classes.iconStatusProcessing} />,
                                                        'waiting': <ScheduleIcon className={classes.iconStatusPending} />,
                                                    }[data.status]
                                                } */}
                                                    {

                                                        !isSuccess(data.type, data.result) ?
                                                            <HighlightOffIcon className={classes.iconStatusError} /> :
                                                            <CheckCircleOutlineIcon className={classes.iconStatusSuccess} />
                                                    }
                                                    <Box className={classes.boxData}>
                                                        <Typography component={"h5"} variant={"h5"} className={clsx({
                                                            [classes.colorSuccess]: isSuccess(data.type, data.result),
                                                            [classes.colorError]: !isSuccess(data.type, data.result),
                                                        })}>
                                                            {
                                                                !isSuccess(data.type, data.result) ?
                                                                    'Erro ao processar operação' :
                                                                    'Operação realizada com sucesso'
                                                            }
                                                        </Typography>
                                                        <Typography component={"p"} variant="body1" className={classes.textSubtitle}>
                                                            <strong>Operação:</strong>
                                                            {
                                                                {
                                                                    'enrollUser': ' Cadastrar Usuário',
                                                                    'listUsers': ' Listar Usuários',
                                                                    'updateUser': ' Atualizar Usuário',
                                                                    'deleteUser': ' Deletar Usuário',
                                                                }[data.type]
                                                            }
                                                        </Typography>
                                                        {isAdmin() ?
                                                            <Typography component={"p"} variant="body1" className={classes.textSubtitle}>
                                                                <strong>Cliente:</strong> {data?.clientEmail}
                                                            </Typography> : ""
                                                        }
                                                    </Box>
                                                    <IconButton
                                                        className={clsx(classes.expand, {
                                                            [classes.expandOpen]: expanded[index],
                                                        })}
                                                        onClick={() => handleExpandClick(index)}
                                                        aria-expanded={expanded}
                                                        aria-label="detalhes"
                                                    >
                                                        <ExpandMoreIcon />
                                                    </IconButton>
                                                </CardContent>
                                                <Collapse in={expanded[index]} timeout="auto" unmountOnExit className={classes.collapse}>
                                                    <CardContent className={classes.cardMobile}>
                                                        <pre className={classes.code}>{JSON.stringify(data.result, undefined, 2)}</pre>
                                                    </CardContent>
                                                </Collapse>
                                            </Card>
                                        )) :
                                        <Typography component={"p"} variant="body1" className={classes.textSubtitle}>
                                            Lista Vazia
                                        </Typography>
                                    }
                                </Box>
                            </ListItem>
                        </List>
                    </PerfectScrollbar>
                </Grid>
                {
                    !isMobile?
                    <Grid item xs={2} sm={1} md={1} lg={2} className={classes.gridCloseIcon}>
                    <IconButton
                        aria-label="close"
                        className={classes.closeIcon}
                        onClick={() => {
                            setDialogStatusCommand(false);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>:""
                }
               
            </Grid>
        </Drawer >
    );
}