import React, { createContext, useState } from 'react';

const getCountNotifications = () => {
    const count = JSON.parse(sessionStorage.getItem('countNotifications'));
    if (count !== null)
        return count;
    return 0
}

const updateCountNotifications = () => {
    var count = sessionStorage.getItem('countNotifications');
    if (count !== null) {
        count = parseInt(count) + 1
        sessionStorage.setItem('countNotifications', count);
    }
    else
        sessionStorage.setItem('countNotifications', 1);

}

const cleanCountNotifications = () => {
    sessionStorage.setItem('countNotifications', 0);
}

export const NotificationsDataContext = createContext();
export const NotificationsApiContext = createContext();

export const NotificationsProvider = ({ children }) => {

    const [countNotifications, setCountNotifications] = useState(getCountNotifications());

    function incrementNotificationCounter() {
        setCountNotifications(c => c + 1);
        updateCountNotifications();
    }

    function resetNotificationCounter() {
        cleanCountNotifications();
        setCountNotifications(0);
    }

    const dataContextValue = {
        countNotifications,
    };
    
    const apiContextValue = {
        setCountNotifications,
        incrementNotificationCounter,
        resetNotificationCounter,
    };

    // https://www.developerway.com/posts/react-re-renders-guide
    return (
        <NotificationsDataContext.Provider value={dataContextValue}>
            <NotificationsApiContext.Provider value={apiContextValue}>
                {children}
            </NotificationsApiContext.Provider>
        </NotificationsDataContext.Provider>
    );
};
