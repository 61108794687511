import React, { forwardRef, useContext, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import InputIcon from '@material-ui/icons/Input';
import { useHistory } from "react-router-dom";
import {
    Drawer,
    Hidden,
    List,
    ListItem,
    Typography,
    Button,
    Grid,
} from '@material-ui/core';
import {
    CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
    HighlightOff as HighlightOffIcon,
    Schedule as ScheduleIcon,
    History as HistoryIcon,
    Timelapse as TimelapseIcon,
    Repeat as RepeatIcon,
    FiberManualRecord as FiberManualRecordIcon,
} from '@material-ui/icons';
import AppContext, { isAdmin, isUserView } from '../../../../context/AppContext';
import { NotificationsApiContext, NotificationsDataContext } from '../../../../context/NotificationsContext';
import SidebarCssModule from './Sidebar.module.css';
import { StatusCommand } from '../../../../components';
import PeopleIcon from '@material-ui/icons/People';
import { RealTimeResultCommandsContext } from '../../../../context/RealTimeContext';

const useStyles = makeStyles(theme => ({
    drawer: {
        width: '50vw',
        border: 0,
        [theme.breakpoints.up('md')]: {
            height: '100%',
            width: '17.66667%',
            overflowX: 'hidden'
        },
        [theme.breakpoints.only('md')]: {
            height: '100%',
            width: '17.66667%',
            overflowX: 'hidden'
        },
        [theme.breakpoints.only('xs')]: {
            width: '65vw',
        }
    },
    root: {
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(2, 1),
    },
    nav: {
        marginBottom: theme.spacing(2)
    },
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: theme.spacing(2)
    },
    button: {
        color: '#4A4A4A',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium,
        '&:hover': {
            color: '#BBD400 '
        },
    },
    icon: {
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center'
    },
    page_title: {
        marginLeft: theme.spacing(2),
        fontSize: '13px'
    },
    page_title_hidden: {
        display: 'none'
    },
    titleButton: {
        marginLeft: theme.spacing(2),
        fontSize: '13px',
        textAlign: 'left'
    },
    active: {
        color: 'white',
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: '#76AB00',
        
        '& $img': {
            filter:  'brightness(0) invert(1)',
        },
        '& $icon': {
            color: 'white'
        },
        '&:hover': {
            color: 'white',
            backgroundColor: '#BBD400'
        },
    },
    headerSidebar: {
        height: '8vh',
        margin: theme.spacing(2),
        textAlign: 'left',
        maxWidth: '87%'
    },
    logoImg: {
        height: '5vh'
    },
    dividerSidebar: {
        backgroundColor: '#a3a9af',
        margin: theme.spacing(2)
    },
    successIcon: {
        color: '#9ccc65',
        fontSize: theme.spacing(4)
    },
    penddingIcon: {
        color: '#ffca28',
        fontSize: theme.spacing(4)
    },
    errorIcon: {
        color: '#f44336',
        fontSize: theme.spacing(4)
    },
    processingIcon: {
        color: '#29b6f6',
        fontSize: theme.spacing(4)
    },
    badge: {
        backgroundColor: '#76AB00',
        color: '#FFFFFF',
        padding: theme.spacing(0, 1),
        borderRadius: theme.spacing(1)
    },
    buttonCommandStatus: {
        display: 'flex',
        justifyContent: 'space-between',

    },
    containerIconTitle: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center'
    },
    imgIcon: {
        width: '24px'
    }
}));

const CustomRouterLink = forwardRef((props, ref) => {
    return (
        <div
            ref={ref}
            style={{ flexGrow: 1 }}
        >
            <RouterLink {...props} />
        </div>
    )
});

const Sidebar = props => {

    //CONTEXT
    const { contextRoutes, handleSignOut } = useContext(AppContext);
    const { countNotifications } = useContext(NotificationsDataContext);
    const { resetNotificationCounter } = useContext(NotificationsApiContext);
    const { getResultCommands } = useContext(RealTimeResultCommandsContext);
    
    let history = useHistory();
    const signOut = () => {
        history.push("/login");
        handleSignOut();
    }
    //PROPS
    const { cssVariables, isSidebarOpen, variant, closeSidebar, sidebarStatus, setSidebarStatus, ...rest } = props;

    //STYLE

    const classes = useStyles(cssVariables);

    const page_title_classes_group = [classes.page_title];
    const toggle_modules_names_icon_classes_group = [SidebarCssModule.toggle_modules_names_icon];

    if (sidebarStatus.is_shrinked) {
        page_title_classes_group.push(classes.page_title_hidden);
        toggle_modules_names_icon_classes_group.push(SidebarCssModule.toggle_modules_names_icon_inverse);
    }

    //GLOBAL VARIABLES

    const [dialogStatusCommand, setDialogStatusCommand] = useState(false);
    return (
        <>
            <Drawer
                anchor="left"
                classes={{ paper: classes.drawer }}
                onClose={closeSidebar}
                open={isSidebarOpen}
                variant={variant}
            >
                <Hidden smDown>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        className={classes.headerSidebar}
                    >
                        <Grid item lg={2}>
                            <img src="/logo_biomtech_splash_screen.png" alt="Logo Biomtech" className={classes.logoImg} />
                        </Grid>
                        <Grid item lg={8}>
                            <Typography variant="h4" className={classes.logo}>
                                Device Control
                            </Typography>
                        </Grid>
                    </Grid>
                </Hidden>
                <div {...rest} className={clsx(classes.root)} >
                    <List {...rest} className={classes.nav} >
                        {!isUserView() && <>
                            <ListItem
                                className={classes.item}
                                disableGutters
                            >
                                <Button
                                    className={classes.button}
                                    classes={{ label: classes.buttonCommandStatus }}
                                    onClick={() => { setDialogStatusCommand(true); resetNotificationCounter() }}
                                >
                                    <div className={classes.containerIconTitle}>
                                        <div className={classes.icon}>
                                            {
                                                {
                                                    'undefined': <HistoryIcon className={classes.iconCommands} />,
                                                    'waiting': <ScheduleIcon className={classes.penddingIcon} />,
                                                    'success': <CheckCircleOutlineOutlinedIcon className={classes.successIcon} />,
                                                    'error': <HighlightOffIcon className={classes.errorIcon} />,
                                                    'send': <TimelapseIcon className={classes.processingIcon} />,
                                                }[getResultCommands()[0]?.status]
                                            }
                                        </div>
                                        <span className={classes.titleButton}>
                                            Meus comandos
                                        </span>
                                    </div>
                                    {countNotifications > 0 ? <span className={classes.badge}>{countNotifications}</span> : ""}
                                </Button>
                            </ListItem>
                            <Divider className={classes.dividerSidebar} />
                        </>}
                        {contextRoutes.map((page, index) => {
                            let showPage = true;

                            if (page.hasUsersRestriction) {
                                showPage = false;

                                for (var i = 0; i < page.rolesWithAccess.length; i++) {
                                    if ((isAdmin() && page.rolesWithAccess[i] === "admin") || (!isAdmin() && !isUserView() && page.rolesWithAccess[i] === "client")) {
                                        showPage = true;
                                        break;
                                    }
                                }
                            }

                            if (showPage) {
                                return (
                                    <ListItem
                                        className={classes.item}
                                        disableGutters
                                        key={page.title}
                                    >
                                        <Button
                                            activeClassName={classes.active}
                                            className={classes.button}
                                            component={CustomRouterLink}
                                            to={page.path}
                                        >
                                            {page.isIcon ? <div className={classes.icon}>{page.icon}</div> : <img src={page.icon} className={classes.imgIcon} />}
                                            <span className={page_title_classes_group.join(' ')}>{page.title}</span>
                                        </Button>
                                    </ListItem>
                                );
                            }
                        })}
                        <Divider className={classes.dividerSidebar} />
                        <ListItem
                            className={classes.item}
                            disableGutters
                        >
                            <Button
                                className={classes.button}
                                onClick={signOut}
                            >
                                <div className={classes.icon}>
                                    <InputIcon />
                                </div>
                                <span className={page_title_classes_group.join(' ')}>Sair</span>
                            </Button>
                        </ListItem>
                    </List>
                </div>
            </Drawer>
            <StatusCommand dialogStatusCommand={dialogStatusCommand} setDialogStatusCommand={setDialogStatusCommand} />
        </>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
    closeSidebar: PropTypes.func,
    isSidebarOpen: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired
};

export default Sidebar;
