import React, { useState,useEffect } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import {Grid,Typography } from '@material-ui/core';
import Status from '../../components/Status';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import State from '../../components/State'
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import TableDevice from '../User/TableDevice';

const useStyles = makeStyles((theme) => ({
    rootMobile: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        backgroundColor: 'white',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
}));
  
const CardComponentMobile = props => {
    const classes = useStyles();
    const { companyKey, checked, setChecked,table_columns, data,isCollapse,elements,index,setElements  } = props;
    const [device, setDevice] = useState(null);
    const handleChange = (event) => {
        if(checked && checked['id'] === elements[index].id)
        setChecked(null);
      else
        setChecked(elements[index]);
    };
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        if(!expanded) 
        setDevice(elements[index].device)
      setExpanded(!expanded);
    };
    useEffect(() => {
        setDevice(elements[index].device)
      }, [elements]);
    return (
        <Grid container fullWidth>
            <Grid id="cardListMobile" item lg={12} sm={12} fullWidth>
                <Card  id="cardList" className={classes.rootMobile} fullWidth>
                    <CardContent>
                        <Grid  
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Grid item lg={1} sm={1} xs={2}>
                                <Checkbox
                                    className={classes.listText}
                                    color="primary"
                                    checked={checked && checked['id'] === elements[index].id?true:false}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    onChange={event => handleChange(event)}
                                />
                            </Grid>
                            <Grid item lg={1} sm={1} xs={10}>
                            {table_columns.map((column, index) => (
                                <Grid  
                                    container
                                    direction="column"
                                    justify="flex-start"
                                    alignItems="center"
                                    style={{  padding:'5%',  borderBottom:' 1px solid rgb(117 125 129)'}}
                                    key={index}
                                >
                                    <Grid item lg={4} sm={4} xs={4}>
                                        <Typography className={classes.listText}>
                                            {column.label} :
                                        </Typography >
                                    </Grid>
                                    {column.name==='status'?
                                        <Grid item lg={3} sm={3} xs={8}>
                                            <Status selected={data[index]} />
                                        </Grid>
                                        :
                                        column.name==='state'?
                                        <Grid item lg={3} sm={3} xs={8}>
                                            <State selected={data[index]} />
                                        </Grid>:
                                        <Grid item lg={4} sm={4} xs={8}>
                                            <Typography className={classes.listText}>
                                                {data[index]}
                                            </Typography >
                                        </Grid>
                                    }
                                </Grid>
                            ))}
                            </Grid>
                            {isCollapse?
                                <Grid item xs={1}>
                                    <IconButton
                                        className={clsx(classes.expand, {
                                            [classes.expandOpen]: expanded,
                                        })}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </Grid>:''}
                        </Grid>
                    </CardContent>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <TableDevice elements={elements} setElements={setElements} index={index} data={device}/>
                        </CardContent>
                    </Collapse>
                </Card>
            </Grid>
        </Grid>
    );
  }
  export default CardComponentMobile