import React from 'react';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  buttons:{
    color: 'white',
    backgroundColor:'transparent',
    paddingTop:  theme.spacing(2),
    paddingBottom:  theme.spacing(2),
    border: 'solid 2px transparent',
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #b63131, #a74e4e)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    '&:hover':{
      boxShadow: '2px 1000px 1px #272727 inset',
      color: '#bd8f8f'
    }
  },
  buttonCancel:{
    color: '#272727',
    paddingTop:  theme.spacing(2),
    paddingBottom:  theme.spacing(2),
    boxShadow: '0 0 4px 0 rgba(157, 96, 212, 0.5)',
    border: 'solid 2px transparent',
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #ffffff, #ffffff)',
    backgroundColor:'white',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    '&:hover':{
      boxShadow: '2px 1000px 1px #272727 inset',
      color: 'white'
    }
  },
  inputText:{
    marginBottom: theme.spacing(3)
  }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    paddingTop:  theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalFormDefault = props => {
    const {modalForm, setModalForm, setElements, elements, index,setSerialNumber,serialNumber} = props
   
    let titleModal = 'Associar Dispositivo'
    if(modalForm.mode === 'edit')
        titleModal = 'Editar Dispositivo'
    const classes = useStyles();

    const handleClose = () => {
        setModalForm({open:false});
    };
   
   const handleChange = async value => {
      if(value !== null && value !== '')
        setSerialNumber(value)
   }
  const handleSave = async event => {
    if(modalForm.mode === 'edit'){
        const itensCopy = Array.from(elements);
        const itensCopyDevices = Array.from(elements[index].device);
        itensCopyDevices.splice(modalForm.index, 1, {...itensCopyDevices[modalForm.index], serialNumber:serialNumber});
        itensCopy.splice(index, 1, {...itensCopy[index],device:itensCopyDevices})
        handleClose();
        setElements(itensCopy);
    }
    else{
      const itensCopy = Array.from(elements);
      const itensCopyDevices = Array.from(elements[index].device);
      itensCopyDevices.push({serialNumber, status:0})
      itensCopy.splice(index, 1, {...itensCopy[index],device:itensCopyDevices})
      handleClose()
      setElements(itensCopy);
    }
  };
 
  return (
    <div>
      <Dialog      
        fullWidth={true}
        maxWidth='sm'
        onClose={handleClose} 
        aria-labelledby="customized-dialog-title" 
        open={modalForm.open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {titleModal}
        </DialogTitle>
        <DialogContent dividers>
          <form  noValidate autoComplete="off">
            <TextField id="standard-basic" 
                label='Número Serial ' 
                name='serialNumber'
                className={classes.inputText} 
                onChange={event => handleChange(event.target.value)}
                value={serialNumber}
                fullWidth
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Fab variant="extended"  onClick={handleClose} className={classes.buttonCancel}>
            Cancelar
          </Fab>
          <Fab variant="extended" className={classes.buttons} onClick={event => handleSave(event)}>
            Salvar
          </Fab>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default ModalFormDefault