import React, { createContext, useState } from 'react';

//LOGIN FUNCTIONS
export const saveToken = (value) => sessionStorage.setItem('device_control_token', JSON.stringify(value));

export const saveLogin = (value) => {
    sessionStorage.setItem('login', JSON.stringify(value));
    sessionStorage.setItem('userInfo', JSON.stringify(value));
}
export const saveUserInfo = (value) => sessionStorage.setItem('userInfo', JSON.stringify(value));

export const getUserInfo = () => {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    return userInfo;
}
export const getToken = () => {
    const token = JSON.parse(sessionStorage.getItem('device_control_token'));
    return token;
}

export const isAuthenticated = () => {
    var token = getToken();
    if (token)
        return true
    else
        return false
}

export const isAdmin = () => {
    if(isAuthenticated()){
        var token = sessionStorage.getItem('device_control_token');
        var data = JSON.parse(atob(token?.split('.')[1])).data.client;

        if (data?.ClientType === "ADMIN")
            return true
    }
    return false
}

export const isUserView = () => {
    if(isAuthenticated()){
        var token = sessionStorage.getItem('device_control_token');
        var data = JSON.parse(atob(token?.split('.')[1])).data.client;

        if (data?.ClientType === "READ_ONLY_SUB_CLIENT" || data?.ClientType === "READ_ONLY_SUB_ADMIN")
            return true
    }
    return false
}

//REACT APP CONTEXT
const AppContext = createContext();

export const AppProvider = props => {
    const [contextRoutes, setContextRoutes] = useState([]);
    const [modalForm, setModalForm] = useState({ open: false, mode: 'add' });
    const [companyNameCreate, setCompanyNameCreate] = useState('');
    
    const [userToken, setUserToken] = useState(getToken());

    const handleSignOut = () => {
        sessionStorage.clear();
        setContextRoutes([]);
        setUserToken("");
    };

    const app_context = {
        contextRoutes,
        setContextRoutes,
        
        modalForm,
        setModalForm,
        
        companyNameCreate,
        setCompanyNameCreate,
        
        handleSignOut,
        
        userToken,
        setUserToken,
        userLoggedIn: !!userToken,
    };

    return (
        <AppContext.Provider value={app_context}>
            {props.children}
        </AppContext.Provider>
    );
};

export default AppContext;
