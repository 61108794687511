import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, IconButton, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { State, BootstrapTooltip } from '../../../../components';
import AppContext, { isUserView } from '../../../../context/AppContext';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '0',
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        backgroundColor: 'transparent',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    cardBody: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        margin: theme.spacing(2, 0),
        boxShadow: ' 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius: '8px',
    },
    cardBodyEmpty: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        margin: theme.spacing(2, 0),
        textAlign: 'center',
        padding: theme.spacing(3)
    },
    rowCard: {
        padding: '5%',
        borderBottom: ' 1px solid rgb(117 125 129)',
        [theme.breakpoints.only('sm')]: {
            padding: '2%',
        }
    },
    deleteIcon: {
        color: '#db1414'
    }
}));

const TableMobile = ({ devices, history, requestUsers, setDeviceDetailsModal, deleteDevice, openEditDevice }) => {

    const classes = useStyles();

    return (
        <Card id="cardList" className={classes.root}>
            {devices.length > 0 ?
                devices.map((device, index) => (
                    <CardContent key={index} className={classes.cardBody}
                        onClick={() => {
                            // requestUsers(device.deviceKey);
                            setDeviceDetailsModal({ open: true, device: device })
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            className={classes.rowCard}

                        >
                            <Grid item lg={4} sm={4} xs={5}>
                                <Typography >
                                    Status
                                </Typography >
                            </Grid>
                            <Grid item lg={4} sm={4} xs={7}>
                                <State selected={device.status} />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            className={classes.rowCard}

                        >
                            <Grid item lg={4} sm={4} xs={5}>
                                <Typography >
                                    Nome
                                </Typography >
                            </Grid>
                            <Grid item lg={4} sm={4} xs={7}>
                                <Typography variant='h5'>
                                    {device.deviceName}
                                </Typography >
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            className={classes.rowCard}
                        >
                            <Grid item lg={4} sm={4} xs={5}>
                                <Typography >
                                    Número Serial
                                </Typography >
                            </Grid>
                            <Grid item lg={4} sm={4} xs={7}>
                                <Typography variant='h5'>
                                    {device.deviceKey}
                                </Typography >
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            className={classes.rowCard}
                        >
                            <Grid item lg={4} sm={4} xs={5}>
                                <Typography>
                                    Nº Pessoas
                                </Typography >
                            </Grid>
                            <Grid item lg={4} sm={4} xs={7}>
                                <Typography variant='h5'>
                                    {device.lastDeviceInfo.personCount}
                                </Typography >
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            className={classes.rowCard}
                        >
                            <Grid item lg={4} sm={4} xs={5}>
                                <Typography>
                                    Nº Faces
                                </Typography >
                            </Grid>
                            <Grid item lg={4} sm={4} xs={7}>
                                <Typography variant='h5'>
                                    {device.lastDeviceInfo.faceCount}
                                </Typography >
                            </Grid>
                        </Grid>
                        {!isUserView() && <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            className={classes.rowCard}
                        >
                            <Grid item lg={4} sm={4} xs={5}>
                                <Typography>
                                    Ação
                                </Typography >
                            </Grid>
                            <Grid item lg={4} sm={4} xs={7}>
                                <BootstrapTooltip title="Editar" >
                                    <IconButton onClick={e => openEditDevice(e, device)} >
                                        <EditIcon className={classes.editIcon} />
                                    </IconButton>
                                </BootstrapTooltip>
                                <BootstrapTooltip title="Remover" >
                                    <IconButton onClick={e => deleteDevice(e, device.deviceKey, device.deviceName)} >
                                        <DeleteForeverIcon className={classes.deleteIcon} />
                                    </IconButton>
                                </BootstrapTooltip>
                            </Grid>
                        </Grid>}
                    </CardContent>
                )) :
                <CardContent className={classes.cardBodyEmpty}>
                    <Typography>
                        Lista vazia
                    </Typography >
                </CardContent>
            }
        </Card>
    );
}
export default TableMobile