import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import {Grid,TextField} from '@material-ui/core';
import pt from "date-fns/locale/pt";
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDateTimePicker} from '@material-ui/pickers';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: theme.spacing(4),
  },
  inputEdit:{
      display:'inline',
      marginTop: theme.spacing(5)
  },
  autocomplete: {
    marginBotton: theme.spacing(2)
  },
  margin: {
    marginBottom: theme.spacing(3),
  },
  content: {
    flex: '1 0 auto',
  },
  search:{
      marginLeft: theme.spacing(5),
      '&:hover':{
        backgroundColor:' rgb(199 67 67)'
      }
  }
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#A11212',
  },
  tooltip: {
    backgroundColor: '#A11212',
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const ReportFilter = props => {
//PROPS
const { setOpenReport, openReport, setResultFilter} = props;
  const classes = useStyles();
  
 
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD h:mm'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD h:mm'));
  const [filterSelects, setFilterSelects] = useState();
  const [elementsSelects, setElementsSelects] = useState({
    has_requested:false,
    names: [],
    environment: []
  });
  
  const get_selects = async () => {
    const request_elements = {code: '3000', result: {Names:[
      {Name: "3566003566003566"},
      {Name: "2266500065650000"},
      {Name: "3566003566003564"}],
      Environment: [
        {Name: "Tom Tallis"},
        {Name: "Rich Harris"},
        {Name: "Moby Dixon"}
      ]
    }
    }
    if(request_elements.code === 3000){
      setElementsSelects({has_requested: true, names: request_elements.result.Names, environment:request_elements.result.Environment })
    }
  }
  const handleFilter = (name, value) => {
    setFilterSelects(filterSelects => ({
        ...filterSelects,
        [name]: value
       
      }));
  };

   
  const search = async () => {
      const request_elements = {code: '3000', result: [
        {
          serialNumber:'0362656565',
          action: "teste",
          date:'2020-08-15 15:23'
      },
      {
          serialNumber:'0362656565',
          action: "teste",
          date:'2020-08-15 16:41'
      },
      {
          serialNumber:'0362656565',
          action: "teste",
          date:'2020-09-15 09:01'
      },
      ]}
      if(request_elements.code === 3000)
        setResultFilter(request_elements.result)
      
      setOpenReport(true)
  }
  

  function formatDate(name, value){
    var dateConcat = value
    if(value !== 'Invalid Date' && value !== null){
        dateConcat = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate() + ' ' + value.getHours() + ':' + value.getMinutes();
        if(name === "startDate")
          setStartDate(value)
        else
          setEndDate(value)
       
    }
    handleFilter(name, dateConcat)
}

  useEffect(() => {
    if(elementsSelects.has_requested === false)
      get_selects()
  })

  return (
      <>
        <Card id="CardReport" className={classes.root} > 
            <CardContent className={classes.content}>
                <Grid
                  container
                  direction="row"
                >
                    <Grid item lg={3} sm={6} xs={10} className={classes.margin}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={elementsSelects.names}
                            className={classes.autocomplete}
                            style={{ width: 200 }}
                            getOptionLabel={(option) => option.Name}
                            renderInput={(params) => <TextField {...params} label="Dispositivo"  />}
                            onChange = {(event,value) => handleFilter("Name", value && (value.Name || null))}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6} xs={10} className={classes.margin}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={elementsSelects.environment}
                            style={{ width: 200 }}
                            getOptionLabel={(option) => option.Name}
                            renderInput={(params) => <TextField {...params} label="Empresa"  />}
                            onChange = {(event,value) => handleFilter("Environment", value && (value.Name || null))}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6} xs={10} className={classes.margin}>
                    <MuiPickersUtilsProvider   utils={DateFnsUtils} locale={pt}>
                        <InputLabel id={'label_for_startDate'}>Data e Hora Inicial</InputLabel>
                        <KeyboardDateTimePicker
                            labelId={'label_for_startDate'}
                            name='startDate'
                            maxDate={endDate}
                            className = {classes.inputEdit}
                            value={ startDate || null}
                            placeholder="dd/mm/aaaa"
                            invalidDateMessage="Formato de data inválido"
                            maxDateMessage="A data não deve ser posterior à data máxima"
                            maskChar="_"
                            ampm={false}
                            onChange = {(value) => formatDate("startDate", value)}
                            format="dd/MM/yyyy hh:mm"
                            cancelLabel="Cancelar"
                        />    
                    </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={2} sm={4} xs={10} className={classes.margin}> 
                        <MuiPickersUtilsProvider   utils={DateFnsUtils} locale={pt}>
                            <InputLabel id={'label_for_startDate'}>Data e Hora Final</InputLabel>
                            <KeyboardDateTimePicker
                                labelId={'label_for_startDate'}
                                name='endDate'
                                minDate={startDate}
                                className = {classes.inputEdit}
                                value={ endDate || null}
                                placeholder="dd/mm/aaaa"
                                ampm={false}
                                invalidDateMessage="Formato de data inválido"
                                maxDateMessage="A data não deve ser posterior à data máxima"
                                maskChar="_"
                                onChange = {(value) => formatDate("endDate", value)}
                                format="dd/MM/yyyy hh:mm"
                                cancelLabel="Cancelar"
                            />    
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={1} sm={2} xs={10} className={classes.margin}> 
                      <BootstrapTooltip title="Pesquisar">
                          <Fab size="small"
                              color="primary"
                              className={classes.search}
                              onClick={search}
                              aria-label="search"
                              fontSize={'small'}
                          >
                              <SearchIcon />
                          </Fab>
                        </BootstrapTooltip>
                    </Grid>
                </Grid>    
            </CardContent>
          
        </Card>
        </>
    );
}
export default ReportFilter;