import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Chip,
    Dialog,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import ptLocale from "date-fns/locale/pt-BR";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import ClearIcon from '@material-ui/icons/Clear';
import { validate } from 'validate.js';

const useStyles = makeStyles((theme) => ({
    button: {
        float: 'right',
        margin: theme.spacing(2, 0),
        borderRadius: theme.spacing(2),
        width: '100%',
        [theme.breakpoints.down("sm")]: {
            minWidth: '60vw'
        }
    },
    formControl: {
        margin: theme.spacing(1, 0)
    },
    containerHeader: {
        display: ' inline-flex',
        justifyContent: 'space-between'
    },
    title: {
        fontWeight: 700,
        margin: theme.spacing(2),
    },
    finalDate: {
        width: '100%',
        marginTop: '2px'
    },
    itemAutocomplete: {
        marginLeft: theme.spacing(2)
    },
    buttonCancel: {
        color: '#8A8A8D',
        textDecorationLine: 'underline',
        width: '100%'
    },
    containerButtons: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column-reverse',
            alignContent: "center",
        }
    },
    iconButtonSelect: {
        marginRight: theme.spacing(1),
    },
    iconSelect: {
        fontSize: '18px'
    }
}));

var initialValues = {
    initialDate: '',
    finalDate: '',
    user: "",
    devices: [],
    status: "",
}

export default function FilterCallback({ history, openModalFilter, getCallback, setOpenModalFilter, handleFilter, devices, cleanFilter, changeFilterText, data, formState, setFormState, dataUpdate }) {
    //STYLE
    const classes = useStyles();

    var isDeviceCallback = (history.location.pathname).split("/").length > 2;

    //STATE
    const [selectedDevice, setSelectedDevice] = React.useState(devices);
    const [errorDate, setErrorDate] = React.useState([]);
    const [temporaryFormFilter, setTemporaryFormFilter] = React.useState({...formState, initialDate: validate.isEmpty(formState.initialDate)?new Date():formState.initialDate, finalDate: validate.isEmpty(formState.finalDate)?new Date():formState.finalDate })
    
    const closeModalFilter = () => {
        setOpenModalFilter(false);
        cleanForm();
    }

    useEffect(() => {
        if (selectedDevice?.length < 2 && hasObject(selectedDevice, { deviceKey: 0, deviceName: "Todos os dispositivos" })) {
            setSelectedDevice(devices)
        }
    }, [devices])

    useEffect(() => {
        cleanForm();
    }, [cleanFilter])

    useEffect(() => {
        handleFilter(formState);
    }, [changeFilterText])

    useEffect(() => {
        if(JSON.stringify(formState) !== JSON.stringify(initialValues)){
            handleFilter(formState);
        }
    }, [dataUpdate])

    const handleChange = (name, value) => {
        setTemporaryFormFilter(temporaryFormFilter => ({
            ...temporaryFormFilter,
            [name]: value
        }))
    }

    const hasObject = (array, object) => {
        const results = array.filter((id1) => object.deviceKey === id1.deviceKey);
      
        return results.length > 0
    }

    const handleDeleteTag = index => {
        var arrayCopy = Array.from(temporaryFormFilter.devices);
        arrayCopy.splice(index, 1);
        handleChange("devices", arrayCopy);
    }

    const cleanForm = () => {
        setFormState({ initialDate: "", finalDate: "", user: "", devices: [], status: "" });
        setTemporaryFormFilter({ initialDate: new Date(), finalDate: new Date(), user: "", devices: [], status: "" });
        setSelectedDevice([]);
        handleFilter({ initialDate: "", finalDate: "", user: "", devices: [], status: "" }, true)
        getCallback({ initialDate: "", finalDate: "", user: "", devices: [], status: "" })
    }

    const renderGroup = (params) => [
        <FormControlLabel
            control={
                <Checkbox
                    name="clientElementCheckAll"
                    color="primary"
                    checked={temporaryFormFilter.devices.length === devices.length}
                />
            }
            label={params.group}
            className={clsx(classes.allClientsCheckbox, {

            })}
            onChange={(e, check) => { check ? handleChange("devices", devices) : handleChange("devices", []) }}
        />,
        params.children,
    ];

    const handleChangeFilter = () => {
        setFormState(temporaryFormFilter);
        let newArrayFilter = temporaryFormFilter;

        if(temporaryFormFilter.devices.length === devices.length){
            newArrayFilter = {
                ...temporaryFormFilter,
                devices: []
            }
        }

        getCallback(newArrayFilter);
            
        setOpenModalFilter(false);
    }

    return (
        <Dialog onClose={closeModalFilter} open={openModalFilter} fullWidth>
            <Grid container spacing={0}>
                <Grid item xs={12} className={classes.containerHeader} >
                    <Typography variant="body1" className={classes.title}>
                        Filtrar Callbacks
                    </Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={() => { closeModalFilter(); }}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <DialogContent>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                            <Typography variant="body2">
                                Data inicial
                            </Typography>
                            <KeyboardDatePicker
                                margin="normal"
                                id="initialDateFilter"
                                format="dd/MM/yyyy"
                                value={temporaryFormFilter.initialDate}
                                onChange={e => handleChange("initialDate", e)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                cancelLabel="cancelar"
                                variant="outlined"
                                inputVariant="outlined"
                                className={classes.finalDate}
                                maxDate={new Date(formState.finalDate)}
                                disableFuture
                                invalidDateMessage="Data inválida"
                                maxDateMessage="A data não pode ser maior que a data atual ou final"
                                onError={(errorInput, value) => setErrorDate(errorInput)}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Typography variant="body2">
                                Data final
                            </Typography>
                            <KeyboardDatePicker
                                margin="normal"
                                id="finalDateFilter"
                                format="dd/MM/yyyy"
                                value={temporaryFormFilter.finalDate }
                                onChange={e => handleChange("finalDate", e)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                cancelLabel="cancelar"
                                inputVariant="outlined"
                                className={classes.finalDate}
                                minDate={new Date(temporaryFormFilter.initialDate)}
                                disableFuture
                                invalidDateMessage="Data inválida"
                                minDateMessage="A data não pode ser menor que a data inicial"
                                maxDateMessage="A data não pode ser maior que a data atual"
                                onError={(errorInput, value) => setErrorDate(errorInput)}
                            />
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
                <FormControl fullWidth className={classes.formControl}>
                    <Typography variant="body2">
                        Usuário
                    </Typography>
                    <TextField
                        id="userInputFilter"
                        variant="outlined"
                        name="user"
                        value={temporaryFormFilter.user}
                        onChange={e => handleChange("user", e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{!validate.isEmpty(temporaryFormFilter.user) && <IconButton onClick={() => handleChange("user", "")}><ClearIcon /></IconButton>}</InputAdornment>,
                        }}
                    />
                </FormControl>
                {!isDeviceCallback && <FormControl fullWidth className={classes.formControl}>
                    <Typography variant="body2">
                        Dispositivo
                    </Typography>
                    <Autocomplete
                        multiple
                        id="devicesFilterInput"
                        options={devices}
                        limitTags={2}
                        disableCloseOnSelect
                        value={temporaryFormFilter.devices}
                        getOptionLabel={(option) => option.deviceName}
                        className={classes.autocompleteInput}
                        classes={{ inputRoot: classes.autocompleteInput }}
                        groupBy={params => "Todos os dispositivos"}
                        renderGroup={renderGroup}
                        onChange={(event, newValue, t) => { handleChange("devices", newValue) }}
                        renderTags={(tagValue, getTagProps) => {
                            if (temporaryFormFilter.devices.length === devices.length) {
                                return <Chip label="Todos os dispositivos" onDelete={undefined} />
                            }
                            else
                                return temporaryFormFilter.devices.map((option, index) => (
                                    <Chip {...getTagProps({ index })} label={option.deviceName} onDelete={() => handleDeleteTag(index)} />
                                ));
                        }}
                        renderOption={(option) => (
                            <FormControlLabel
                                key={"key-" + option}
                                control={
                                    <Checkbox
                                        name="deviceElement"
                                        color="primary"
                                        checked={hasObject(temporaryFormFilter.devices, option) || temporaryFormFilter.devices.length === devices.length}
                                    />
                                }
                                label={option.deviceName}
                                className={clsx({
                                    [classes.itemAutocomplete]: option.deviceKey !== 0,
                                })}
                            />
                        )}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                        )}
                    />
                </FormControl>}
             
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <Typography variant="body2">
                        Status
                    </Typography>
                    <TextField
                        native
                        value={temporaryFormFilter.status}
                        onChange={e => handleChange("status", e.target.value)}
                        inputProps={{
                            name: 'status',
                            id: 'statusInputFilterCallback',
                        }}
                        SelectProps={{
                            displayEmpty: true,
                         //   endAdornment: !validate.isEmpty(temporaryFormFilter.status) && <IconButton className={classes.iconButtonSelect} onClick={() => handleChange("status", "")}><ClearIcon className={classes.iconSelect} /></IconButton>,
                        }}
                        select
                        variant="outlined"
                    >
                        <MenuItem aria-label="None" value="">Todos os status</MenuItem>
                        <MenuItem value="true">Enviado</MenuItem>
                        <MenuItem value="false">Não enviado</MenuItem>
                    </TextField>
                </FormControl>
                <Grid container alignItems="center" className={classes.containerButtons}>
                    <Grid item lg={6} xs={12}>
                        <Button variant="text" onClick={() => { closeModalFilter(); }} size="medium" className={classes.buttonCancel}>
                            cancelar
                        </Button>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Button variant="contained" onClick={() => {   handleChangeFilter() }} size="medium" color="primary" className={classes.button} disabled={errorDate.length > 0}>
                            aplicar filtros
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog >
    );
}
