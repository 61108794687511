import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(3)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    button: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderRadius: '99px',
        width: '15%'
    },
    boxState: {
        display: 'flex',
        alignItems: 'center'
    },
    stateElement: {
        marginRight: theme.spacing(1)
    },
    buttonCallback: {
        color: '#8A8A8D',
        textDecorationLine: "underline",
        textTransform: "capitalize"
    },
    iconTopic: {
        fontSize: '8px'
    },
    listIcon: {
        justifyContent: 'center',
        minWidth: '22px'
    },
    listItem: {
        paddingBottom: 0,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 0
        }
    },
    list: {
        padding: 0
    },
    sizeMobile: {
        [theme.breakpoints.down('sm')]: {
            minWidth: '90vw'
        }
    },
}));

const MuiDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <DialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h4">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});

const MuiDialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
}))(DialogContent);

const MuiDialogActions = withStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
}))(DialogActions);

export default function ClientDetails({ clientDetailsModal, setClientDetailsModal, history }) {
    //STYLE
    const classes = useStyles();
    var client = clientDetailsModal.client;

    const handleClose = () => {
        setClientDetailsModal({ open: false, client: {} });
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                onClose={handleClose}
                open={clientDetailsModal.open}
                classes={{ paper: classes.sizeMobile }}
            >
                <MuiDialogTitle id="dialog-title-client-details" onClose={handleClose}>
                    {client.name}
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    <Grid container className={classes.headerContainer} spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> Nome: </Typography> {client?.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> E-mail: </Typography> {client?.email}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12} className={classes.boxState}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> Tipo de usuário: </Typography> {
                                    {
                                        'ADMIN': "Administrador",
                                        'CLIENT': "Cliente",
                                        'READ_ONLY_SUB_CLIENT': "Visualizador",
                                        'READ_ONLY_SUB_ADMIN': "Visualizador",
                                    }[client?.clientType]
                                    }
                            </Typography>
                        </Grid>
                        {client?.clientType === "CLIENT" && <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> Url de callback: </Typography>  {client.urlCallback}                             
                            </Typography>
                        </Grid>}
                        {client?.clientType?.indexOf("READ_ONLY") !== -1 && <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Typography variant="subtitle1" color="textPrimary" component="p">
                                <Typography variant="subtitle1" color="textSecondary" component="span"> Clientes associados: </Typography>
                            </Typography>
                            <List className={classes.list}>
                                {client?.parents?.length > 0 && client?.parents?.map((parent, index) => (
                                    <ListItem key={`${index}-${parent}`} className={classes.listItem}>
                                        <ListItemIcon className={classes.listIcon}>
                                            <FiberManualRecordIcon className={classes.iconTopic}/>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={parent}
                                        />
                                    </ListItem>
                                ))}
                                </List>
                        </Grid>}
                    </Grid>
                </MuiDialogContent>
                <MuiDialogActions>
                    <Button id="ok-close-client-details" variant="contained" color="primary" onClick={handleClose} className={classes.button}>
                        OK
                    </Button>
                </MuiDialogActions>
            </Dialog>
        </div>
    );
}