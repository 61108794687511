import React, { useState } from 'react';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Typography
} from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';
import Timer from '../Timer';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    boxAlert: {
        backgroundColor: '#76AB00',
        margin: '8px',
        padding: '16px',
        borderRadius: '16px',
        position: 'fixed',
        top: 0,
        left: '50%',
        right: '-50%',
        maxWidth: 'max-content',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: '6',
        [theme.breakpoints.only('xs')]: {
            backgroundColor: '#FFFFFF',
            left: '30%',
            right: '-25%',
        }
    },
    iconBox: {
        color: '#FFFFFF',
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            color: '#8A8A8D'
        }
    },
    containerText: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: theme.spacing(1),
        alignItems: 'center',
        color: '#FFFFFF'
    },
    boxAlertFail: {
        backgroundColor: '#af3432',
        margin: '8px',
        padding: '16px',
        borderRadius: '16px',
        position: 'fixed',
        top: 0,
        left: '45%',
        right: '-50%',
        maxWidth: 'max-content',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: '6',
        [theme.breakpoints.only('xs')]: {
            backgroundColor: '#FFFFFF',
            left: '2%',
            right: '0',
        }
    },
    text:{
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            color: '#8A8A8D'
        }
    }
}))

export default function WaitingCommand({ openMessage, loadingCommand, setLoadingCommand, page }) {
    //STYLE
    const classes = useStyles();

    //STATES
    const [newMessage, setNewMessage] = useState(null);

    //VARIABLES
    const refTimer = React.useRef();
    const componentMounted = React.useRef(true);

    const timerCallbackFunc = timerFlag => {
        console.warn(
            'You can alert the user by letting him know that Timer is out.',
        );
        if (componentMounted.current){
        setNewMessage("O comando pode estar demorando mais que o esperado.");
        setTimeout(() => {
            setNewMessage(null);
            setLoadingCommand({ ...loadingCommand, [page]: {open: false, deviceId: null }});
        }, 60000)
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // (4) set it to false when we leave the page
        }
    }
    };

    return (
        <Slide direction="up" in={openMessage}>
            <Box
                className={clsx({
                    [classes.boxAlert]: newMessage === null,
                    [classes.boxAlertFail]: newMessage !== null,
                })}
            >
                {newMessage !== null ?
                    <Typography component="p" className={classes.text}>
                        {newMessage}
                    </Typography> :
                    <>
                        <Typography component="p" className={classes.text}>
                            Por favor, aguarde...
                        </Typography>
                        <div className={classes.containerText}>
                            <TimerIcon className={classes.iconBox} />
                            <Timer ref={refTimer} setNewMessage={setNewMessage} timerCallback={timerCallbackFunc} restart={openMessage} />
                        </div>
                    </>
                }
            </Box>
        </Slide>
    );
}
