import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    FormControl,
    InputLabel,
    MenuItem,
    TextField,
    Select
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    textField: {
        margin: '16px 8px 28px 10px',
        width: '95%',
        [theme.breakpoints.down('sm')]: {
            margin: '16px 8px 28px 16px',
            width: '90%',
        }
    },
    formControl: {
        margin: '16px 8px 28px 10px',
        width: '95%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
}));


export default function ComponentInput({ name, label, type, handleChange, validateElement, errors, formState, options }) {
    //STYLE
    const classes = useStyles();
    
    return (
        <FormControl className={classes.formControl}>
            <InputLabel >{label}</InputLabel>
            <Select
                inputProps={{
                    name: name,
                }}
                value={formState[name]}
                onChange={e => handleChange(name, e.target.value)}
                onBlur={e => validateElement(name)}
                error={errors[name]?.length > 0}
                helperText={errors[name]}
            >
                {
                    options.map((option, index) => (
                        <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
}
