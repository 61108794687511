import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery, Grid, Hidden } from '@material-ui/core';

import { Sidebar, Topbar } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    main_container: {
        height: '100vh',
        backgroundColor: '#f9f9f9'
    },
    container_content: {
        marginTop: '15vh',
        [theme.breakpoints.up('md')]: {
            marginLeft: '17.66667%',
            marginTop: 0
        },
        [theme.breakpoints.only('md')]: {
            marginLeft: '17.66667%',
            marginTop: 0
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '10vh',
        }
    }
}));

const Main = props => {

    //PROPS
    const { children } = props;

    //STATE
    const [sidebarStatus, setSidebarStatus] = useState({ user_has_opened: false, is_shrinked: false });

    //STYLE
    const css_variables = {
        topbar_css: {
            height: '4em'
        },
        sidebar_css: {
            width: '12em'
        },
        sidebar_shrinked_css: {
            width: '5em'
        },
        footer_css: {
            height: '5em'
        }
    };
    const theme = useTheme();

    //STYLE
    const classes = useStyles();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });

    const isSidebarOpen = isDesktop ? true : sidebarStatus.user_has_opened;

    const toggleSidebar = () => {
        setSidebarStatus({
            ...sidebarStatus,
            user_has_opened: !sidebarStatus.user_has_opened
        });
    };

    return (
        <div className={classes.root}>
            <Hidden mdUp>
                <Topbar
                    cssVariables={css_variables}
                    onSidebarOpen={toggleSidebar}
                />
            </Hidden>
            <Grid>
                <Grid item lg={4}>
                    <Sidebar
                        cssVariables={css_variables}
                        closeSidebar={toggleSidebar}
                        isSidebarOpen={isSidebarOpen}
                        variant={isDesktop ? 'persistent' : 'temporary'}
                        sidebarStatus={sidebarStatus}
                        setSidebarStatus={setSidebarStatus}
                    />
                </Grid>
                <Grid item lg={10} className={classes.container_content}>
                    <main className={classes.main_container}>
                        {children}
                    </main>
                </Grid>
            </Grid>
        </div>
    );
};

Main.propTypes = {
    children: PropTypes.node
};

export default Main;
