import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  login_footer_html_class: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: '2%',
    color: theme.palette.white
  },
  footer_link: {
    color: theme.palette.white,
    fontFamily: '"Titillium Web", "sans-serif"'
  },
  magin_bottom_1perc: {
    marginBottom: '1%',
    textAlign: 'center'
  },
  logo_biomtech: {
    zIndex: '2',
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    }
  },
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <footer className={classes.login_footer_html_class}>
      <Grid container
        className={classes.magin_bottom_1perc}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item lg={12}>
          <a href="http://www.biomtech.com.br" target="_blank">
            <img className={classes.logo_biomtech}
              alt="Logo"
              src="/logo-biomtech.png"
            />
          </a>
        </Grid>
      </Grid>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
