import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Card,
    CardContent,
    Container,
    Fab,
    Grid,
    Hidden,
    IconButton,
    InputAdornment,
    TablePagination,
    TextField,
    Typography,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import RefreshIcon from '@material-ui/icons/Refresh';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { TableDesktop, TableMobile, Form, ClientDetails } from './components';
import { ModalFilter, LoadingComponent } from '../../components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import clsx from 'clsx';
import { ListClients, RemoveClient } from '../../API/endpoints';
import Swal from 'sweetalert2/src/sweetalert2';
import AppContext, { isAdmin } from '../../context/AppContext';
import { getComparator, stableSort } from '../../common/ordination';


const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(0),
        paddingLeft: 0,
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(0),
            padding: theme.spacing(2),
        }
    },
    tableHeader: {
        marginBottom: theme.spacing(1),
        backgroundColor: '#76AB00',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
        },
    },
    textHeader: {
        color: 'white',
        alignItems: 'center',
        cursor: 'pointer',
        textAlign: 'center'
    },
    textHeaderAction: {
        color: 'white',
        alignItems: 'center',
        textAlign: 'center',
        cursor: 'default'
    },
    view: {
        display: 'inline',
        marginLeft: theme.spacing(1),
        fontSize: 'medium'
    },
    cardHeader: {
        padding: theme.spacing(3, 6),
        backgroundColor: '#76AB00 !important',
        [theme.breakpoints.only('sm')]: {
            padding: theme.spacing(3),
        },
    },
    buttonAdd: {
        bottom: '2%',
        position: 'fixed',
        float: 'rigth',
        right: '2%',
        backgroundColor: '#76AB00',
        color: 'white',
        '&:hover': {
            backgroundColor: '#BBD400',
            color: '#FFFFFF'
        }
    },
    title: {
        margin: theme.spacing(3, 3, 2, 3),
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0),
        }
    },
    hide: {
        display: 'none',
    },
    show: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 1,
        transform: 'none',
        transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    gridSearch: {
        display: 'flex',
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(0),
        }
    },
    textField: {
        width: '30vw',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    gridHeader: {
        justifyContent: 'flex-end'
    },
    containerPagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: '90px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
            paddingBottom: theme.spacing(4)
        }
    },
    paginationToolbar: {
        position: 'initial',
        right: 0,
        flexWrap: 'wrap',
        textAlign: 'center',
        justifyContent: 'center',
        padding: 0
    },
    groupActionsHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    itemAction: {
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(4)
        }
    },
}));

export default function Clients({ history }) {
    //STYLE
    const classes = useStyles();

    //GLOBAL VARIABLES

    //STATE
    const [clients, setClients] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [openSearch, setOpenSearch] = useState(false);
    const [filter, setFilter] = useState("");
    const [dataFilter, setDataFilter] = useState(clients);
    const [openModal, setOpenModal] = useState(false);
    const [requestData, setRequestData] = useState(false);
    const [openModalFilter, setOpenModalFilter] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clientSelected, setClientSelected] = useState({ email: "", name: "", password: "", clientType: "", parents: [], webhookURLs: [] })
    const [modeForm, setModeForm] = useState("add");
    const [clientDetailsModal, setClientDetailsModal] = useState({ open: false, client: {} });

    //VARIABLES
    const inputSearchRef = useRef();
    const isMobile = useMediaQuery(theme => theme.breakpoints.only('xs'));
    let filterData = stableSort(dataFilter, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const columnsFilterMobile = [{ name: 'name', label: 'Nome' }, { name: 'email', label: 'E-mail' }]

    const openModalForm = () => {
        setOpenModal(true);
        setModeForm("add")
    };

    const closeModalFilter = () => {
        setOpenModalFilter(false)
    }

    //FILTER
    useEffect(() => {
        function handleSearch() {
            if (filter !== "") {
                var newArray = clients.filter((value) => {
                    var name = value?.name?.toLowerCase();
                    var email = value?.email?.toLowerCase();
                    var compound = name?.split(' ');
                    var indexOfCompound = -1;
                    if (compound?.length > 1)
                        indexOfCompound = compound[1]?.indexOf(filter.toLowerCase());
                    return name?.indexOf(filter?.toLowerCase()) === 0 || indexOfCompound === 0 || email?.indexOf(filter?.toLowerCase()) === 0
                });
                setDataFilter(newArray);
                setPage(0)
            }
            else {
                setDataFilter(clients)
            }
        }
        handleSearch();
    }, [filter])

    useEffect(() => {
        inputSearchRef.current.focus();
    }, [openSearch])


    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            cancelButton: 'swal2-cancel'
        },
    })

    function getClients() {
        setLoading(true);
        var hasError = true;
        if (isAdmin()) {
            ListClients()
                .then(response => {
                    if (response.status === 200) {
                        setClients(response.data.clients)
                        setDataFilter(response.data.clients)
                        hasError = false
                    }
                    if (response.status === 401) {
                        swalWithBootstrapButtons.fire({
                            icon: 'error',
                            title: 'Erro',
                            text: 'Sessão expirada ',
                        }).then((result) => {
                            history.push("/login")
                        })
                        hasError = false
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                    if (hasError) {
                        swalWithBootstrapButtons.fire({
                            icon: 'error',
                            title: 'Erro',
                            text: 'Falha ao listar clientes.',
                        })
                    }
                });
        }
    }

    useEffect(() => {
        getClients();
    }, [requestData])

    //PAGINATION
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //ORDINATION
    const handleRequestSort = (property) => {
        const isEmpty = orderBy === property && order === 'desc';

        if (!isEmpty) {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }
        else {
            setOrder('');
            filterData = clients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        }
    };

    const openEditClient = (client, event) => {
        event.stopPropagation();
        setClientSelected(client);
        setOpenModal(true);
        setModeForm("edit")
    }

    const handleDeleteClient = (client, event) => {
        event.stopPropagation();
        var hasError = true;
        swalWithBootstrapButtons.fire({
            title: 'Alerta',
            html: `Tem certeza que deseja remover o(a) cliente <strong> ${client.name} - ${client.email} </strong>? `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#76AB00',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                RemoveClient(client.id)
                    .then(response => {
                        if (response.status === 204) {
                            hasError = false;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        setRequestData(!requestData);
                        if (hasError) {
                            swalWithBootstrapButtons.fire({
                                icon: 'error',
                                title: 'Erro',
                                text: 'Falha ao remover cliente.',
                            })
                        }
                        else {
                            swalWithBootstrapButtons.fire({
                                icon: 'success',
                                title: 'Sucesso',
                                text: 'Cliente removido com sucesso!',
                            })
                        }
                    });
            }
        })
    }

    return (
        <Container className={classes.container}>
            <Grid container className={classes.gridHeader}>
                <Grid item lg={12} xs={12}>
                    <Typography variant="h2" component="h2" className={classes.title}>
                        Clientes
                    </Typography>
                </Grid>
                <Grid item lg={3} xs={8} className={classes.gridSearch}>
                    <Box className={
                        clsx({
                            [classes.hide]: !openSearch,
                            [classes.show]: openSearch
                        })
                    }>
                        <TextField
                            inputRef={inputSearchRef}
                            className={classes.textField}
                            placeholder="Pesquisar"
                            value={filter}
                            autoFocus={true}
                            onChange={e => setFilter(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <IconButton onClick={() => { setOpenSearch(false); setFilter("") }}>
                                        <CloseIcon />
                                    </IconButton>
                                )
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={4} lg={4} className={classes.itemAction}>
                    <Grid container className={classes.groupActionsHeader}>
                        <Hidden smUp>
                            <Grid item lg={1} xs={4} className={classes.gridSearch} >
                                <IconButton onClick={() => setOpenModalFilter(true)}>
                                    <FilterListIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>
                        <Grid item lg={1} xs={4} className={classes.gridSearch} >
                            <IconButton onClick={() => setOpenSearch(!openSearch)}>
                                <SearchIcon />
                            </IconButton>
                        </Grid>
                        <Grid item lg={1} xs={4} className={classes.gridSearch} >
                            <IconButton onClick={() => getClients()}>
                                <RefreshIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Card className={classes.tableHeader} >
                <CardContent className={classes.cardHeader}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        className={classes.marginDevice}
                    >
                        <Grid key={1} item lg={5} sm={3} xs={10}>
                            <Typography component="h2" variant="h5" color="primary" className={classes.textHeader} onClick={e => handleRequestSort('name')}>
                                Nome
                                <ImportExportIcon className={order === '' || orderBy !== 'name' ? classes.view : classes.hide} />
                                <ArrowDownwardIcon className={order === 'desc' && orderBy === 'name' ? classes.view : classes.hide} />
                                <ArrowUpwardIcon className={order === 'asc' && orderBy === 'name' ? classes.view : classes.hide} />
                            </Typography >
                        </Grid>
                        <Grid key={2} item lg={5} sm={3} xs={10}>
                            <Typography component="h2" variant="h5" color="primary" className={classes.textHeader} onClick={e => handleRequestSort('email')}>

                                Email
                                <ImportExportIcon className={order === '' || orderBy !== 'email' ? classes.view : classes.hide} />
                                <ArrowDownwardIcon className={order === 'desc' && orderBy === 'email' ? classes.view : classes.hide} />
                                <ArrowUpwardIcon className={order === 'asc' && orderBy === 'email' ? classes.view : classes.hide} />
                            </Typography >
                        </Grid>
                        {isAdmin() && <Grid key={3} item lg={2} sm={2} xs={10}>
                            <Typography component="h2" variant="h5" color="primary" className={classes.textHeaderAction}>
                                Ações
                            </Typography >
                        </Grid>}
                    </Grid>
                </CardContent>
            </Card>
            {isMobile ?
                <TableMobile clients={filterData} history={history} openEditClient={openEditClient} handleDeleteClient={handleDeleteClient} setClientDetailsModal={setClientDetailsModal} />
                :
                <TableDesktop clients={filterData} history={history} openEditClient={openEditClient} handleDeleteClient={handleDeleteClient} setClientDetailsModal={setClientDetailsModal}  />
            }
            <Container className={classes.containerPagination}>
                <TablePagination
                    component="div"
                    classes={{ toolbar: classes.paginationToolbar }}
                    count={dataFilter.length}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : ` até ${to}`
                        }`
                    }
                    labelRowsPerPage="Total por página:"
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 15, 30]}
                />
            </Container>
            <Fab
                aria-label="adicionar cliente"
                className={classes.buttonAdd}
                onClick={() => openModalForm()}
            >
                <AddIcon />
            </Fab>
            <Form formEdit={clientSelected} setFormEdit={setClientSelected} openModal={openModal} setOpenModal={setOpenModal} modeForm={modeForm} setModeForm={setModeForm} requestData={requestData} setRequestData={setRequestData} />
            <ModalFilter openModalFilter={openModalFilter} closeModalFilter={closeModalFilter} handleRequestSort={handleRequestSort} setOrder={setOrder} columns={columnsFilterMobile} />
            <ClientDetails clientDetailsModal={clientDetailsModal} setClientDetailsModal={setClientDetailsModal} history={history} />
            <LoadingComponent open={loading} handleClose={() => setLoading(false)} />
        </Container>
    );
}
