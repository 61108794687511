import React, { useState, useContext } from 'react';
import MuiDataTable from "mui-datatables";
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { 
  Card,
  CardContent,
  Fab,
  Grid, 
  Tooltip,
  Typography 
} from '@material-ui/core';
import ModalFormDefault from '../ModalFormDefault'
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AppContext from '../../context/AppContext';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
    },
    backgroundColor: '#FFFFFF',
    overflow: 'hidden'
  },
  tableHeader: {
    marginBottom: theme.spacing(1),
    backgroundColor: '#00525A !important',
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
  textHeader: {
    color: 'white',
    alignItems: 'center',
    cursor: 'pointer'
  },
  buttonAdd: {
    bottom: '2%',
    position: 'fixed',
    float: 'rigth',
    right: '2%',
    backgroundColor: '#76AB00',
    color: 'white',
    '&:hover': {
      backgroundColor: '#BBD400',
      color: '#272727'
    }
  },
  buttonEdit: {
    bottom: '2%',
    position: 'fixed',
    float: 'rigth',
    right: '5%',
    backgroundColor: '#A11212',
    color: 'white',
    marginRight: theme.spacing(3),
    [theme.breakpoints.up('xs')]: {
      right: '13%',
    },
    [theme.breakpoints.up('sm')]: {
      right: '8%',
    },
    [theme.breakpoints.up('lg')]: {
      right: '5%',
    },
    '&:hover': {
      backgroundColor: '#A14242',
      color: '#272727'
    }
  },
  view: {
    display: 'inline',
    marginLeft: theme.spacing(1),
    fontSize: 'medium'
  },
  hidde: {
    display: 'none'
  },
  cardHeader: {
    padding: theme.spacing(3, 6)
  }
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#A11212',
  },
  tooltip: {
    backgroundColor: '#A11212',
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}
function CustomHeadComponent(props) {
  const classes = useStyles();
  const { columnMeta, table } = props;
  const [asc, setAsc] = useState(false)
  const [desc, setDesc] = useState(false)
  const [sort, setSort] = useState(true)
  const [indexSort, setIndexSort] = useState(-1)
  const handleChange = index => {
    if (indexSort !== index) {
      setAsc(true)
      setDesc(false)
    }
    else {
      setAsc(!asc)
      setDesc(!desc)
    }
    setIndexSort(index)
    columnMeta.toggleSort(index)
  }
  return (
    <Grid container direction="row">
      <Grid item lg={12} sm={12}>
        <Card id="cardHead" className={classes.tableHeader} >
          <CardContent className={classes.cardHeader}>
            <Grid container
              direction="row"
              justify="flex-start"
              alignItems="center"
              className={table.hasForm ? classes.margin : classes.marginDevice}
            >
              {columnMeta.columns.map((head, index) => (
                <Grid key={index} item lg={columnMeta.columns.length === 4 ? 3 : 4} sm={columnMeta.columns.length === 4 ? 3 : 4} xs={12}>
                  <Typography component="h2" variant="h5" color="primary" className={classes.textHeader} onClick={() => handleChange(index)}>
                    {head.label}
                    <ImportExportIcon className={sort && indexSort !== index ? classes.view : classes.hidde} />
                    <ArrowDownwardIcon className={desc && indexSort === index ? classes.view : classes.hidde} />
                    <ArrowUpwardIcon className={asc && indexSort === index ? classes.view : classes.hidde} />
                  </Typography >
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

const companyList = [
  {
    name: "Benício e Emily Advocacia ME",
    companyKey: "60496087000161",
    company: 'Benício Advocacia ME',
    serialNumber: '22665656565',
    status: "0",
    state: '0',
    user: 'Tom Tallis',
    device: [{ serialNumber: '22665656565', status: 0 }, { serialNumber: '226650006565', status: 1 }, { serialNumber: '2266006565', status: 1 }],
    id: 0
  },
  {
    name: "Bianca e Stella Locações de Automóveis Ltda",
    companyKey: "65583838000109",
    company: 'Bianca e Stella Locações de Automóveis Ltda',
    status: "0",
    serialNumber: '226650006565',
    user: "Rich Harris",
    state: '0',
    device: [{ serialNumber: '22665656565', status: 0 }],
    id: 1
  },
  {
    name: "Alice e Oliver Lavanderia Ltda",
    companyKey: "79154549000190",
    status: "1",
    state: '1',
    company: 'Alice e Oliver Lavanderia Ltda',
    user: "Moby Dixon",
    serialNumber: '226650006565',
    device: [{ serialNumber: '22665656565', status: 0 }],
    id: 2
  },
  {
    name: "Samuel e Fátima Telecomunicações Ltda",
    companyKey: "08084578000185",
    status: "2",
    company: 'Samuel e Fátima Telecomunicações Ltda',
    state: '1',
    user: "Tom Tallis",
    serialNumber: '226650006565',
    device: [{ serialNumber: '22665656565', status: 0 }],
    id: 3
  },
  {
    name: "Mariana e Luzia Esportes ME",
    companyKey: "52460707000184",
    status: "2",
    state: '0',
    company: 'Mariana e Luzia Esportes ME',
    user: "Rich Harris",
    serialNumber: '226650006565',
    device: [{ serialNumber: '22665656565', status: 0 }],
    id: 4
  },
  {
    name: "Enrico e Matheus Financeira Ltda",
    companyKey: "23697734000177",
    status: "1",
    state: '0',
    company: 'Enrico e Matheus Financeira Ltda',
    user: "Moby Dixon",
    serialNumber: '226650006565',
    device: [{ serialNumber: '22665656565', status: 0 }],
    id: 5
  },
  {
    name: "Adriana e Miguel Limpeza ME",
    companyKey: "96677042000130",
    status: "0",
    state: '0',
    company: 'Adriana e Miguel Limpeza ME',
    user: "Tom Tallis",
    serialNumber: '226650006565',
    device: [{ serialNumber: '22665656565', status: 0 }],
    id: 6
  },
  {
    name: "Rebeca e Thiago Advocacia Ltda",
    companyKey: "34646420000161",
    status: "0",
    state: '0',
    company: 'Rebeca e Thiago Advocacia Ltda',
    user: "Rich Harris",
    serialNumber: '226650006565',
    device: [{ serialNumber: '22665656565', status: 0 }],
    id: 7
  },
  {
    name: "Alexandre e Gabrielly Marketing Ltda",
    companyKey: "22249903000143",
    status: "2",
    state: '0',
    company: 'Alexandre e Gabrielly Marketing Ltda',
    user: "Moby Dixon",
    serialNumber: '226650006565',
    device: [{ serialNumber: '22665656565', status: 0 }],
    id: 8
  },
];

const TableDefault = props => {
  const classes = useStyles();
  const { table, title, module_form, textButtonAdd } = props
  const { configTable, table_columns, view: View, isCollapse } = table
  const [checked, setChecked] = useState(null);
  const [elements, setElements] = useState(companyList);
  const { modalForm, setModalForm } = useContext(AppContext);

  const openModalForm = mode => {
    if (mode === 'add')
      setChecked(null)
    setModalForm({ open: true, mode: mode });
  };

  return (
    <>
      <div id='tableCompany' className={table.hasForm ? classes.root : classes.root}>
        <MuiDataTable
          data={elements}
          columns={table_columns}
          components={{
            TableHead: (columnMeta, updateDirection) => (<CustomHeadComponent columnMeta={columnMeta} table={table} updateDirection={updateDirection} />)
          }}
          options={{
            selectableRows: 'single',
            selectableRowsOnClick: true,
            responsive: "vertical",
            filterType: 'multiselect',
            print: configTable.print,
            download: configTable.download,
            viewColumns: configTable.viewColumns,
            filter: configTable.filter,
            sort: configTable.sort,
            title: configTable.title,
            rowsPerPageOptions: [5, 10, 15],
            rowsPerPage: 5,
            fixedHeader: true,
            fixedSelectColumn: true,
            setFilterChipProps: (colIndex, colName, data) => {
              return {
                color: 'primary',
                variant: 'outlined',
                className: 'testClass123',
              };
            },
            textLabels: {
              body: {
                noMatch: "Não foi encontrado registro.",
              },
              filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
              },
              toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download CSV",
                print: "Imprimir",
                viewColumns: "View Columns",
                filterTable: "Filtro",
              },
              pagination: {
                next: "Página anterior",
                previous: "Próxima página",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
              }
            },

            customRowRender: (data, index) => {
              const [name, companyKey, status, id] = data;
              let array = []
              table_columns.forEach((column, index) => {
                array.push(array => ({
                  ...array,
                  [column.name]: data[index]
                }));
              })
              return (
                <View
                  name={name}
                  id={id}
                  key={id}
                  companyKey={companyKey}
                  status={status}
                  data={data}
                  table={table}
                  index={index}
                  setChecked={setChecked}
                  checked={checked}
                  isCollapse={isCollapse}
                  table_columns={table_columns}
                  elements={elements}
                  setElements={setElements}
                />
              );
            },
          }}
        />
        {checked != null ?
          <BootstrapTooltip id="edit" title="Editar">
            <Fab aria-label="edit" className={classes.buttonEdit} onClick={() => openModalForm('edit')}>
              <EditIcon />
            </Fab>
          </BootstrapTooltip>
          : ''}
        {table.hasForm ?
          <BootstrapTooltip id="add" title="Adicionar">
            <Fab aria-label="add" className={classes.buttonAdd} onClick={() => openModalForm('add')}>
              <AddIcon />
            </Fab>
          </BootstrapTooltip>
          : ''}
      </div>
      <ModalFormDefault elements={elements} textButtonAdd={textButtonAdd} openModalForm={openModalForm} setElements={setElements} modalForm={modalForm} setModalForm={setModalForm} title={title} module_form={module_form} checked={checked} />
    </>
  );
}

export default TableDefault;
