import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  topbar_css: {
    backgroundColor: '#76AB00',
    color: 'black',
    opacity: '1'
  },
  logo_img: {
    maxHeight: '30px'
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className) + " " + classes.topbar_css}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <Typography variant="h2" className={classes.logo}>
          Device Control
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
