import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    CircularProgress,
    Grid,
    Box,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import validate from 'validate.js';
import clsx from 'clsx';
import configData from './dataConfig';
import {ComponentInput, ComponentInputSelect } from './components';

const schema = {
    companyName: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
    identityDistance: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
    identifyScores: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
    saveIdentityTime: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
    ttsModType: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
    ttsModContent: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
    comModType: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
    displayModType: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
    displayModContent: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
    recStrangerTimesThreshold: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
    recStrangerType: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
    ttsModStrangerType: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
    ttsModStrangerContent: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
    wg: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
    recRank: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
    delayTimeForCloseDoor: {
        presence: { allowEmpty: false, message: 'Campo obrigatório' },
    },
};

const useStyles = makeStyles((theme) => ({
    boxPrimary: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.spacing(5)
    },
    form: {
        maxHeight: '60vh',
        height: 'auto',
        margin: theme.spacing(2),
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        marginBottom: '24px',
        borderRadius: '12px',
        padding: theme.spacing(1),
        overflowX: 'none',
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2),
        }
    },
    boxContent: {
        backgroundColor: '#e1dfdf',
        margin: '8px',
        borderRadius: '15px',
        padding: '1px',
        [theme.breakpoints.down('sm')]: {
            minHeight: '50vh',
        }
    },
    boxContentLoading: {
        opacity: 0.3
    },
    progress: {
        margin: 'auto',
        display: 'flex'
    },
}));


export default function ConfigDevice({ loadingCommand, setLoadingCommand, errors, setErrors, formState, setFormState }) {
    //STYLE
    const classes = useStyles();

    //VARIABLES   

    //STATES
    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    function handleChange(name, value) {
        setFormState(formState => ({
            ...formState,
            [name]: isNumeric(value)?parseInt(value):value
        }))
    }

    function validateElement(name){
        let errors = validate(formState, schema, { fullMessages: false });
        if (errors) {
            setErrors(errors);
        }
        else{
            setErrors([]);
        }
    }

    return (
        <Box className={
            clsx(classes.boxContent, {
                [classes.boxContentLoading]: loadingCommand,
            })
        }>
            <form noValidate autoComplete="off" className={classes.form}>
                <PerfectScrollbar>
                    <Grid container className={classes.boxPrimary} >
                        {configData.map((element, index) => (
                            <Grid key={index} item xs={element.xs} sm={element.sm} lg={element.lg}>
                                {
                                    element.type === "select"?
                                        <ComponentInputSelect name={element.name} type={element.type} label={element.label} handleChange={handleChange} validateElement={validateElement} errors={errors} formState={formState} options={element.options}/>
                                    :
                                        <ComponentInput name={element.name} type={element.type} label={element.label} handleChange={handleChange} validateElement={validateElement} errors={errors} formState={formState} />
                                }
                            </Grid>
                        ))}
                    </Grid>
                </PerfectScrollbar>
            </form>
            {loadingCommand && <CircularProgress className={classes.progress} />}
        </Box>
    );
}
