import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Dialog,
    Fab,
    Grid,
    IconButton,
} from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Webcam from 'react-webcam';
import ResizeObserver from 'react-resize-observer';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        textAlign: 'left',
        height: 'auto',
        padding: theme.spacing(0),
        justifyContent: 'space-evenly',
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'space-between',
            flexDirection: 'column-reverse'
        }
    },
    dialog: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    webcam: {
        borderRadius: theme.spacing(2),
        height: 'auto',
        [theme.breakpoints.down('sm')]:{
            width: '100%',
        }
    },
    closeIcon: {
        float: 'right',
        backgroundColor: 'transparent',
        color: '#FFF'
    },
    dialogPaper: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'hidden',
        width: 'auto'
    },
    gridCloseIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start'
    },
    buttonTakePicture: {
        position: 'absolute',
        zIndex: 3,
        textAlign: 'center',
        bottom: '10%',
        left: 0,
        right: '7%',
        margin: 'auto',
        width: 'max-content !important',
        display: 'flex',
    },
    iconPhoto: {
        marginRight: theme.spacing(2)
    },
    show: {
        display: 'flex'
    },
    hidde: {
        display: 'none'
    },
    svg: {
        position: 'absolute',
        zIndex: 2,
        borderRadius: '2%',
    },
    rect: {
        fillOpacity: 0.6
    },
    ellipseOut: {
        fillOpacity: 0,
        strokeWidth: '6px',
        stroke: '#76AB00',
    },
}))

export default ({ history, modalCam, setOpenModalCam, savePhoto }) => {
    //STYLE
    const classes = useStyles();

    //VARIABLES
    const webcamRef = useRef(null);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isSM = useMediaQuery(theme => theme.breakpoints.only('sm'));
    const ellipseRX = isMobile ? isSM ? '16%' : '33%' : '16%';
    const ellipseRY = isMobile ? isSM ? '25%' : '28%' : '25%';

    //STATES
    const [loadWebcam, setLoadWebcam] = useState(false);
    const [dimensions, setDimensions] = useState({ width: '100', height: '100' });
   

    return (
        <Dialog
            open={modalCam.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
            className={classes.dialog}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <Grid container component="main" direction="row" className={classes.root}>
                <Grid item md={11}>
                    <Fab
                        variant="extended"
                        size="medium"
                        color="primary"
                        aria-label="add"
                        className={clsx(classes.buttonTakePicture, {
                            [classes.show]: loadWebcam,
                            [classes.hidde]: !loadWebcam
                        })}
                        onClick={() => savePhoto(webcamRef.current.getScreenshot())}
                    >
                        <PhotoCameraIcon className={classes.iconPhoto} />
                        tirar foto
                    </Fab>
                    <svg xmlns="http://www.w3.org/2000/svg" id="mySVG" 
                        className={clsx(classes.svg, {
                            [classes.show]: loadWebcam,
                            [classes.hidde]: !loadWebcam,
                        })} width={dimensions.width} height={dimensions.height} preserveAspectRatio="xMidYMid meet" viewBox={"6 2 " + (dimensions.width - 10) + " " + (dimensions.height - 10)}
                    >
                        <defs>
                            <mask id="myMask" x="0" y="0" width={dimensions.width} height={dimensions.height}>
                                <rect id="rect-ellipse" width={dimensions.width} height={dimensions.height} fill="white" />
                                <ellipse id="ellipse" cx="51%" cy="45%" rx={ellipseRX} ry={ellipseRY} preserveAspectRatio="xMidYMid meet" fill="black" stroke="#76AB00;" strokeWidth="3" transform="scale(1 1)" />
                            </mask>
                        </defs>
                        <rect id="rect" className={classes.rect} x="0" y="0" width={dimensions.width} height={dimensions.height} fill="black" mask="url(#myMask)"></rect>
                        <ellipse id="ellipse-out" className={classes.ellipseOut} cx="51%" cy="45%" rx={ellipseRX} ry={ellipseRY} preserveAspectRatio="xMidYMid meet" fill="black" stroke="#76AB00;" strokeWidth="3" transform="scale(1 1)" />
                    </svg>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        mirrored={true}
                        screenshotQuality={1}
                        className={classes.webcam}
                        onUserMedia={() => { setLoadWebcam(true); }}
                    >
                        <ResizeObserver
                            onResize={(rect) => {
                                setDimensions({ width: rect.width, height: rect.height })
                            }}
                            onPosition={(rect) => {

                            }}
                        />
                    </Webcam>
                </Grid>
                <Grid item md={1} className={classes.gridCloseIcon}>
                    <IconButton
                        aria-label="close"
                        className={clsx(classes.closeIcon, {
                            [classes.show]: loadWebcam,
                            [classes.hidde]: !loadWebcam
                        })}
                        onClick={() => {
                            setOpenModalCam({...modalCam, open: false });
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Dialog>
    );
}