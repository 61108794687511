import React from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import { Main as MainLayout } from '../../layouts';
import { TableList } from '../../views';
import { Report } from '../../views'
const module_name = 'Report';
const module_name_as_title = 'Relatório';
const module_name_route_address = 'report';
const module_api_route = '/report';
const textButtonAdd = 'Salvar e Criar Usuário'
/*------------------------------*/
/*BEGIN LIST CONFIGURATION*/
const table_columns = [
    {
        name: "name",
        label: "Nome",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "companyKey",
        label: "CNPJ",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "status",
        label: "Status",
        options: {
            filter: false,
            sort: true,
        }
    }
];

//LIST CONFIG RESUME
const configTable = {
    print:false,
    download:false,
    viewColumns: false,
    filter:false,
    sort: true,
    title:false,
};
/*END LIST CONFIGURATION*/
/*------------------------------*/

/*------------------------------*/
/*BEGIN FORM CONFIGURATION*/
const module_form = {
    title: module_name_as_title,
    object: {
        companyId: 0,
        status:0,
    },
    form_fields: [
        {
            name: 'name',
            title: 'Nome da Empresa *',
            type: 'text',
            isRequired: true,
            show_on_view: true,
            show_on_add: true,
            show_on_edit: true,
            default_value: '',
            add_to_object: true,
            grid_lg: 12,
            grid_xs:12
        },
        {
            name: 'companyKey',
            title: 'CNPJ *',
            type: 'text',
            isRequired: true,
            show_on_view: true,
            show_on_add: true,
            show_on_edit: true,
            default_value: '',
            add_to_object: true,
            grid_lg: 12,
            grid_xs:12
        }
    ],
    validate_js_constraints: {
        name: {
            presence:{message: "Campo Obrigatório"}
            // message: "Campo Obrigatório",
            // length: {
            //     minimum: 3,
            //     message: "Mínimo 3 caracteres"
            //   }
        },
        companyKey: {
            presence:{message: "Campo Obrigatório"}
            // length: {
            //     minimum: 3,
            //     message: "Mínimo 3 caracteres"
            //   }
        }
    },
};
/*END FORM CONFIGURATION*/
/*------------------------------*/
const table = {
    type: 'table',
    configTable,
    table_columns,
    view: TableList,
    isCollapse: false,
    hasForm: true
};
export default {
    name: module_name,
    title: module_name_as_title,
    component: Report,
    layout: MainLayout,
    path: '/' + module_name_route_address,
    module_api_route,
    icon: <DescriptionIcon/>,
    showOnSidebar: true,
    module_form,
    table,
    textButtonAdd
};
