import React, {
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    text:{
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            color: '#8A8A8D'
        }
    }
}))

const Timer = forwardRef((props, ref) => {
    //STYLE
    const classes = useStyles();
    // For Total seconds
    const [timeStamp, setTimeStamp] = useState(60);
    // Delay Required
    const [delay, setDelay] = useState(props.delay ? props.delay : 1000);

    // Flag for informing parent component when timer is over
    const [sendOnce, setSendOnce] = useState(true);

    // Flag for final display time format
    const [finalDisplayTime, setFinalDisplayTime] = useState('');

    useInterval(() => {
        if (timeStamp > 0) {
            setTimeStamp(timeStamp - 1);
        } else if (sendOnce) {
            if (props.timerCallback) {
                props.timerCallback(true);
            } else {
                console.log('Please pass a callback function...');
            }
            setSendOnce(false);
        }
        setFinalDisplayTime(secondsToDhms(timeStamp));
    }, delay);

    function secondsToDhms(seconds) {
        seconds = Number(seconds);
        var h = Math.floor((seconds % (3600 * 24)) / 3600);
        var m = Math.floor((seconds % 3600) / 60);
        var s = Math.floor(seconds % 60);

        var hDisplay = h > 9 ? h + ': ' : '0' + h + ':';
        var mDisplay = m > 9 ? m + ': ' : '0' + m + ':';
        var sDisplay = s > 9 ? s : '0' + s;


        return hDisplay + mDisplay + sDisplay;
    }

    useImperativeHandle(ref, () => ({
        resetTimer: () => {
            setTimeStamp(60);
            setSendOnce(true);
        },
    }));

    useEffect(() => {
        setTimeStamp(60);
        setSendOnce(true);
        props.setNewMessage(null)
    }, [props.restart])
    
    return (
        <Typography className={classes.text}>{sendOnce ? finalDisplayTime : '0'}</Typography>
    );
});

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [delay]);
}

export default Timer;