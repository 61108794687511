import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LensIcon from '@material-ui/icons/Lens';

const useStyles = makeStyles(theme => ({
    buttonIconActive: {
        backgroundColor: 'transparent',
        color: theme.palette.success.main,
        fontSize: 'medium',
        display: 'inline-flex',
        marginRight: theme.spacing(1)

    },
    buttonIconBlock: {
        backgroundColor: 'transparent',
        color: theme.palette.error.main,
        fontSize: 'medium',
        display: 'inline-flex',
        marginRight: theme.spacing(1)
    },

}));

const State = props => {

    const { selected } = props
    const classes = useStyles();

    return (
        <>
            <LensIcon className={selected ? classes.buttonIconActive : classes.buttonIconBlock} />
            {selected ?
                <Typography className={classes.buttonIconActive}>Online</Typography>
                : <Typography className={classes.buttonIconBlock}>Offline</Typography>
            }
        </>
    );
}

export default State